import StyledButton from '@components/Shared/StyledButton';
import { Modal } from '@shared/modal/Modal';
import { useModalActions } from '@utilities/context/ModalContext';
import React from 'react';
import { UboFormData } from './models/ubo-form-data.model';
import { useTranslation } from 'react-i18next';

interface Props {
  removeUbo: (ubo: UboFormData) => void;
  ubo: UboFormData;
}

const RemoveUboModal: React.FC<Props> = ({ ubo, removeUbo }) => {
  const { hide } = useModalActions();
  const { t } = useTranslation();

  return (
    <Modal id="remove-ubo-modal" title={t('RemoveUboModal.remove_ubo')} isClosable={true}>
      <p className="u-margin-top--xs u-text--secondary">
        {t('RemoveUboModal.remove_ubo_confirmation', { uboFirstname: ubo.firstname, uboLastname: ubo.lastname })}
      </p>
      <StyledButton
        tag="button"
        text="Buttons.no_do_not_delete"
        type="button"
        formType="tertiary"
        classes="u-margin-right--xs u-margin-bottom--xs"
        onClick={() => hide('remove-ubo-modal')}
      />
      <StyledButton
        tag="button"
        text="Buttons.yes_delete"
        type="button"
        formType="tertiary"
        onClick={() => removeUbo(ubo)}
      />
    </Modal>
  );
};

export default RemoveUboModal;
