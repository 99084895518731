import React from 'react';
import Confirmation from '@shared//Confirmation';
import { useTranslation } from 'react-i18next';

interface Callback {
  callback?: () => void;
  buttonClick?: () => void;
}

const RegisterConfirmation: React.FC<Callback> = ({ buttonClick }) => {
  const { t } = useTranslation();
  return (
    <Confirmation
      title="RegisterConfirmation.success"
      text="RegisterConfirmation.confirmation_email_message"
      button={{ label: 'RegisterConfirmation.register_button_label', onClick: buttonClick }}
    />
  );
};

export default RegisterConfirmation;
