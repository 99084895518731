export const getHostCountryPrefix = () => {
  const hostName = window.location.hostname;
  if (hostName.includes('be.') || hostName.includes('be-')) {
    return CountryPrefix.Belgium;
  }
  // This is the default language for local testing purposes. Set this accordingly
  if (hostName === 'localhost') {
    return CountryPrefix.Belgium;
  }

  return CountryPrefix.Netherlands;
};

export enum CountryPrefix {
  Belgium = 'be',
  Netherlands = 'nl',
  French = 'fr',
}
