export const industries = [
  {
    value: 'Agriculture',
    displayValue: 'IndustriesDropdown.agriculture',
  },
  {
    value: 'Apparel',
    displayValue: 'IndustriesDropdown.apparel',
  },
  {
    value: 'Banking',
    displayValue: 'IndustriesDropdown.banking',
  },
  {
    value: 'Biotechnology',
    displayValue: 'IndustriesDropdown.biotechnology',
  },
  {
    value: 'Chemicals',
    displayValue: 'IndustriesDropdown.chemicals',
  },
  {
    value: 'Communications',
    displayValue: 'IndustriesDropdown.communications',
  },
  {
    value: 'Construction',
    displayValue: 'IndustriesDropdown.construction',
  },
  {
    value: 'Consulting',
    displayValue: 'IndustriesDropdown.consulting',
  },
  {
    value: 'Education',
    displayValue: 'IndustriesDropdown.education',
  },
  {
    value: 'Electronics',
    displayValue: 'IndustriesDropdown.electronics',
  },
  {
    value: 'Energy',
    displayValue: 'IndustriesDropdown.energy',
  },
  {
    value: 'Engineering',
    displayValue: 'IndustriesDropdown.engineering',
  },
  {
    value: 'Entertainment',
    displayValue: 'IndustriesDropdown.entertainment',
  },
  {
    value: 'Environmental',
    displayValue: 'IndustriesDropdown.environmental',
  },
  {
    value: 'Finance',
    displayValue: 'IndustriesDropdown.finance',
  },
  {
    value: 'Food & Beverage',
    displayValue: 'IndustriesDropdown.food_and_beverage',
  },
  {
    value: 'Government',
    displayValue: 'IndustriesDropdown.government',
  },
  {
    value: 'Healthcare',
    displayValue: 'IndustriesDropdown.healthcare',
  },
  {
    value: 'Hospitality',
    displayValue: 'IndustriesDropdown.hospitality',
  },
  {
    value: 'Insurance',
    displayValue: 'IndustriesDropdown.insurance',
  },
  {
    value: 'Machinery',
    displayValue: 'IndustriesDropdown.machinery',
  },
  {
    value: 'Manufacturing',
    displayValue: 'IndustriesDropdown.manufacturing',
  },
  {
    value: 'Media',
    displayValue: 'IndustriesDropdown.media',
  },
  {
    value: 'Not For Profit',
    displayValue: 'IndustriesDropdown.not_for_profit',
  },
  {
    value: 'Other',
    displayValue: 'IndustriesDropdown.other',
  },
  {
    value: 'Recreation',
    displayValue: 'IndustriesDropdown.recreation',
  },
  {
    value: 'Retail',
    displayValue: 'IndustriesDropdown.retail',
  },
  {
    value: 'Shipping',
    displayValue: 'IndustriesDropdown.shipping',
  },
  {
    value: 'Technology',
    displayValue: 'IndustriesDropdown.technology',
  },
  {
    value: 'Telecommunications',
    displayValue: 'IndustriesDropdown.telecommunications',
  },
  {
    value: 'Transportation',
    displayValue: 'IndustriesDropdown.transportation',
  },
  {
    value: 'Utilities',
    displayValue: 'IndustriesDropdown.utilities',
  },
];
