import { FastField, Field, useFormikContext } from 'formik';
import React from 'react';
import Select, { SelectProps } from './Select';

interface FormikSelectProps extends SelectProps {
  optimized?: boolean;
}

export const FormikSelect: React.FC<FormikSelectProps> = ({ name, optimized = true, ...props }) => {
  const { setFieldTouched } = useFormikContext();
  const Component = optimized ? FastField : Field;

  // We use this key to force a rerender on the component whenever we switch languages
  return (
    <Component key={JSON.stringify(props.options)} name={name}>
      {({ field, meta }) => {
        return (
          <Select
            {...field}
            {...props}
            hasError={Boolean(meta.touched && meta.error)}
            onChange={(e: any) => {
              setFieldTouched(field.name);
              field.onChange(e);

              if (props.onChange) props.onChange(e.target.value);
            }}
          />
        );
      }}
    </Component>
  );
};
