import i18next from 'i18next';
import backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationNL from '../src/assets/locales/nl/translation.json';
import translationFR from '../src/assets/locales/fr/translation.json';
import translationNLBE from '../src/assets/locales/nl-be/translation.json';
import { CountryPrefix, getHostCountryPrefix } from '@utilities/countryPrefix';

const hostCountry = getHostCountryPrefix();

const resources = {
  nl: {
    translation: hostCountry === CountryPrefix.Belgium ? translationNLBE : translationNL,
  },
  fr: {
    translation: translationFR,
  },
};

i18next
  .use(LanguageDetector)
  .use(backend)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'nl',
    debug: false,
    detection: {
      order: ['cookie', 'path', 'htmlTag'],
      caches: ['cookie'],
      lookupCookie: 'i18next',
    },
    react: { useSuspense: false },
    backend: {
      loadPath: '/src/assets/locales/nl/translation.json',
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18next;
