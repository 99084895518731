import { FormikSelect } from '@components/Shared/form-elements/FormikSelect';
import StyledButton from '@components/Shared/StyledButton';
import { FormikInput } from '@shared//form-elements/FormikInput';
import FormHeader from '@shared//FormHeader';
import FormLayout from '@shared//FormLayout';
import FormLayoutColOne from '@shared//FormLayoutColOne';
import FormLayoutColTwo from '@shared//FormLayoutColTwo';
import { Company, UserProps } from '@utilities/interfaces/user';
import { Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import SelectedPackage from '../SelectedPackage';
import { socialMediaValues } from '@utilities/input-values';
import Collapse from '@components/Shared/collapse/Collapse';
import { BinIcon, PencilIcon } from '@components/Shared/svg/icons';
import { industries } from '@utilities/industries';
import { useUser } from '@state/user';
import { useTranslation } from 'react-i18next';
import CompanyTile from '@components/Shared/tiles/CompanyTile';
import RegisterCompanyModal from './RegisterCompanyModal';
import { useModalActions } from '@utilities/context/ModalContext';
import { EMPTY_COMPANY } from '@utilities/constants/user';

import { FormikAutocomplete } from '@components/Shared/form-elements/FormikAutocomplete';
import { CountryPrefix, getHostCountryPrefix } from '@utilities/countryPrefix';
import { REGISTER_TWO_SCHEMA } from './validation/register-two-schema';

interface RegisterTwoProps {
  callback?: (id: string) => void;
  initialValues: UserProps;
  onSubmit: (values) => void;
}

export interface SocialMedia {
  mediaType?: string | undefined;
  mediaUrl?: string | undefined;
}

const RegisterTwo: React.FC<RegisterTwoProps> = ({ onSubmit, initialValues, callback }) => {
  const { packageid } = useParams<{ packageid: string }>();
  const [vatNumberVisible, setVatNumberVisible] = useState<boolean>(false);
  const [searchButtonVisible, setSearchButtonVisible] = useState<boolean>(false);
  const [registerButtonVisible, setRegisterButtonVisible] = useState<boolean>(true);
  const [companyFromSearch, setCompanyFromSearch] = useState<boolean>(true);
  const [socialMediaFields, setSocialMediaFields] = useState<SocialMedia[]>([{ mediaType: undefined, mediaUrl: '' }]);
  const [socialMediaWarning, setSocialMediaWarning] = useState({ show: false, text: '' });
  const [companyInitialValues, setCompanyInitialValues] = useState<Company>(EMPTY_COMPANY);
  const [showCompanyTile, setShowCompanyTile] = useState(false);
  const { user, setUser } = useUser();
  const { dispatch } = useModalActions();
  const { t, i18n } = useTranslation();
  const hostCountry = getHostCountryPrefix();
  const firstRender = useRef<boolean>(true);

  const unableToDeleteSocialMediaWarning = t('RegisterTwo.select_none_social_media');
  const finishPreviousSocialMediaWarning = t('RegisterTwo.finish_first_social_media');
  const hasCardsOrClicks = user.packageInformation.hasCards || user.packageInformation.hasClicks;

  const socialMediaIsNotComplete = () =>
    socialMediaFields.length > 0 && !socialMediaFields[socialMediaFields.length - 1].mediaType;

  const addSocialMedia = (event) => {
    event.preventDefault();

    if (socialMediaIsNotComplete()) {
      setSocialMediaWarning({ text: finishPreviousSocialMediaWarning, show: true });
    } else {
      setSocialMediaWarning({ ...socialMediaWarning, show: false });
      setSocialMediaFields((socialMediaFields) => [...socialMediaFields, { mediaType: undefined, mediaUrl: '' }]);
    }
  };

  const removeSocialMedia = (index: number) => {
    if (socialMediaFields.length > 1) {
      const reducedArr = socialMediaFields.filter((field, i) => i !== index);
      setSocialMediaFields(reducedArr);
    } else {
      setSocialMediaWarning({ text: unableToDeleteSocialMediaWarning, show: true });
    }
  };

  const handleFormChange = (selection, index: number) => {
    const newFields = socialMediaFields.map((data) => Object.assign({}, data));
    const updatedField = { ...newFields[index], mediaType: selection };

    if (selection == 'Geen') {
      updatedField.mediaUrl = '';
    }

    newFields[index] = updatedField;
    setSocialMediaFields(newFields);
  };

  useEffect(() => {
    const searchShouldBeVisible = hostCountry !== CountryPrefix.Belgium;
    setSearchButtonVisible(searchShouldBeVisible);
  }, [hostCountry]);

  useEffect(() => {
    // We use this to avoid triggering the Collapse on first render.
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    if (!socialMediaIsNotComplete()) {
      setSocialMediaWarning({ ...socialMediaWarning, show: false });
    }
  }, [socialMediaFields]);

  useEffect(() => {
    if (callback && packageid) {
      callback(packageid);
    }
  }, [packageid, callback]);

  const openRegisterCompanyModal = () => {
    dispatch({ type: 'show', key: 'register-company-modal' });
  };

  const openEditCompanyModal = (values: Company) => {
    setCompanyInitialValues(values);
    dispatch({ type: 'show', key: 'register-company-modal' });
  };

  const registerCompany = async (values: Company) => {
    setUser({ ...user, company: values });
    setCompanyFromSearch(false);
    setShowCompanyTile(true);
  };

  const handleFormSubmit = (values) => {
    const countryCode = getHostCountryPrefix();

    if (companyFromSearch) {
      onSubmit({
        ...values,
        company: { ...values.company, address: { ...values.company.address, country: countryCode } },
      });
    } else {
      const data = {
        ...values,
        company: {
          ...values.company,
          address: {
            ...user.company.address,
            country: countryCode,
          },
          registrationNumber: user.company.registrationNumber?.replace(/\./g, ''),
          name: user.company.name,
          tradeName: user.company.name,
          vatNumber: user.company.vatNumber,
          descriptionOfBusinessActivities: user.company.descriptionOfBusinessActivities,
        },
      };
      onSubmit(data);
    }
  };

  return (
    <FormLayout mobileReverse>
      <FormLayoutColOne>
        <FormHeader
          title="RegisterTwo.complete_registration"
          text="RegisterTwo.fill_in_data"
          status="RegisterTwo.one_step_to_go"
          indicator={t('step') + ' 2/2'}
          hasPrev
        />
        <Formik
          initialValues={initialValues}
          validationSchema={REGISTER_TWO_SCHEMA(t, hasCardsOrClicks, companyFromSearch)}
          onSubmit={handleFormSubmit}
        >
          {({ handleChange, values, touched, setErrors }) => {
            i18n.on('languageChanged', () => {
              setErrors({});
            });
            return (
              <Form>
                <RegisterCompanyModal
                  id="register-company-modal"
                  title="RegisterTwo.add_company"
                  initialValues={companyInitialValues}
                  onSubmit={registerCompany}
                />
                {/* TODO: Add conditions for language and if register button is visible */}
                {companyFromSearch && !values.company.registrationNumber && touched.companySearchQuery && (
                  <p className="input__error u-margin-bottom--xs">{t('RegisterTwo.search_company')}</p>
                )}
                {searchButtonVisible && (
                  <FormikAutocomplete
                    label="RegisterTwo.company_name_or_vat_number"
                    name="companySearchQuery"
                    formValue="company"
                    type="text"
                    formType="secondary"
                    setVatNumberVisible={(value) => setVatNumberVisible(value)}
                    showRegisterButton={(value) => setRegisterButtonVisible(value)}
                    setCompanyFromSearch={(value) => setCompanyFromSearch(value)}
                  />
                )}
                {((hostCountry !== CountryPrefix.Belgium && registerButtonVisible) ||
                  hostCountry === CountryPrefix.Belgium) && (
                  <div className="">
                    <StyledButton
                      onClick={openRegisterCompanyModal}
                      type="button"
                      tag="button"
                      text="RegisterTwo.add_company"
                      classes="u-margin-bottom--xs"
                    />
                    {showCompanyTile && (
                      <div>
                        <CompanyTile
                          variant="primary"
                          name={user.company.name}
                          registrationNumber={user.company.registrationNumber}
                          classes="u-margin-bottom--xs"
                        >
                          <div className="company-tile__button-wrapper">
                            <button className="company-tile__button" onClick={() => openEditCompanyModal(user.company)}>
                              {t('Buttons.edit')} <PencilIcon />
                            </button>
                          </div>
                        </CompanyTile>
                      </div>
                    )}
                  </div>
                )}
                {vatNumberVisible && (
                  <FormikInput
                    label="RegisterTwo.vat_number"
                    name="company.vatNumber"
                    type="text"
                    formType="secondary"
                  />
                )}

                {hasCardsOrClicks && (
                  <>
                    <FormikInput
                      label="RegisterTwo.company_website"
                      name="company.website"
                      type="text"
                      formType="secondary"
                    />
                    <FormikSelect
                      label="RegisterTwo.industry"
                      name="company.industry"
                      shouldTranslateValues
                      hideLabel
                      placeholder="RegisterTwo.select_industry"
                      white
                      options={industries}
                    />
                  </>
                )}
                <FormikInput
                  label="ContactInformation.first_name"
                  name="applicant.firstname"
                  type="text"
                  formType="secondary"
                />
                {hostCountry !== CountryPrefix.Belgium && (
                  <FormikInput
                    label="ContactInformation.preposition"
                    name="applicant.middlename"
                    type="text"
                    formType="secondary"
                  />
                )}
                <FormikInput
                  label="ContactInformation.surname"
                  name="applicant.lastname"
                  type="text"
                  formType="secondary"
                />
                <FormikSelect
                  label="ContactInformation.salutation"
                  hideLabel
                  name="applicant.gender"
                  placeholder="ContactInformation.select_salutation"
                  white
                  options={[
                    { value: 'M', displayValue: t('ContactInformation.sir') },
                    { value: 'F', displayValue: t('ContactInformation.madam') },
                  ]}
                />
                {hasCardsOrClicks &&
                  socialMediaFields.map((field, index) => {
                    return (
                      <div key={index} className="input__small">
                        <div className="form__item_socmed">
                          <FormikSelect
                            label="RegisterTwo.social_media"
                            hideLabel
                            name={`socialMediaFields[${index}].mediaType`}
                            placeholder="RegisterTwo.company_social_media"
                            white
                            shouldTranslateValues
                            onChange={(selection) => handleFormChange(selection, index)}
                            options={socialMediaValues}
                          />
                          <FormikInput
                            label="RegisterTwo.corporate_social_media_account"
                            name={`socialMediaFields[${index}].mediaUrl`}
                            formType="secondary"
                            type="text"
                            onChange={handleChange}
                          />
                          <button className="form__item_socmed_button_add" type="button" onClick={addSocialMedia}>
                            +
                          </button>
                          <button type="button" onClick={() => removeSocialMedia(index)}>
                            <BinIcon fill="#FFFFFF" />
                          </button>
                        </div>
                      </div>
                    );
                  })}
                {hasCardsOrClicks && (
                  <div className="form__item_socmed_warning">
                    <Collapse active={socialMediaWarning.show}>
                      <p className="input__error u-margin-bottom--xs">{socialMediaWarning.text}</p>
                    </Collapse>
                  </div>
                )}

                <StyledButton tag="button" type="submit" text="Buttons.confirm" />
              </Form>
            );
          }}
        </Formik>
      </FormLayoutColOne>
      <FormLayoutColTwo selectedPackage>
        {initialValues.productType && <SelectedPackage productid={initialValues.productType} />}
      </FormLayoutColTwo>
    </FormLayout>
  );
};

export default RegisterTwo;
