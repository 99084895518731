import AutoAddress from '@components/Shared/AutoAddress';
import { FormikSelect } from '@components/Shared/form-elements/FormikSelect';
import StyledButton from '@components/Shared/StyledButton';
import { FormikInput } from '@shared/form-elements/FormikInput';
import { Modal } from '@shared/modal/Modal';
import { LoadingIcon } from '@shared/svg/icons';
import { useModalActions } from '@utilities/context/ModalContext';
import { Form, Formik } from 'formik';
import React, { useCallback, useState } from 'react';
import { LocationDetailsFormValues } from '../edit-location/location-details.model';
import { CHECKOUT_THREE_SCHEMA } from '../../CheckoutThree/validation/checkout-three-schema';
import { useTranslation } from 'react-i18next';
import { getHostCountryPrefix } from '@utilities/countryPrefix';

interface AddressModalProps {
  id: string;
  title: string;
  initialValues: LocationDetailsFormValues;
  onSubmit: (values: LocationDetailsFormValues) => void;
}

const AddressModal: React.FC<AddressModalProps> = ({ initialValues, id, title, onSubmit }) => {
  const [loading, setLoading] = useState(false);
  const { hide } = useModalActions();
  const { t } = useTranslation();
  const hostCountry = getHostCountryPrefix();

  const submit = useCallback(
    (values: LocationDetailsFormValues) => {
      setLoading(true);
      setTimeout(() => {
        onSubmit(values);
        hide('contact-modal');
        setLoading(false);
      }, 600);
    },
    [hide, setLoading, onSubmit],
  );

  return (
    <Modal id={id} title={title} isClosable={true}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={CHECKOUT_THREE_SCHEMA(t, hostCountry)}
        onSubmit={submit}
      >
        <Form>
          <div className="address__modal">
            <FormikInput formType="tertiary" name="name" label="ContactInformation.company_name" type="text" />

            <AutoAddress
              zipCode={
                <FormikInput formType="tertiary" name="address.zipCode" label="Address.postal_code" type="text" />
              }
              houseNumber={
                <FormikInput formType="tertiary" name="address.houseNumber" label="Address.housenumber" type="text" />
              }
              street={<FormikInput formType="tertiary" name="address.street" label="Address.street" type="text" />}
              city={<FormikInput formType="tertiary" name="address.city" label="Address.city" type="text" />}
            />

            <h4 className="location__title">{t('ContactInformation.contact_information')}</h4>

            <div className="address__modal-row">
              <FormikInput
                formType="tertiary"
                classes="form__item--half"
                name="contact.firstname"
                label="ContactInformation.first_name"
                type="text"
              />
              <FormikInput
                formType="tertiary"
                classes="form__item--half"
                name="contact.lastname"
                label="ContactInformation.surname"
                type="text"
              />
            </div>
            <div className="address__modal-row">
              <FormikInput
                formType="tertiary"
                classes="form__item--half"
                name="contact.email"
                label="ContactInformation.email"
                type="text"
              />
              <FormikInput
                formType="tertiary"
                classes="form__item--half"
                name="contact.mobilePhone"
                label="ContactInformation.phone_number"
                type="text"
              />
            </div>
            <FormikSelect
              label="ContactInformation.salutation"
              hideLabel
              name="contact.gender"
              placeholder="ContactInformation.select_salutation"
              options={[
                { value: 'M', displayValue: t('ContactInformation.sir') },
                { value: 'F', displayValue: t('ContactInformation.madam') },
              ]}
            />

            <StyledButton tag="button" type="submit" text="Buttons.save" classes={loading ? 'button--icon' : ''}>
              {loading && <LoadingIcon classes={'button__icon button__icon--loading'} />}
            </StyledButton>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};

export default AddressModal;
