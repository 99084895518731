import i18next from 'i18next';
import {
  atleastOneEstablishmentMessage,
  missingDetailsEstablishmentsMessage,
} from '../../../../../utilities/validations/messages';

export interface LocationError {
  locations?: string;
  hasContact?: boolean[];
}

export const LOCATIONS_SCHEMA = (values) => {
  const errors: LocationError = {};

  if (!values.locations.length) {
    errors.locations = i18next.t(atleastOneEstablishmentMessage);
    return errors;
  }

  const missingContact = values.locations.some((location) => !location.contactId);
  const hasContact = values.locations.map((location) => !!location.contactId);

  if (missingContact) {
    document.body.scrollIntoView({ behavior: 'smooth', block: 'start' });
    errors.locations = i18next.t(missingDetailsEstablishmentsMessage);
    errors.hasContact = hasContact;
  }

  return errors;
};
