import {
  domainTooLongMessage,
  invalidVatNumber,
  maxCharacters,
  minCharacters,
  requiredMessage,
  validWebAddressMessage,
} from '@utilities/validations/messages';
import { belgianVat, dutchVat, urlRegExp } from '@utilities/validations/regex';
import * as yup from 'yup';

export const REGISTER_TWO_SCHEMA = (t, hasCardsOrClicks, companyFromSearch) =>
  yup.object().shape({
    applicant: yup.object().shape({
      firstname: yup
        .string()
        .min(2, t(minCharacters, { amount: 2 }))
        .max(40, t(maxCharacters, { amount: 40 }))
        .required(t(requiredMessage)),
      middlename: yup.string().max(40, t(maxCharacters, { amount: 40 })),
      lastname: yup
        .string()
        .min(2, t(minCharacters, { amount: 2 }))
        .max(80, t(maxCharacters, { amount: 80 }))
        .required(t(requiredMessage)),
    }),
    company: yup
      .object()
      .shape({
        vatNumber: yup.string().when({
          is: () => companyFromSearch,
          then: yup
            .string()
            .test('checkVatNumber', t(invalidVatNumber), function (this: yup.TestContext) {
              if (!this.parent.vatNumber) return false;
              if (this.parent.vatNumber.match(dutchVat) || this.parent.vatNumber.match(belgianVat)) return true;
              return false;
            })
            .required(t(requiredMessage)),
          otherwise: yup.string().nullable(),
        }),
        industry: yup
          .string()
          .when({
            is: () => !!hasCardsOrClicks,
            then: yup.string().required(t(requiredMessage)),
          })
          .nullable(),
        website: yup
          .string()
          .when({
            is: () => !!hasCardsOrClicks,
            then: yup
              .string()
              .matches(urlRegExp, t(validWebAddressMessage))
              .optional()
              .max(64, t(domainTooLongMessage, { amount: 64 })),
          })
          .nullable(),
      })
      .nullable(),
  });
