import { FormikDatePicker } from '@components/Shared/form-elements/FormikDatePicker';
import { FormikSelect } from '@components/Shared/form-elements/FormikSelect';
import StyledButton from '@components/Shared/StyledButton';
import { FormikInput } from '@shared/form-elements/FormikInput';
import { Modal } from '@shared/modal/Modal';
import { LoadingIcon } from '@shared/svg/icons';
import { useModalActions } from '@utilities/context/ModalContext';
import { Form, Formik } from 'formik';
import React, { useCallback, useState } from 'react';
import { UboFormData } from './models/ubo-form-data.model';
import { countries } from '@utilities/countries';
import Radio from '@components/Shared/form-elements/Radio';
import { useUser } from '@state/user';
import { useTranslation } from 'react-i18next';
import { UBO_FORM_SCHEMA } from './validation/ubo-form-schema';
import { CountryPrefix, getHostCountryPrefix } from '@utilities/countryPrefix';

interface AddUboModalProps {
  id: string;
  title: string;
  initialValues: UboFormData;
  onSubmit: (values: UboFormData) => void;
}

const AVAILABLE_ROLES = ['Partner', 'Tekenbevoegde'];
const DEFAULT_ROLE = 'Tekenbevoegde';

const UboModal: React.FC<AddUboModalProps> = ({ id, title, initialValues, onSubmit }) => {
  const [loading, setLoading] = useState(false);
  const [powerOfRepresentation, setPowerOfRepresentation] = useState('');
  const { user } = useUser();
  const { t, i18n } = useTranslation();
  const hasCardsOrClicks = user.packageInformation.hasCards || user.packageInformation.hasClicks;
  const countryPrefix = getHostCountryPrefix();

  const { hide } = useModalActions();

  const submit = useCallback(
    (values: UboFormData) => {
      setLoading(true);

      const role = values.roles || DEFAULT_ROLE;
      const lowerCaseAvailableRoles = AVAILABLE_ROLES.map((availableRole) => availableRole.toLowerCase());
      const payload: UboFormData = {
        ...values,
        roles: lowerCaseAvailableRoles.includes(role.toLowerCase()) ? role : DEFAULT_ROLE,
        powerOfRepresentation,
      };

      onSubmit(payload);
      hide('add-ubo-modal');
      setLoading(false);
    },
    [hide, setLoading, onSubmit, powerOfRepresentation],
  );

  const handlePowerOfRepresentationChange = (value: string) => {
    setPowerOfRepresentation(value);
  };

  return (
    <Modal id={id} title={title} isClosable={true}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={UBO_FORM_SCHEMA(hasCardsOrClicks, t)}
        onSubmit={submit}
      >
        {({ setErrors }) => {
          i18n.on('languageChanged', () => {
            setErrors({});
          });
          return (
            <Form>
              <div className="address__modal">
                <div className="address__modal-row">
                  <FormikInput
                    formType="tertiary"
                    classes="form__item--half"
                    name="firstname"
                    label="ContactInformation.first_name"
                    type="text"
                  />
                  {countryPrefix !== CountryPrefix.Belgium && (
                    <FormikInput
                      formType="tertiary"
                      classes="form__item--half"
                      name="middlename"
                      label="ContactInformation.preposition"
                      type="text"
                    />
                  )}
                  <FormikInput
                    formType="tertiary"
                    classes="form__item--half"
                    name="lastname"
                    label="ContactInformation.surname"
                    type="text"
                  />
                </div>
                <div className="address__modal-row-full">
                  <FormikDatePicker name="dateOfBirth" label="ContactInformation.birth_date" style={{ marginTop: 3 }} />
                </div>
                <div className="address__modal-row">
                  <FormikSelect
                    name="gender"
                    placeholder="ContactInformation.salutation"
                    options={[
                      { value: 'M', displayValue: t('ContactInformation.sir') },
                      { value: 'F', displayValue: t('ContactInformation.madam') },
                    ]}
                  />
                  {hasCardsOrClicks && (
                    <FormikSelect
                      name="roles"
                      placeholder="UboModal.select_role"
                      options={[
                        { value: 'Tekenbevoegde', displayValue: t('UboModal.authorized_signatory') },
                        { value: 'Partner', displayValue: t('UboModal.partner') },
                      ]}
                    />
                  )}
                </div>
                {hasCardsOrClicks && (
                  <>
                    <div className="address__modal-row-full">
                      <FormikInput
                        formType="tertiary"
                        classes="form__item--half"
                        name="homeAddress"
                        label="UboModal.address"
                        type="text"
                      />
                    </div>
                    <div className="address__modal-row">
                      <FormikInput
                        formType="tertiary"
                        classes="form__item--half"
                        name="postalCode"
                        label="Address.postal_code"
                        type="text"
                      />
                      <FormikInput
                        formType="tertiary"
                        classes="form__item--full"
                        name="city"
                        label="UboModal.city"
                        type="text"
                      />
                    </div>
                    <div className="address__modal-row-full">
                      <FormikSelect
                        shouldTranslateValues
                        name="country"
                        placeholder="CheckoutFourCardsClicksShared.choose_country"
                        options={countries}
                      />
                    </div>
                    <div className="address__modal-row-full">
                      <FormikInput
                        formType="tertiary"
                        classes="form__item--half"
                        name="email"
                        label="ContactInformation.email"
                        type="text"
                      />
                    </div>
                  </>
                )}

                <div className="address__modal-row">
                  <FormikInput
                    formType="tertiary"
                    classes="form__item--half"
                    name="mobilePhone"
                    label="ContactInformation.mobile"
                    type="text"
                  />
                  <FormikInput
                    formType="tertiary"
                    classes="form__item--half"
                    name="phone"
                    label="ContactInformation.phone"
                    type="text"
                  />
                </div>
                {hasCardsOrClicks && (
                  <>
                    <div className="address__modal-row-full">
                      <Radio
                        label={t('UboModal.signing_authority')}
                        formType="tertiary"
                        name="powerOfRepresentation"
                        options={[
                          { value: 'Sole', displayValue: t('UboModal.sole') },
                          { value: 'Joint', displayValue: t('UboModal.joint') },
                          { value: 'Limited', displayValue: t('UboModal.limited') },
                        ]}
                        value={powerOfRepresentation}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handlePowerOfRepresentationChange(e.target.value)
                        }
                        smallerFont
                      />
                    </div>

                    {powerOfRepresentation === 'Limited' && (
                      <div className="address__modal-row-full">
                        <FormikInput
                          formType="tertiary"
                          classes="form__item--half"
                          name="limited"
                          label="UboModal.limited_description"
                          type="text"
                        />
                      </div>
                    )}
                  </>
                )}

                <StyledButton tag="button" type="submit" text="Buttons.save" classes={loading ? 'button--icon' : ''}>
                  {loading && <LoadingIcon classes={'button__icon button__icon--loading'} />}
                </StyledButton>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default UboModal;
