import { Field } from 'formik';
import React from 'react';
import Autocomplete, { AutocompleteProps } from './Autocomplete';

interface FormikAutocompleteProps extends AutocompleteProps {
  setVatNumberVisible?: (value: boolean) => void;
  showRegisterButton?: (value: boolean) => void;
  setCompanyFromSearch?: (value: boolean) => void;
  checkout?: boolean;
}

export const FormikAutocomplete: React.FC<FormikAutocompleteProps> = ({ name, ...props }) => {
  return (
    <Field name={name}>
      {({ field, meta }) => <Autocomplete {...props} {...field} hasError={Boolean(meta.touched && meta.error)} />}
    </Field>
  );
};
