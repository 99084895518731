import classNames from 'classnames';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { ArrowLeft, CheckRound } from './svg/icons';
import LanguageSelector from './LanguageSelector';
import { useTranslation } from 'react-i18next';
import { getHostCountryPrefix } from '@utilities/countryPrefix';

interface FormHeaderProps {
  title: string;
  text?: string;
  indicator?: string;
  status?: string;
  hasPrev?: boolean;
  prevUrl?: string;
}

const FormHeader: React.FC<FormHeaderProps> = ({ title, text, status, hasPrev, prevUrl, indicator }) => {
  const titleClasses = classNames({
    'form-header__title': true,
    'form-header__title--has-sub': Boolean(text),
  });

  const history = useHistory();
  const hostCountry = getHostCountryPrefix();
  const { t } = useTranslation();

  const handlePrev = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (prevUrl) {
      history.push(prevUrl);
      return;
    }

    history.goBack();
  };

  return (
    <header className="c-form-header">
      {hasPrev && (
        <button className="form-header__prev" onClick={handlePrev}>
          <ArrowLeft classes="form-header__prev-icon" />
          <span className="form-header__prev-label">{t('Buttons.previous')}</span>
        </button>
      )}
      <div className="language_selector">
        <LanguageSelector languagePrefix={hostCountry} />
      </div>
      {indicator && <p className="form-header__indicator">{indicator}</p>}
      {status && (
        <div className="form-header__status">
          <CheckRound classes="form-header__status-icon" />
          <div className="form-header__status-text">{t(status)}</div>
        </div>
      )}
      <h1 className={titleClasses} dangerouslySetInnerHTML={{ __html: t(title) }} />
      {text && <p className="form-header__text">{t(text)}</p>}
    </header>
  );
};

export default FormHeader;
