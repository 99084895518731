import React, { useCallback, useState } from 'react';
import { Modal } from '@components/Shared/modal/Modal';
import { Form, Formik } from 'formik';
import { useModalActions } from '@utilities/context/ModalContext';
import { FormikInput } from '@components/Shared/form-elements/FormikInput';
import { useTranslation } from 'react-i18next';
import StyledButton from '@components/Shared/StyledButton';
import { LoadingIcon } from '@components/Shared/svg/icons';
import { Company } from '@utilities/interfaces/user';
import { REGISTER_COMPANY_FORM_SCHEMA } from './validation/register-company-form-schema';
import { CountryPrefix, getHostCountryPrefix } from '@utilities/countryPrefix';

interface Props {
  id: string;
  title: string;
  initialValues: Company;
  onSubmit: (values: Company) => void;
}

const RegisterCompanyModal: React.FC<Props> = ({ id, title, initialValues, onSubmit }) => {
  const [loading, setLoading] = useState(false);
  const hostCountry = getHostCountryPrefix();
  const { hide } = useModalActions();
  const { t } = useTranslation();

  const submit = useCallback(
    (values: Company) => {
      setLoading(true);

      const payload: Company = { ...values };

      onSubmit(payload);
      hide('register-company-modal');
      setLoading(false);
    },
    [hide, setLoading, onSubmit],
  );

  return (
    <Modal id={id} title={t(title)} isClosable={true}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={REGISTER_COMPANY_FORM_SCHEMA(t, hostCountry)}
        onSubmit={submit}
      >
        <Form>
          <div className="address__modal">
            <div className="address__modal-row-full">
              <FormikInput
                formType="tertiary"
                classes="form__item--half"
                name="name"
                label="RegisterTwo.company_name"
                type="text"
              ></FormikInput>
            </div>
            <div className="address__modal-full">
              <FormikInput
                formType="tertiary"
                classes="form__item--full"
                name="registrationNumber"
                label="RegisterTwo.registration_number"
                type="text"
              ></FormikInput>
            </div>
            <div className="address__modal-row-full">
              <FormikInput
                formType="tertiary"
                classes="form__item--half"
                name="vatNumber"
                label="RegisterTwo.vat_number"
                type="text"
              ></FormikInput>
            </div>
            <div className="address__modal-row-full">
              <FormikInput
                formType="tertiary"
                classes="form__item--half"
                name="descriptionOfBusinessActivities"
                label="RegisterTwo.company_activity"
                type="text"
              ></FormikInput>
            </div>
            <div className="address__modal-row">
              <FormikInput
                formType="tertiary"
                classes="form__item--half"
                name="address.street"
                label="Address.street"
                type="text"
              ></FormikInput>
              <FormikInput
                formType="tertiary"
                classes="form__item--half"
                name="address.houseNumber"
                label="Address.housenumber"
                type="text"
              ></FormikInput>
            </div>
            <div className="address__modal-row">
              <FormikInput
                formType="tertiary"
                classes="form__item--half"
                name="address.zipCode"
                label="Address.postal_code"
                type="text"
              />
              <FormikInput
                formType="tertiary"
                classes="form__item--full"
                name="address.city"
                label="UboModal.city"
                type="text"
              />
            </div>
            <StyledButton tag="button" type="submit" text="Buttons.save" classes={loading ? 'button--icon' : ''}>
              {loading && <LoadingIcon classes={'button__icon button__icon--loading'} />}
            </StyledButton>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};

export default RegisterCompanyModal;
