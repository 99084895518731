import { BlubeemModelsApiProduct } from '@generated/brinks.schemas';
import Collapse from '@shared/collapse/Collapse';
import { FormikRadio } from '@shared/form-elements/FormikRadio';
import { FormikToggle } from '@shared/form-elements/FormikToggle';
import { useUser } from '@state/user';
import { useFormikContext } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CheckoutStep4FormData } from '../../models/checkout-step-4-form.model';
import { ServiceType } from '../../models/service-type.enum';
import Accessories from '../shared/Accessories';
import FormTitle from '../shared/FormTitle';
import { Hours } from './Hours';
import { ProductType } from '@utilities/interfaces/user';
import GroupButton from '../shared/GroupButton';
import { useTranslation } from 'react-i18next';

const PAGE_SERVICE_TYPE = ServiceType.Cash;
interface Props {
  products: BlubeemModelsApiProduct[];
  locationIndex: number;
  resetValue: boolean;
  resetAccessoires: number;
}

export const Cash: React.FC<Props> = ({ products, locationIndex, resetValue, resetAccessoires }) => {
  const { user } = useUser();
  const isBrinks = useMemo(() => user.productType === ProductType.BRINKS, [user.productType]);
  const [cashActive, setCashActive] = useState<boolean>(true);
  const { t } = useTranslation();

  const { setFieldValue, values, setFieldTouched } = useFormikContext<CheckoutStep4FormData>();

  const cashProducts = useMemo(
    () => products.filter((product) => product.serviceType?.toLowerCase() === PAGE_SERVICE_TYPE),
    [products],
  );

  const serviceIndex = useMemo(
    () => values.services.findIndex((service) => service.name === PAGE_SERVICE_TYPE),
    [values],
  );

  // TODO 1171: Do we need to change these checks for brinks and switch to yaml?
  const active = useMemo(
    () => (isBrinks ? true : values.services.find((service) => service.name === PAGE_SERVICE_TYPE).active),
    [values, isBrinks, locationIndex],
  );

  const setAllTogglesTouchedForValidation = useCallback(() => {
    values.services.forEach((_, index) => setFieldTouched(`services[${index}].active`, true, true));
  }, [values.services, setFieldTouched]);

  useEffect(() => {
    setTimeout(() => {
      if (isBrinks) setFieldValue(`services[${serviceIndex}].active`, true);
    }, 250);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBrinks, locationIndex]);

  return (
    <div className={`${active ? 'collapse__wrapper-active' : null}`}>
      {!isBrinks && (
        <div className="cash-cards-clicks__button-toggle">
          <GroupButton title={'Cash'} defaultActive={true} onToggle={setCashActive}>
            <div className="cash-cards-clicks__item">
              <FormikToggle
                formType="secondary"
                label={active ? 'CheckoutFourCash.cash_off' : 'CheckoutFourCash.cash_on'}
                uncheckedLabel="CheckoutFourCash.cash_on"
                checkedLabel="CheckoutFourCash.cash_off"
                name={`services[${serviceIndex}].active`}
                hideLabel={true}
                optimized={false}
                onChange={() => {
                  setFieldValue(`services[${serviceIndex}].active`, !active);
                  setAllTogglesTouchedForValidation();
                }}
              />
            </div>
          </GroupButton>
        </div>
      )}

      <Collapse active={active}>
        <FormTitle variant="primary" title="CheckoutFourCash.fill_in_data" />
        <Hours locationIndex={locationIndex} />
        <div className="cash-cards-clicks__item">
          <FormikRadio
            name={`services[${serviceIndex}].alarm`}
            label="CheckoutFourCash.alarm"
            labelSubtitle="CheckoutFourCash.alarm_system_text"
            options={[
              { value: 'true', displayValue: 'CheckoutFourCash.yes' },
              { value: 'false', displayValue: 'CheckoutFourCash.no' },
            ]}
          />
        </div>

        {cashProducts.length > 0 && (
          <Accessories
            products={cashProducts}
            service={values?.services[serviceIndex]}
            resetValue={resetValue}
            resetAccessoires={resetAccessoires}
          />
        )}
      </Collapse>
    </div>
  );
};
