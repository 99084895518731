import { BlubeemModelsApiLocationDay, OpeningHours } from '@generated/brinks.schemas';
import {
  closingTimeMessage,
  invalidAdditionalTimeMessage,
  maxTwoDecimals,
  requiredMessage,
  selectAValueMessage,
  selectDayMessage,
} from '@utilities/validations/messages';
import { maxTwoDecimalRegExp } from '@utilities/validations/regex';
import * as yup from 'yup';
import { CheckoutStep4Service } from '../models/checkout-step-4-form.model';
import { ServiceType } from '../models/service-type.enum';

export function isTimeAfter(openingTime: string, closingTime?: string): boolean {
  const d1 = new Date();
  const d2 = new Date();

  if (!closingTime) return true;

  const openTime = openingTime.split(':');
  const endTime = closingTime.split(':');
  d1.setHours(+openTime[0]);
  d2.setHours(+endTime[0]);
  d1.setMinutes(Number(openTime[1]));
  d2.setMinutes(+endTime[1]);
  return d2.getTime() > d1.getTime();
}

function timeToMinutes(timeString) {
  if (timeString != undefined) {
    const [hour, minute] = timeString.split(':').map(Number);
    return hour * 60 + minute;
  }
}

export const cashCardsClickCustomValidations = {
  hours: function (this: yup.TestContext) {
    const { cash, monday, tuesday, wednesday, thursday, friday, saturday, sunday } = this.parent;
    return !cash || (cash && (monday || tuesday || wednesday || thursday || friday || saturday || sunday));
  },
  time: function (this: yup.TestContext, value: any) {
    //const { openingTime } = this.parent as BlubeemModelsApiLocationDay;
    const openingHours: OpeningHours[] = (this.parent as BlubeemModelsApiLocationDay).openingHours;

    //return isTimeAfter(openingTime, value);
    return openingHours.every((openingHour) => isTimeAfter(openingHour.openingTime, value));
  },
};

export const CASH_SCHEMA = (t) => {
  return {
    dayInformation: yup
      .array()
      .when(['active', 'name'], {
        is: (active, name) => name === ServiceType.Cash && active,
        then: yup.array().of(
          yup.object().shape({
            open: yup.boolean().test('is-one-true', t(selectDayMessage), function (this: any) {
              const cashService = this.from.find((service) => service.value.name === ServiceType.Cash)
                .value as CheckoutStep4Service;

              return cashService.dayInformation.filter((day) => day.open).length > 0;
            }),
            openingHours: yup.array().when('open', {
              is: true,
              then: yup
                .array()
                .of(
                  yup.object().shape({
                    openingTime: yup.string().required(t(requiredMessage)),
                    closingTime: yup.string().test('is-greater', t(closingTimeMessage), function (value) {
                      const { openingTime } = this.parent;
                      if (!openingTime || !value) return true;

                      const openingTimes = timeToMinutes(openingTime);
                      const closingTimes = timeToMinutes(value);

                      if (closingTimes < openingTimes) return false;

                      return true;
                    }).required(t(requiredMessage)),
                  }),
                )
                .test('opening-time-check', t(invalidAdditionalTimeMessage), function (value) {
                  const { openingHours } = this.parent;

                  if (openingHours.length === 2) {
                    const [firstOpening, secondOpening] = openingHours;

                    const firstClosingTime = timeToMinutes(firstOpening.closingTime);
                    const secondOpeningTime = timeToMinutes(secondOpening.openingTime);

                    if (secondOpeningTime < firstClosingTime) {
                      return new yup.ValidationError(
                        t(invalidAdditionalTimeMessage),
                        null,
                        `${this.path}[1].openingTime`,
                      );
                    }
                  }
                  return true;
                }),
            }),

            turnoverSpeed: yup.number().when('open', {
              is: true,
              then: yup
                .number()
                .test('decimals', t(maxTwoDecimals), (value) => maxTwoDecimalRegExp.test(String(value)))
                .test('exists', t(requiredMessage), function (this: yup.TestContext) {
                  const { turnoverSpeed } = this.parent;

                  return !!turnoverSpeed;
                }),
            }),
          }),
        ),
      })
      .nullable(),
    alarm: yup
      .string()
      .when(['active', 'name'], {
        is: (active, name) => name === ServiceType.Cash && active,
        then: yup.string().test('exists', t(selectAValueMessage), function (this: yup.TestContext) {
          const { alarm } = this.parent;

          if (!alarm) return true;

          return ['true', 'false'].includes(alarm);
        }),
        otherwise: undefined,
      })
      .nullable(),
  };
};
