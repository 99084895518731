import { FormikMultiSelect } from '@components/Shared/form-elements/FormikMultiSelect';
import { FormikNumberInput } from '@components/Shared/form-elements/FormikNumberInput';
import { FormikRadio } from '@components/Shared/form-elements/FormikRadio';
import { FormikRange } from '@components/Shared/form-elements/FormikRange';
import { FormikSelect } from '@components/Shared/form-elements/FormikSelect';
import { BlubeemModelsApiPicklistValue, BlubeemModelsApiProduct } from '@generated/brinks.schemas';
import Collapse from '@shared/collapse/Collapse';
import { FormikProductInput } from '@shared/form-elements/FormikProductInput';
import { FormikToggle } from '@shared/form-elements/FormikToggle';
import { countries } from '@utilities/countries';
import { useFormikContext } from 'formik';
import React, { useCallback, useMemo, useState } from 'react';
import { CheckoutStep4FormData } from '../../models/checkout-step-4-form.model';
import { ServiceType } from '../../models/service-type.enum';
import Accessories from '../shared/Accessories';
import FormTitle from '../shared/FormTitle';
import GroupButton from '../shared/GroupButton';
import { useTranslation } from 'react-i18next';
import { reasonForSwitching } from '@utilities/reasonForSwitching';

const PAGE_SERVICE_TYPE = ServiceType.Clicks;

interface Props {
  products: BlubeemModelsApiProduct[];
  formerPSPPicklistValues: BlubeemModelsApiPicklistValue[];
  resetValue: boolean;
  resetAccessoires: number;
}

export const Clicks: React.FC<Props> = ({ products, formerPSPPicklistValues, resetValue, resetAccessoires }) => {
  const { t } = useTranslation();
  const { setFieldValue, values, setFieldTouched } = useFormikContext<CheckoutStep4FormData>();
  const [clicksActive, setClicksActive] = useState<boolean>(true);

  const clicksProducts = useMemo(
    () => products.filter((product) => product.serviceType?.toLowerCase() === PAGE_SERVICE_TYPE),
    [products],
  );

  const serviceIndex = useMemo(
    () => values.services.findIndex((service) => service.name === PAGE_SERVICE_TYPE),
    [values],
  );
  const active = useMemo(() => values.services.find((service) => service.name === PAGE_SERVICE_TYPE).active, [values]);

  const setAllTogglesTouchedForValidation = useCallback(() => {
    values.services.forEach((_, index) => setFieldTouched(`services[${index}].active`, true, true));
  }, [values.services, setFieldTouched]);

  return (
    <>
      <div className="cash-cards-clicks__button-toggle">
        <GroupButton title={'Clicks'} defaultActive={true} onToggle={setClicksActive}>
          <div className="cash-cards-clicks__item">
            <FormikToggle
              formType="secondary"
              label={active ? 'CheckoutFourClicks.clicks_off' : 'CheckoutFourClicks.clicks_on'}
              checkedLabel="CheckoutFourClicks.clicks_off"
              uncheckedLabel="CheckoutFourClicks.clicks_on"
              name={`services[${serviceIndex}].active`}
              hideLabel={true}
              optimized={false}
              onChange={() => {
                setFieldValue(`services[${serviceIndex}].active`, !active);
                setAllTogglesTouchedForValidation();
              }}
            />
          </div>
        </GroupButton>
      </div>
      <Collapse active={active}>
        <div className="cash-cards-clicks__item">
          <FormikNumberInput
            title="CheckoutFourClicks.delivery_time"
            label="CheckoutFourClicks.delivery_time"
            subtitle="CheckoutFourCardsClicksShared.average_lead_time_in_days"
            name={`services[${serviceIndex}].clicksLeadTimeInDays`}
            formType="tertiary"
          />
        </div>
        <div className="cash-cards-clicks__item">
          <FormikProductInput
            label="CheckoutFourClicks.type_of_products"
            labelSubtitle="CheckoutFourClicks.type_of_products_sold_online"
            name={`services[${serviceIndex}].productTypesOnline`}
            valueToArray
            optimized={false}
            placeholder="CheckoutFourClicks.example_products"
          />
        </div>
        <div className="cash-cards-clicks__item">
          <FormikProductInput
            label="CheckoutFourClicks.webshop_url"
            labelSubtitle="CheckoutFourClicks.url_to_website"
            optimized={false}
            name={`services[${serviceIndex}].webshopUrl`}
          />
        </div>
        <div className="cash-cards-clicks__item">
          <FormikNumberInput
            title="CheckoutFourClicks.average_revenue_clicks_per_month"
            label="CheckoutFourClicks.average_revenue_clicks_per_month"
            subtitle="CheckoutFourClicks.expected_revenue_clicks_per_month"
            name={`services[${serviceIndex}].averageOnlineRevenuePerMonth`}
            formType="tertiary"
            prefix="€"
          />
        </div>
        <div className="cash-cards-clicks__item">
          <FormikRange
            label="CheckoutFourCardsClicksShared.seasonal_revenue"
            name={`services[${serviceIndex}].clicksSeasonalRevenuePercentage`}
            labelSubtitle="CheckoutFourClicks.percentage_of_revenue_seasonal_or_periodic"
            suffix="%"
            resetValue={resetValue}
          />
        </div>
        <div className="cash-cards-clicks__item">
          <FormikMultiSelect
            label="CheckoutFourClicks.target_countries"
            labelSubtitle="CheckoutFourClicks.target_countries"
            name={`services[${serviceIndex}].selectedCountries`}
            placeholder="CheckoutFourCardsClicksShared.choose_country"
            options={countries.map((current) => ({
              value: current.value,
              label: t(current.displayValue),
            }))}
          />
        </div>
        <div className="cash-cards-clicks__item">
          <FormikRange
            name={`services[${serviceIndex}].clicksAverageRevenuePerPaymentMethodDifference`}
            label="CheckoutFourClicks.percentage_of_revenue_per_payment_method"
            labelSubtitle="CheckoutFourClicks.percentage_credit_versus_online_banking_and_transfer"
            suffix="%"
            minLabel="CheckoutFourClicks.credit"
            maxLabel="CheckoutFourClicks.online_banking_and_transfer"
            split
            initialValue={0}
            resetValue={resetValue}
          />
        </div>
        <div className="cash-cards-clicks__item">
          <FormikRadio
            name={`services[${serviceIndex}].recurringCreditCardPayments`}
            label="CheckoutFourClicks.recurring_credit_payments"
            labelSubtitle=""
            options={[
              { value: 'true', displayValue: 'CheckoutFourClicks.yes' },
              { value: 'false', displayValue: 'CheckoutFourClicks.no' },
            ]}
          />
        </div>
        {clicksProducts.length > 0 && (
          <Accessories
            products={clicksProducts}
            service={values?.services[serviceIndex]}
            resetValue={resetValue}
            resetAccessoires={resetAccessoires}
          />
        )}
        <div className="cash-cards-clicks__item">
          <FormikRange
            name={`services[${serviceIndex}].clicksConsumptionDistributionDifference`}
            label="CheckoutFourCardsClicksShared.distribution_consumer_versus_corporate"
            labelSubtitle="CheckoutFourCardsClicksShared.percentage_consumer_versus_corporate"
            suffix="%"
            minLabel="CheckoutFourCardsClicksShared.consumer"
            maxLabel="CheckoutFourCardsClicksShared.corporate"
            split
            initialValue={0}
            resetValue={resetValue}
          />
        </div>
        <div className="cash-cards-clicks__item">
          <FormikRange
            name={`services[${serviceIndex}].clicksDomesticTargetMarketPercentage`}
            label="CheckoutFourCardsClicksShared.percentage_domestic_target_market"
            suffix="%"
            resetValue={resetValue}
          />
        </div>
        <div className="cash-cards-clicks__item">
          <FormikSelect
            shouldTranslateValues
            optimized={false}
            label="CheckoutFourCardsClicksShared.domestic_country"
            labelSubtitle="CheckoutFourCardsClicksShared.target_market_country"
            name={`services[${serviceIndex}].clicksDomesticTargetCountryName`}
            placeholder="CheckoutFourCardsClicksShared.choose_country"
            options={countries}
          />
        </div>
        <div className="cash-cards-clicks__item">
          <FormikRange
            name={`services[${serviceIndex}].clicksIntraRegionalTargetMarketDifference`}
            label="CheckoutFourClicks.distribution_intra_regional_eu_eer"
            labelSubtitle="CheckoutFourCardsClicksShared.percentage_inside_and_outside_eu"
            suffix="%"
            minLabel="CheckoutFourCardsClicksShared.inside_eu"
            maxLabel="CheckoutFourCardsClicksShared.outside_eu"
            split
            resetValue={resetValue}
          />
        </div>
        <div className="cash-cards-clicks__item">
          <FormikMultiSelect
            label="CheckoutFourClicks.intraregional_target_market_inside_eu"
            labelSubtitle="CheckoutFourCardsClicksShared.select_countries"
            name={`services[${serviceIndex}].clicksIntraRegionalTargetMarketInEuCountries`}
            placeholder="CheckoutFourCardsClicksShared.choose_country"
            options={countries.map((current) => ({
              value: current.value,
              label: t(current.displayValue),
            }))}
          />
        </div>
        <div className="cash-cards-clicks__item">
          <FormikMultiSelect
            label="CheckoutFourClicks.intraregional_target_market_outside_eu"
            labelSubtitle="CheckoutFourCardsClicksShared.select_countries"
            placeholder="CheckoutFourCardsClicksShared.choose_country"
            name={`services[${serviceIndex}].clicksIntraRegionalTargetMarketOutsideEuCountries`}
            options={countries.map((current) => ({
              value: current.value,
              label: t(current.displayValue),
            }))}
          />
        </div>
        <div className="cash-cards-clicks__item">
          <FormikNumberInput
            label="CheckoutFourCardsClicksShared.expected_total_volume_per_year_in_euros"
            title="CheckoutFourCardsClicksShared.expected_total_volume_per_year_in_euros"
            name={`services[${serviceIndex}].clicksExpectedTotalVolumeAnnually`}
            formType="tertiary"
            prefix="€"
          />
        </div>
        <div className="cash-cards-clicks__item">
          <FormikNumberInput
            label="CheckoutFourCardsClicksShared.average_transaction_value"
            title="CheckoutFourCardsClicksShared.average_transaction_value"
            subtitle="CheckoutFourClicks.average_transaction_value_per_payment"
            name={`services[${serviceIndex}].clicksAverageTransactionValue`}
            formType="tertiary"
            prefix="€"
          />
        </div>
        <div className="cash-cards-clicks__item">
          <FormikNumberInput
            label="CheckoutFourCardsClicksShared.highest_transaction_value"
            title="CheckoutFourCardsClicksShared.highest_transaction_value"
            subtitle="CheckoutFourClicks.highest_transaction_value_per_payment"
            name={`services[${serviceIndex}].clicksHighestTransactionValue`}
            formType="tertiary"
            prefix="€"
          />
        </div>
        <FormTitle variant="primary" title="CheckoutFourCardsClicksShared.transaction_history" />
        <div className="cash-cards-clicks__item">
          <FormikRadio
            name={`services[${serviceIndex}].madePaymentsWithAnotherPsp`}
            label="CheckoutFourCardsClicksShared.transaction_history"
            labelSubtitle="CheckoutFourCardsClicksShared.previous_payments_other_psp"
            options={[
              { value: 'true', displayValue: 'CheckoutFourClicks.yes' },
              { value: 'false', displayValue: 'CheckoutFourClicks.no' },
            ]}
          />
        </div>
        <Collapse active={(values.services[serviceIndex].madePaymentsWithAnotherPsp as any) === 'true'}>
          <div className="cash-cards-clicks__item">
            <FormikSelect
              optimized={false}
              label="CheckoutFourCardsClicksShared.previous_psp"
              name={`services[${serviceIndex}].clicksNameFormerPsp`}
              placeholder="CheckoutFourCardsClicksShared.choose_psp"
              options={formerPSPPicklistValues.map((value) => ({
                value: value.value,
                displayValue: value.displayValue,
              }))}
            />
          </div>
          <div className="cash-cards-clicks__item">
            <FormikSelect
              optimized={false}
              label="CheckoutFourCardsClicksShared.reason_for_switch"
              name={`services[${serviceIndex}].clicksReasonForSwitching`}
              shouldTranslateValues
              placeholder="CheckoutFourCardsClicksShared.choose_reason"
              options={reasonForSwitching}
            />
          </div>
          <div className="cash-cards-clicks__item">
            <FormikNumberInput
              title="CheckoutFourCardsClicksShared.total_transactions_last_three_months_current_psp"
              label="CheckoutFourCardsClicksShared.payment_history_current_psp"
              subtitle=""
              name={`services[${serviceIndex}].clicksProcessedVolumeLastThreeMonths`}
              formType="tertiary"
            />
          </div>
          <div className="cash-cards-clicks__item">
            <FormikRange
              name={`services[${serviceIndex}].clicksReturnPaymentPercentage`}
              label="CheckoutFourCardsClicksShared.return_payment_volume"
              formType="tertiary"
              suffix="%"
              resetValue={resetValue}
            />
          </div>
          <div className="cash-cards-clicks__item">
            <FormikRange
              name={`services[${serviceIndex}].clicksChargebackPercentage`}
              label="CheckoutFourCardsClicksShared.charge_back_volume"
              formType="tertiary"
              suffix="%"
              resetValue={resetValue}
            />
          </div>
          <div className="cash-cards-clicks__item">
            <FormikRange
              name={`services[${serviceIndex}].clicksFraudVolumePercentage`}
              label="CheckoutFourCardsClicksShared.fraud_volume"
              formType="tertiary"
              suffix="%"
              resetValue={resetValue}
            />
          </div>
        </Collapse>
      </Collapse>
    </>
  );
};
