import {
  BlubeemModelsApiLocation,
  BlubeemModelsApiProduct,
  SalesforceSdkModelsOpportunityLineItemRequest,
} from '@generated/brinks.schemas';
import { getContact } from '@generated/contact';
import { getLocation, putLocation } from '@generated/location';
import { postOpportunityPackage, putOpportunityStep } from '@generated/opportunity';
import { getProductSourceParent } from '@generated/product';
import FormHeader from '@shared/FormHeader';
import FormLayout from '@shared/FormLayout';
import FormLayoutColOne from '@shared/FormLayoutColOne';
import FormLayoutColTwo from '@shared/FormLayoutColTwo';
import { Chevron, PencilIcon } from '@shared/svg/icons';
import CompanyTile from '@shared/tiles/CompanyTile';
import { useUser } from '@state/user';
import { addressModalInitialValues } from '@utilities/constants/user';
import { useLoader } from '@utilities/context/LoaderContext';
import { useModalActions } from '@utilities/context/ModalContext';
import classNames from 'classnames';
import { Formik, FormikProps } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Slider, { Settings } from 'react-slick';
import { serializeLocationDetails } from '../shared/edit-location/location-details.converter';
import { LocationDetailsFormValues } from '../shared/edit-location/location-details.model';
import { updateLocationDetails } from '../shared/edit-location/update-location';
import AddressModal from '../shared/modals/AddressModal';
import CashCardsClicks from './CashCardsClicks/CashCardsClicks';
import { convertLocationDataToForm, deserializeFormData } from './converters/form-data.converter';
import { CheckoutStep4FormData } from './models/checkout-step-4-form.model';
import { useTranslation } from 'react-i18next';
import { CHECKOUT_FOUR_SCHEMA } from './validation/checkout-four-schema';

const CheckoutFour: React.FC = () => {
  const { dispatch } = useModalActions();
  const { toggleLoader } = useLoader();
  const history = useHistory();
  const { user, setUser } = useUser();
  const { t, i18n } = useTranslation();

  const componentRef = useRef<Slider>(null);
  const formRef = useRef<FormikProps<CheckoutStep4FormData>>(null);

  const [submitType, setSubmitType] = useState<'nextSlide' | 'saveAllNextStep' | 'prevSlide' | 'copyToAll' | ''>('');
  const [modalInitialValues, setModalInitialValues] = useState<LocationDetailsFormValues>(addressModalInitialValues);
  const [currentLocation, setCurrentLocation] = useState(0);
  const [products, setProducts] = useState<BlubeemModelsApiProduct[]>([]);

  const [slideIndex, setSlideIndex] = useState(0);

  const locationSliderSettings: Settings = {
    dots: false,
    centerMode: true,
    centerPadding: '12px',
    infinite: false,
    slidesToShow: 1,
    afterChange: (nextSlide) => handleSlide(nextSlide, slideIndex),
  };

  const handleSlide = (nextSlide: number, currentSlide: number) => {
    if (nextSlide === currentSlide) return;
    setSubmitType(nextSlide > currentSlide ? 'nextSlide' : 'prevSlide');
    formRef.current.handleSubmit();
    setTimeout(() => {
      formRef.current.isValid ? setSlideIndex(nextSlide) : componentRef.current.slickGoTo(currentSlide);
    }, 100);
  };

  const submitFormData = (values: CheckoutStep4FormData, submitType: string, index: number) => {
    const location = user.locations[index];
    const locationData = deserializeFormData(location, values);

    // This is a requirement for the backend API to find the clicks package and add it as an accessory.
    // The clicks package is not visible in the onboarding funnel, but still needs to be added in Salesforce.
    // The structure of the ProductCode for products that allow clicks will always start with BLU<package version as a single character>
    locationData.clicks.clicksVersion = locationData.clicks.clicksEnabled
      ? user?.packageInformation?.code?.charAt(3)
      : null;

    switch (submitType) {
      case 'prevSlide':
        storeLocation(locationData, index);
        document.body.scrollIntoView({ behavior: 'smooth', block: 'start' });
        break;
      case 'nextSlide':
        storeLocation(locationData, index);
        document.body.scrollIntoView({ behavior: 'smooth', block: 'start' });
        break;
      case 'saveAllNextStep':
        saveAllLocations(locationData, index);
        break;
      case 'copyToAll':
        const locations = user.locations.map((location, i) =>
          i >= index ? deserializeFormData(location, values) : location,
        );
        setUser({ ...user, locations });
        saveAllLocations(locationData, index, locations);
        break;
    }
  };

  const storeLocation = (values: BlubeemModelsApiLocation, index: number) => {
    const locations = [...user.locations];
    locations[index] = values;
    setUser({ ...user, locations });
    return locations;
  };

  const saveAllLocations = async (
    values: BlubeemModelsApiLocation,
    index: number,
    savedLocations?: BlubeemModelsApiLocation[],
  ) => {
    toggleLoader(true);
    const locations = savedLocations ? savedLocations : storeLocation(values, index);

    await Promise.all([
      ...locations.map(async (location) => {
        await putLocation(location);

        const payload: SalesforceSdkModelsOpportunityLineItemRequest = {
          locationId: location.id,
          productCode: user.packageId,
        };
        await postOpportunityPackage(payload);
      }),
    ]);
    toggleLoader(false);
    history.push('/checkout/step-5');
  };

  const getProducts = async () => {
    toggleLoader(true);

    const { data } = await getProductSourceParent(user.productType, user.packageId);
    setProducts(data);

    toggleLoader(false);
  };

  const getStep4Data = async (withProducts = false) => {
    toggleLoader(true);
    const { data: locations } = await getLocation();

    setUser({ locations });
    const { data: contacts } = await getContact();
    setUser({ contacts });

    if (withProducts) {
      await getProducts();
    }
    toggleLoader(false);
  };

  const updateLocation = async (newFormValues: LocationDetailsFormValues) => {
    const location = user?.locations[currentLocation];
    const contact = user.contacts.find((contact) => contact.id === location.contactId);

    toggleLoader(true);
    await updateLocationDetails(contact, location, newFormValues);
    await getStep4Data();
    toggleLoader(false);
  };

  const openEditModal = (locationIndex) => {
    setCurrentLocation(locationIndex);

    if (!user?.locations) return;

    const { contactId, ...location } = user?.locations[locationIndex];
    const contact = user.contacts.find((contact) => contact.id === contactId);
    const locationFormValues = serializeLocationDetails(contact, location);

    setModalInitialValues(locationFormValues);
    dispatch({ type: 'show', key: 'address-modal' });
  };

  useEffect(() => {
    putOpportunityStep({ step: '4' });
    getStep4Data(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!user?.locations) return null;

  const hasMultipleLocations = user?.locations.length > 1;

  return (
    <FormLayout>
      <AddressModal
        initialValues={modalInitialValues}
        onSubmit={updateLocation}
        id="address-modal"
        title={t('CheckoutFour.change_address')}
      />
      <FormLayoutColOne>
        {user?.locations && (
          <>
            <FormHeader
              title="CheckoutFour.order_overview"
              indicator="Stap 4/6"
              text="CheckoutFour.fill_details_per_establishment"
              hasPrev
              prevUrl="step-3"
            />

            <div className="company-tile__slider-wrapper">
              <Slider {...locationSliderSettings} ref={componentRef}>
                {user?.locations.map((location, locationIndex) => (
                  <CompanyTile
                    key={location.name}
                    variant="primary"
                    name={location.name}
                    address={location}
                    classes="company-tile__slide"
                  >
                    <div className="company-tile__button-wrapper">
                      <button className="company-tile__button" onClick={() => openEditModal(locationIndex)}>
                        {t('Buttons.edit')} <PencilIcon />
                      </button>
                    </div>
                  </CompanyTile>
                ))}
              </Slider>

              {hasMultipleLocations && (
                <div className="company-tile__slider-navigation">
                  <button
                    className="company-tile__slider-button"
                    onClick={() => componentRef?.current.slickPrev()}
                    disabled={!slideIndex}
                  >
                    <Chevron fill="#fff" direction="left" /> <span>{t('Buttons.previous')}</span>
                  </button>

                  <ul className="company-tile__slider-dots">
                    {user?.locations.map((key, index) => {
                      const dotClasses = classNames({
                        'company-tile__slider-dot': true,
                        'company-tile__slider-dot--active': index === slideIndex,
                      });

                      return <li className={dotClasses} key={`${key.name}-${index}`}></li>;
                    })}
                  </ul>

                  <button
                    className="company-tile__slider-button"
                    onClick={() => componentRef?.current.slickNext()}
                    disabled={slideIndex === user?.locations.length - 1}
                  >
                    <span>{t('Buttons.next')}</span> <Chevron fill="#fff" direction="right" />
                  </button>
                </div>
              )}
            </div>
          </>
        )}
      </FormLayoutColOne>
      <FormLayoutColTwo>
        <Formik
          innerRef={formRef}
          initialValues={convertLocationDataToForm(user.locations[slideIndex])}
          validationSchema={CHECKOUT_FOUR_SCHEMA(t)}
          onSubmit={(values) => submitFormData(values, submitType, slideIndex)}
        >
          {({ setErrors }) => {
            // Adding i18next language change handler
            i18n.on('languageChanged', () => {
              setErrors({});
            });

            return (
              <>
                {user?.locations[slideIndex] && (
                  <CashCardsClicks
                    currentSlideIndex={slideIndex}
                    setSubmitType={setSubmitType}
                    nrOflocations={user?.locations.length}
                    products={products}
                    nextSlide={() => {
                      componentRef.current.slickNext();
                      setSubmitType('nextSlide');
                    }}
                  />
                )}
              </>
            );
          }}
        </Formik>
      </FormLayoutColTwo>
    </FormLayout>
  );
};

export default CheckoutFour;
