import {
  BlubeemRequestsConfirmRegistrationRequest,
  BlubeemRequestsRegistrationRequest,
} from '@generated/brinks.schemas';
import { postRegister, putRegister } from '@generated/register';
import Loader from '@shared/Loader';
import Toast from '@shared/Toast';
import { emptyUser } from '@utilities/constants/user';
import { LoaderProvider, useLoader } from '@utilities/context/LoaderContext';
import { ToastProvider, useToast } from '@utilities/context/ToastContext';
import { UserProps } from '@utilities/interfaces/user';
import { Api } from '@utilities/services/Api';
import { setToken as setAxiosToken } from '@utilities/services/setToken';
import axios, { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import RegisterConfirmation from './components/RegisterConfirmation';
import RegisterOne from './components/RegisterOne/RegisterOne';
import RegisterTwo, { SocialMedia } from './components/RegisterTwo/RegisterTwo';
import { useGetCurrentProduct } from '@utilities/hooks/useGetCurrentProduct';
import { ModalContextProvider } from '@utilities/context/ModalContext';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { getHostCountryPrefix } from '@utilities/countryPrefix';

const Routing: React.FC = () => {
  const history = useHistory();

  const { makeToast } = useToast();
  const { path } = useRouteMatch();
  const { toggleLoader } = useLoader();
  const { currentProduct } = useGetCurrentProduct();

  const [packageid, setPackageid] = useState<string>();
  const [user, setUser] = useState<UserProps>(emptyUser);
  const [token, setToken] = useState<string | boolean>(false);

  const { t, i18n } = useTranslation();

  const socialMediaString = (socialMediaFields: SocialMedia[]) => {
    if (!socialMediaFields) {
      return '';
    }

    const filledSocialMediaFields = socialMediaFields.filter(
      (field) => field.mediaType && field.mediaUrl && field.mediaType !== 'Geen',
    );

    return filledSocialMediaFields.map((field) => `${field.mediaType}:${field.mediaUrl}`).join(',');
  };

  useEffect(() => {
    const data = sessionStorage.getItem('USER_DATA');

    if (data) setUser(JSON.parse(data));

    if (token) {
      Api.setToken(token as string);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const newUser = { ...user, packageId: packageid, productType: currentProduct };
    setUser(newUser);
  }, [packageid]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const successFn = (response) => response;
    const errorFn = ({
      response: error,
    }: {
      response: AxiosResponse & {
        body: { message: string };
        message: string;
      };
    }) => {
      if (error.status === 400 || error.status === 405) {
        toggleLoader(false);
        return Promise.reject(error);
      }

      makeToast({ message: error?.body?.message || error?.message, variant: 'error' });
      toggleLoader(false);

      return Promise.reject(error);
    };

    const interceptorId = axios.interceptors.response.use(successFn, errorFn);
    return () => {
      axios.interceptors.response.eject(interceptorId);
    };
  }, [makeToast, toggleLoader]);

  useEffect(() => {
    sessionStorage.setItem('USER_DATA', JSON.stringify(user));

    if (user.step && user.packageId) {
      const redirect = user.step === 3 ? `${path}/confirmation` : `${path}/step-${user.step}/${user.packageId}`;

      if (!(history.location.pathname.indexOf(redirect) !== -1)) history.push(redirect);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const registerOneSubmit = async (values: UserProps) => {
    toggleLoader(true);

    const countryCode = getHostCountryPrefix().toUpperCase();

    const payload: BlubeemRequestsRegistrationRequest = {
      ...values.applicant,
      selectedPackage: packageid,
      acceptTerms: true,
      source: currentProduct,
      country: countryCode,
    };

    postRegister(payload, { responseType: 'text' })
      .then(({ data, status }) => {
        const token = data;

        toggleLoader(false);

        if (status !== 200) {
          makeToast({
            message: t('Errors.account_already_exists_error'),
            variant: 'error',
          });
          return;
        }

        sessionStorage.setItem('USER_TOKEN', token);
        setToken(token);
        Api.setToken(token);

        registerOneSubmitSuccess(values);
      })
      .catch((err) => {
        toggleLoader(false);

        if (err.data?.indexOf('SFEX ONBOARDING_STARTED') > -1) {
          return makeToast({
            message: t('Errors.email_already_used_error'),
            variant: 'error',
          });
        }

        makeToast({ message: err?.body?.message || err?.message, variant: 'error' });
      });
  };

  const registerOneSubmitSuccess = async (values: UserProps) => {
    const dataUser = {
      ...user,
      ...values,
      step: 2,
      packageId: user.packageId,
      productType: currentProduct,
    } as UserProps;
    setUser(dataUser);

    const pathName = `${path}/step-2/${user.packageId}`;
    history.push(pathName);
  };

  const registerTwoSubmit = async (values: UserProps) => {
    toggleLoader(true);
    setAxiosToken(token as string);

    const socialMedia = socialMediaString(values.socialMediaFields);
    const language = i18n.language;
    const payload: BlubeemRequestsConfirmRegistrationRequest = {
      company: values.company.name,
      firstname: values.applicant.firstname,
      middlename: values.applicant.middlename,
      lastname: values.applicant.lastname,
      phone: values.applicant.mobilePhone,
      mobilePhone: values.applicant.mobilePhone,
      gender: values.applicant.gender,
      vatNumber: values.company.vatNumber,
      registrationNumber: values.company.registrationNumber,
      locationNumber: values.company.locationNumber,
      confirmationUrl: `${window.location.origin}/checkout/step-1?token=${token}&packageId=${user.packageId}&language=${language}`,
      product: currentProduct,
      tradeName: values.company.tradeName,
      businessWebsite: values.company.website,
      industry: values.company.industry,
      descriptionOfBusinessActivities: values.company.descriptionOfBusinessActivities,
      socialMedia: socialMedia,
      city: values.company.address.city,
      houseNumber: values.company.address.houseNumber,
      country: values.company.address.country,
      street: values.company.address.street,
      postalCode: values.company.address.zipCode,
    };

    const dataUser = {
      ...user.applicant,
      ...values,
      step: 2,
      selectedPackage: user.packageId,
      productType: user.productType,
    } as UserProps;

    setUser(dataUser);
    await putRegister(payload);

    toggleLoader(false);
    history.push(`${path}/confirmation`);
    resetUser();
  };

  const resetUser = () => {
    const resetUser = { ...emptyUser };
    resetUser.applicant.email = user.applicant.email;
    resetUser.step = 3;
    resetUser.packageId = user.packageId;
    resetUser.productType = user.productType;
    resetUser.packageInformation.hasCards = user.packageInformation.hasCards;
    resetUser.packageInformation.hasClicks = user.packageInformation.hasClicks;
    resetUser.packageInformation.hasCash = user.packageInformation.hasCash;
    setUser(resetUser);
  };

  const clearUser = () => {
    sessionStorage.removeItem('USER_DATA');
    sessionStorage.removeItem('USER_TOKEN');

    setUser({
      ...emptyUser,
      productType: user.productType,
    });
    window.location.href = '/';
  };

  return (
    <ModalContextProvider>
      <Switch>
        <Route path={`${path}/step-1/:packageid`}>
          <RegisterOne initialValues={user} onSubmit={registerOneSubmit} callback={setPackageid} />
        </Route>
        <Route path={`${path}/step-2/:packageid`}>
          <Formik initialValues={user} onSubmit={registerTwoSubmit}>
            <RegisterTwo initialValues={user} onSubmit={registerTwoSubmit} callback={setPackageid} />
          </Formik>
        </Route>
        <Route path={`${path}/confirmation`}>
          <RegisterConfirmation buttonClick={clearUser} />
        </Route>
        <Route>
          <Redirect to={`${path}/step-1/KCAS`} />
        </Route>
      </Switch>
    </ModalContextProvider>
  );
};

const Register: React.FC = () => {
  return (
    <LoaderProvider>
      <ToastProvider>
        <Loader />
        <Toast />
        <Routing />
      </ToastProvider>
    </LoaderProvider>
  );
};

const ReactRegister = () => {
  return <Register />;
};

export default ReactRegister;
