/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
// @ts-nocheck

import React, { useRef, useState, useEffect } from 'react';
import classNames from 'classnames';
import { ErrorMessage } from 'formik';
import Tooltip from '@shared//Tooltip';
import { useTranslation } from 'react-i18next';

export interface NumberInputProps {
  classes?: string;
  disabled?: boolean;
  readonly?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  sronly?: boolean;
  label: string;
  title?: string;
  value?: string | number;
  name: string;
  placeholder?: string;
  formType?: string;
  hasError?: boolean;
  tooltip?: string;
  prefix?: string;
  subtitle?: string;
  suffix?: string;
}

const NumberInput: React.FC<NumberInputProps> = ({ formType = 'primary', ...props }) => {
  const [active, setActive] = useState(false);
  const { t } = useTranslation();

  const classes = classNames({
    'c-input form__item': true,
    [`${props.classes}`]: Boolean(props.classes),
    'form__item--active': active,
    'form__item--error': props.hasError,
    [`form__item--${formType}`]: formType,
  });

  const labelClasses = classNames({
    input__label: true,
    'u-sr-only': Boolean(props.sronly),
  });

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setActive(Boolean(props.value));
  }, [props.value]);

  useEffect(() => {
    if (!inputRef.current) return;
    if (inputRef.current.value) setActive(true);
  }, []);

  const onFocus = (e) => {
    if (props.onFocus) {
      props.onFocus(e);
    }
    if (!inputRef.current) return;
    if (active === false) setActive(true);
  };

  const onBlur = (e) => {
    if (props.onBlur) {
      props.onBlur(e);
    }
    if (!inputRef.current) return;
    const ACTION = inputRef.current.value ? true : false;
    setActive(ACTION);
  };

  const removeChars = (e: any) => {
    e.persist();
    if (!props.onChange) return;

    e.target.value = e.target.value.replace(/[^0-9.]/g, '');
    props.onChange(e);
  };

  return (
    <div className={classes}>
      <div className="select__label-wrapper">
        <label htmlFor={props.name} role="button">
          {props.title && <span dangerouslySetInnerHTML={{ __html: t(props.title) }} className="select__label-title" />}
          {props.subtitle && <span className="select__label-subtitle">{t(props.subtitle)}</span>}
        </label>
        {props.tooltip && <Tooltip text={props.tooltip} />}
      </div>
      <div className="input__wrapper">
        {props.prefix && <span className="input__prefix">{props.prefix}</span>}
        <label htmlFor={props.name} className={labelClasses}>
          {t(props.label)}
        </label>
        <input
          onChange={(e) => removeChars(e)}
          type="text"
          className="input__input"
          name={props.name}
          value={props.value || ''}
          onFocus={onFocus}
          onBlur={onBlur}
          id={props.name}
          ref={inputRef}
          disabled={props.disabled}
          readOnly={props.readonly}
          placeholder={props.placeholder}
        />
        {props.suffix && <span className="input__suffix">{props.suffix}</span>}
        {props.tooltip && <Tooltip text="Dummy tekst over tooltip" classes="input__tooltip" />}
      </div>
      {props.hasError && (
        <p className="input__error">
          <ErrorMessage name={props.name} />
        </p>
      )}
    </div>
  );
};

export default NumberInput;
