import {
  domainTooLongMessage,
  intraRegionalCountriesMessage,
  maxNumberMessage,
  minNumberMessage,
  minRequiredMessage,
  requiredMessage,
  validWebAddressMessage,
  valueStringTooBigMessage,
  valueTooBigMessage,
} from '@utilities/validations/messages';
import * as yup from 'yup';
import { ServiceType } from '../models/service-type.enum';
import { validateMoneyInput } from './validation';
import { urlRegExp } from '@utilities/validations/regex';

export const CLICKS_SCHEMA = (t) => {
  return {
    clicksLeadTimeInDays: yup
      .number()
      .when(['active', 'name'], {
        is: (active, name) => name === ServiceType.Clicks && active,
        then: yup
          .number()
          .required(t(requiredMessage))
          .min(1, t(minNumberMessage, { amount: 1 }))
          .max(365, t(maxNumberMessage, { amount: 365 }))
          .nullable(),
      })
      .nullable(),
    productTypesOnline: yup
      .string()
      .when(['active', 'name'], {
        is: (active, name) => name === ServiceType.Clicks && active,
        then: yup
          .string()
          .test('exists', t(minRequiredMessage), function (this: yup.TestContext) {
            if (!this.parent.productTypesOnline) return false;
            return this.parent.productTypesOnline.length > 1;
          })
          .max(255, t(valueStringTooBigMessage, { amount: 255 }))
          .nullable(),
        otherwise: undefined,
      })
      .nullable(),
    webshopUrl: yup
      .string()
      .when(['active', 'name'], {
        is: (active, name) => name === ServiceType.Clicks && active,
        then: yup
          .string()
          .matches(urlRegExp, t(validWebAddressMessage))
          .required(t(requiredMessage))
          .max(64, t(domainTooLongMessage)),
        otherwise: undefined,
      })
      .nullable(),
    averageOnlineRevenuePerMonth: validateMoneyInput(t, 'averageOnlineRevenuePerMonth', ServiceType.Clicks),
    selectedCountries: yup
      .array()
      .when(['active', 'name'], {
        is: (active, name) => name === ServiceType.Clicks && active,
        then: yup.array().min(1, t(requiredMessage)).required(t(requiredMessage)).nullable(),
        otherwise: undefined,
      })
      .nullable(),
    clicksDomesticTargetCountryName: yup
      .string()
      .when(['active', 'name'], {
        is: (active, name) => name === ServiceType.Clicks && active,
        then: yup.string().required(t(requiredMessage)).nullable(),
      })
      .nullable(),
    clicksIntraRegionalTargetMarketInEuCountries: yup
      .array()
      .when(['active', 'name'], {
        is: (active, name) => name === ServiceType.Clicks && active,
        then: yup.array().test('exists', t(intraRegionalCountriesMessage), function (this: any) {
          const countriesInEU = this.parent.clicksIntraRegionalTargetMarketInEuCountries;
          const countriesOutsideEU = this.parent.clicksIntraRegionalTargetMarketOutsideEuCountries;
          if (
            (countriesInEU === undefined || countriesInEU.length === 0) &&
            (countriesOutsideEU === undefined || countriesOutsideEU.length === 0)
          ) {
            return false;
          }
          return true;
        }),
      })
      .nullable(),
    clicksIntraRegionalTargetMarketOutsideEuCountries: yup
      .array()
      .when(['active', 'name'], {
        is: (active, name) => name === ServiceType.Clicks && active,
        then: yup.array().test('exists', t(intraRegionalCountriesMessage), function (this: any) {
          const countriesInEU = this.parent.clicksIntraRegionalTargetMarketInEuCountries;
          const countriesOutsideEU = this.parent.clicksIntraRegionalTargetMarketOutsideEuCountries;
          if (
            (countriesInEU === undefined || countriesInEU.length === 0) &&
            (countriesOutsideEU === undefined || countriesOutsideEU.length === 0)
          ) {
            return false;
          }
          return true;
        }),
      })
      .nullable(),
    clicksExpectedTotalVolumeAnnually: validateMoneyInput(t, 'clicksExpectedTotalVolumeAnnually', ServiceType.Clicks),
    clicksAverageTransactionValue: validateMoneyInput(t, 'clicksAverageTransactionValue', ServiceType.Clicks),
    clicksHighestTransactionValue: validateMoneyInput(t, 'clicksHighestTransactionValue', ServiceType.Clicks),

    // PSP information
    clicksNameFormerPsp: yup
      .string()
      .when(['active', 'name', 'madePaymentsWithAnotherPsp'], {
        is: (active, name, madePaymentsWithAnotherPsp) =>
          name === ServiceType.Clicks && active && madePaymentsWithAnotherPsp === 'true',
        then: yup.string().required(t(requiredMessage)).nullable(),
      })
      .nullable(),
    clicksReasonForSwitching: yup
      .string()
      .when(['active', 'name', 'madePaymentsWithAnotherPsp'], {
        is: (active, name, madePaymentsWithAnotherPsp) =>
          name === ServiceType.Clicks && active && madePaymentsWithAnotherPsp === 'true',
        then: yup.string().required(t(requiredMessage)).nullable(),
      })
      .nullable(),
    clicksProcessedVolumeLastThreeMonths: yup
      .number()
      .when(['active', 'name', 'madePaymentsWithAnotherPsp'], {
        is: (active, name, madePaymentsWithAnotherPsp) =>
          name === ServiceType.Clicks && active && madePaymentsWithAnotherPsp === 'true',
        then: yup
          .number()
          .required(t(requiredMessage))
          .min(1, t(minNumberMessage, { amount: 1 }))
          .max(999999999999999, t(valueTooBigMessage, { amount: 15 }))
          .nullable(),
      })
      .nullable(),
  };
};
