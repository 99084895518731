// @ts-nocheck
import classNames from 'classnames';
import { ErrorMessage } from 'formik';
import React, { useRef } from 'react';
import { CheckboxProps } from './Checkbox';
import { useTranslation } from 'react-i18next';

export interface ToggleProps extends CheckboxProps {
  checkedLabel?: string;
  uncheckedLabel?: string;
  hideLabel?: boolean;
}

const Toggle: React.FC<ToggleProps> = ({ formType = 'primary', ...props }) => {
  const checkboxRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const classes = classNames({
    'c-toggle form__item': true,
    [`${props.classes}`]: Boolean(props.classes),
    'form__item--error': props.hasError,
    [`form__item--${formType}`]: formType,
  });

  const inputClasses = classNames({
    toggle__input: true,
    'u-sr-only': Boolean(props.sronly),
  });

  return (
    <div className={classes} ref={checkboxRef}>
      <div className="toggle__wrapper">
        <input
          className={inputClasses}
          type="checkbox"
          name={props.name}
          id={props.name}
          checked={Boolean(props.value)}
          onChange={props.onChange}
          disabled={props.disabled}
          readOnly={props.readonly}
        />
        <label title={props.hideLabel ? props.label : undefined} htmlFor={props.name} className="toggle__label">
          {!props.hideLabel && <span className="toggle__label-title">{t(props.label)}</span>}

          {props.uncheckedLabel && !props.value && (
            <span className="toggle__unchecked-title" aria-hidden="true">
              {t(props.uncheckedLabel)}
            </span>
          )}
          {props.checkedLabel && props.value && (
            <span className="toggle__checked-title" aria-hidden="true">
              {t(props.checkedLabel)}
            </span>
          )}
        </label>
      </div>
      {props.hasError && (
        <p className="toggle__error">
          <ErrorMessage name={props.name} />
        </p>
      )}
    </div>
  );
};

export default Toggle;
