import StyledButton from '@components/Shared/StyledButton';
import { FormikCheckbox } from '@shared//form-elements/FormikCheckbox';
import { FormikInput } from '@shared//form-elements/FormikInput';
import FormHeader from '@shared//FormHeader';
import FormLayout from '@shared//FormLayout';
import FormLayoutColOne from '@shared//FormLayoutColOne';
import { UserProps } from '@utilities/interfaces/user';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { REGISTRATION_ONE_SCHEMA } from './validation/register-one-schema';
import FormLayoutColTwo from '@components/Shared/FormLayoutColTwo';
import SelectedPackage from '../SelectedPackage';

interface RegisterOneProps {
  callback?: (id: string) => void;
  initialValues: UserProps;
  onSubmit: (values: UserProps) => void;
}

const RegisterOne: React.FC<RegisterOneProps> = ({ onSubmit, initialValues, callback }) => {
  const { packageid } = useParams<{ packageid: string }>();
  const { t, i18n } = useTranslation();
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    if (callback && packageid) {
      setShow(true);
      callback(packageid);
    }
  }, [packageid, callback]);

  return (
    initialValues &&
    show && (
      <FormLayout mobileReverse>
        <FormLayoutColOne>
          <FormHeader title="RegisterOne.fill_in_data" indicator={t('step') + ' 1/2'} />
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={REGISTRATION_ONE_SCHEMA(t)}
            onSubmit={onSubmit}
          >
            {({ setErrors }) => {
              i18n.on('languageChanged', () => {
                setErrors({});
              });
              return (
                <Form>
                  <FormikInput
                    label="ContactInformation.email"
                    name="applicant.email"
                    type="email"
                    formType="secondary"
                  />
                  <FormikInput
                    label="RegisterOne.mobile"
                    name="applicant.mobilePhone"
                    type="tel"
                    formType="secondary"
                  />
                  <FormikCheckbox label="RegisterOne.privacy_and_terms" name="applicant.acceptTerms" />
                  <StyledButton tag="button" text="Buttons.next" type="submit" />
                </Form>
              );
            }}
          </Formik>
        </FormLayoutColOne>
        <FormLayoutColTwo selectedPackage>
          {initialValues?.productType && <SelectedPackage productid={initialValues.productType} />}
        </FormLayoutColTwo>
      </FormLayout>
    )
  );
};

export default RegisterOne;
