import StyledButton from '@components/Shared/StyledButton';
import { postMandate } from '@generated/mandate';
import { postOpportunityComplete, putOpportunityStep } from '@generated/opportunity';
import { useUser } from '@state/user';
import { useLoader } from '@utilities/context/LoaderContext';
import { MandateProcessStatus } from '@utilities/interfaces/user';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import FormHeader from '../../../Shared/FormHeader';
import FormLayout from '../../../Shared/FormLayout';
import FormLayoutColOne from '../../../Shared/FormLayoutColOne';
import FormLayoutColTwo from '../../../Shared/FormLayoutColTwo';
import InfoBox from '../../../Shared/InfoBox';
import { Cross } from '../../../Shared/svg/icons';
import { useTranslation } from 'react-i18next';

const CheckoutSix: React.FC = () => {
  const { user, setUser } = useUser();
  const { toggleLoader } = useLoader();
  const { t } = useTranslation();
  const history = useHistory();

  const createMandate = async () => {
    toggleLoader(true);
    const { data } = await postMandate();
    setUser({ mandateProcess: { mandateId: data.mandateId } });

    const twikeyUrl = data.protectedUrl;
    window.location.href = twikeyUrl;
  };

  const mandateProcessStatus = useMemo(() => user.mandateProcess && user.mandateProcess.status, [user.mandateProcess]);

  const onSubmit = useCallback(() => {
    postOpportunityComplete();
    history.push('confirmation');
  }, [history]);

  useEffect(() => {
    putOpportunityStep({ step: '6' });
  }, []);

  return (
    <FormLayout>
      <FormLayoutColOne>
        <FormHeader title="CheckoutSix.authorization" indicator={t('step') + ' 6/6'} hasPrev prevUrl="step-5" />
        <InfoBox>
          <p style={{ hyphens: 'none' }}>{t('CheckoutSix.authorization_info')}</p>
        </InfoBox>
      </FormLayoutColOne>
      <FormLayoutColTwo>
        {!mandateProcessStatus && (
          <button type="button" className="c-verify" onClick={() => createMandate()}>
            <div className="verify__icon-wrapper">
              <Cross classes="verify__icon" />
            </div>
            <p className="verify__content">{t('CheckoutSix.provide_authorization')}</p>
          </button>
        )}
        {!!mandateProcessStatus && mandateProcessStatus !== MandateProcessStatus.SIGNED && (
          <button type="button" className="c-verify error" onClick={() => createMandate()}>
            <div className="verify__icon-wrapper">
              <Cross classes="verify__icon" />
            </div>
            <p className="verify__content">{t('CheckoutSix.reverify_authorization')}</p>
          </button>
        )}
        {mandateProcessStatus === MandateProcessStatus.PENDING && (
          <div className="pending-info">{t('CheckoutSix.validations_pending')}</div>
        )}

        {mandateProcessStatus === MandateProcessStatus.SIGNED && (
          <div className="success-info">{t('CheckoutSix.finished_authorization')}</div>
        )}
        <StyledButton
          tag="button"
          text="CheckoutSix.complete"
          formType="tertiary"
          disabled={!mandateProcessStatus}
          onClick={onSubmit}
        />
      </FormLayoutColTwo>
    </FormLayout>
  );
};

export default CheckoutSix;
