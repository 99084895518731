export const reasonForSwitching = [
  {
    value: 'Pricing too expensive',
    displayValue: 'ReasonForSwitchingDropdown.too_expensive',
  },
  {
    value: 'Quality of the service not as expected',
    displayValue: 'ReasonForSwitchingDropdown.quality_not_as_expected',
  },
  {
    value: 'Poor customer services',
    displayValue: 'ReasonForSwitchingDropdown.poor_customer_services',
  },
  {
    value: 'Could not offer online payments',
    displayValue: 'ReasonForSwitchingDropdown.could_not_offer_online_payments',
  },
  {
    value: 'Could not offer in-store payments',
    displayValue: 'ReasonForSwitchingDropdown.could_not_offer_in_store_payments',
  },
  {
    value: 'Could not offer cash',
    displayValue: 'ReasonForSwitchingDropdown.could_not_offer_cash',
  },
  {
    value: 'Other',
    displayValue: 'ReasonForSwitchingDropdown.other',
  },
];
