import { invalidPhone, maxCharacters, requiredMessage } from '@utilities/validations/messages';
import { phoneRegExp } from '@utilities/validations/regex';
import * as yup from 'yup';

export const CHECKOUT_ONE_SCHEMA = (t) =>
  yup.object().shape({
    account: yup.object().shape({
      name: yup
        .string()
        .max(80, t(maxCharacters, { amount: 80 }))
        .required(t(requiredMessage)),
      phone: yup.string().matches(phoneRegExp, t(invalidPhone)).required(t(requiredMessage)),
    }),
  });
