export const countries = [
  {
    value: 'AF',
    displayValue: 'CountriesDropdown.afghanistan',
  },
  {
    value: 'AX',
    displayValue: 'CountriesDropdown.aland',
  },
  {
    value: 'AL',
    displayValue: 'CountriesDropdown.albania',
  },
  {
    value: 'DZ',
    displayValue: 'CountriesDropdown.algeria',
  },
  {
    value: 'VI',
    displayValue: 'CountriesDropdown.virgin_islands_us',
  },
  {
    value: 'AS',
    displayValue: 'CountriesDropdown.american_samoa',
  },
  {
    value: 'AD',
    displayValue: 'CountriesDropdown.andorra',
  },
  {
    value: 'AO',
    displayValue: 'CountriesDropdown.angola',
  },
  {
    value: 'AI',
    displayValue: 'CountriesDropdown.anguilla',
  },
  {
    value: 'AQ',
    displayValue: 'CountriesDropdown.antarctica',
  },
  {
    value: 'AG',
    displayValue: 'CountriesDropdown.antigua_and_barbuda',
  },
  {
    value: 'AR',
    displayValue: 'CountriesDropdown.argentina',
  },
  {
    value: 'AM',
    displayValue: 'CountriesDropdown.armenia',
  },
  {
    value: 'AW',
    displayValue: 'CountriesDropdown.aruba',
  },
  {
    value: 'AU',
    displayValue: 'CountriesDropdown.australia',
  },
  {
    value: 'AZ',
    displayValue: 'CountriesDropdown.azerbaijan',
  },
  {
    value: 'BS',
    displayValue: 'CountriesDropdown.bahamas',
  },
  {
    value: 'BH',
    displayValue: 'CountriesDropdown.bahrain',
  },
  {
    value: 'BD',
    displayValue: 'CountriesDropdown.bangladesh',
  },
  {
    value: 'BB',
    displayValue: 'CountriesDropdown.barbados',
  },
  {
    value: 'BE',
    displayValue: 'CountriesDropdown.belgium',
  },
  {
    value: 'BZ',
    displayValue: 'CountriesDropdown.belize',
  },
  {
    value: 'BJ',
    displayValue: 'CountriesDropdown.benin',
  },
  {
    value: 'BM',
    displayValue: 'CountriesDropdown.bermuda',
  },
  {
    value: 'BT',
    displayValue: 'CountriesDropdown.bhutan',
  },
  {
    value: 'BO',
    displayValue: 'CountriesDropdown.bolivia',
  },
  {
    value: 'BA',
    displayValue: 'CountriesDropdown.bosnia_and_herzegovina',
  },
  {
    value: 'BW',
    displayValue: 'CountriesDropdown.botswana',
  },
  {
    value: 'BV',
    displayValue: 'CountriesDropdown.bouvet_island',
  },
  {
    value: 'BR',
    displayValue: 'CountriesDropdown.brazil',
  },
  {
    value: 'IO',
    displayValue: 'CountriesDropdown.british_indian_ocean_territory',
  },
  {
    value: 'VG',
    displayValue: 'CountriesDropdown.british_virgin_islands',
  },
  {
    value: 'BN',
    displayValue: 'CountriesDropdown.brunei',
  },
  {
    value: 'BG',
    displayValue: 'CountriesDropdown.bulgaria',
  },
  {
    value: 'BF',
    displayValue: 'CountriesDropdown.burkina_faso',
  },
  {
    value: 'BI',
    displayValue: 'CountriesDropdown.burundi',
  },
  {
    value: 'KH',
    displayValue: 'CountriesDropdown.cambodia',
  },
  {
    value: 'CA',
    displayValue: 'CountriesDropdown.canada',
  },
  {
    value: 'BQ',
    displayValue: 'CountriesDropdown.caribbean_netherlands',
  },
  {
    value: 'CF',
    displayValue: 'CountriesDropdown.central_african_republic',
  },
  {
    value: 'CL',
    displayValue: 'CountriesDropdown.chile',
  },
  {
    value: 'CN',
    displayValue: 'CountriesDropdown.china',
  },
  {
    value: 'CM',
    displayValue: 'CountriesDropdown.cameroon',
  },
  {
    value: 'CX',
    displayValue: 'CountriesDropdown.christmas_island',
  },
  {
    value: 'CC',
    displayValue: 'CountriesDropdown.cocos_islands',
  },
  {
    value: 'CO',
    displayValue: 'CountriesDropdown.colombia',
  },
  {
    value: 'KM',
    displayValue: 'CountriesDropdown.comores',
  },
  {
    value: 'CG',
    displayValue: 'CountriesDropdown.democratic_republic_of_congo',
  },
  {
    value: 'CD',
    displayValue: 'CountriesDropdown.republic_of_congo',
  },
  {
    value: 'CK',
    displayValue: 'CountriesDropdown.cook_islands',
  },
  {
    value: 'CR',
    displayValue: 'CountriesDropdown.costa_rica',
  },
  {
    value: 'CU',
    displayValue: 'CountriesDropdown.cuba',
  },
  {
    value: 'CW',
    displayValue: 'CountriesDropdown.curacao',
  },
  {
    value: 'CY',
    displayValue: 'CountriesDropdown.cyprus',
  },
  {
    value: 'PH',
    displayValue: 'CountriesDropdown.philippines',
  },
  {
    value: 'DK',
    displayValue: 'CountriesDropdown.denmark',
  },
  {
    value: 'DJ',
    displayValue: 'CountriesDropdown.djibouti',
  },
  {
    value: 'DM',
    displayValue: 'CountriesDropdown.dominica',
  },
  {
    value: 'DO',
    displayValue: 'CountriesDropdown.dominican_republic',
  },
  {
    value: 'DE',
    displayValue: 'CountriesDropdown.germany',
  },
  {
    value: 'EC',
    displayValue: 'CountriesDropdown.ecuador',
  },
  {
    value: 'EG',
    displayValue: 'CountriesDropdown.egypt',
  },
  {
    value: 'SV',
    displayValue: 'CountriesDropdown.el_salvador',
  },
  {
    value: 'GQ',
    displayValue: 'CountriesDropdown.equatorial_guinea',
  },
  {
    value: 'ER',
    displayValue: 'CountriesDropdown.eritrea',
  },
  {
    value: 'EE',
    displayValue: 'CountriesDropdown.estonia',
  },
  {
    value: 'ET',
    displayValue: 'CountriesDropdown.ethiopia',
  },
  {
    value: 'FO',
    displayValue: 'CountriesDropdown.faroe_islands',
  },
  {
    value: 'FK',
    displayValue: 'CountriesDropdown.falkland_islands',
  },
  {
    value: 'FJ',
    displayValue: 'CountriesDropdown.fiji',
  },
  {
    value: 'FI',
    displayValue: 'CountriesDropdown.finland',
  },
  {
    value: 'FR',
    displayValue: 'CountriesDropdown.france',
  },
  {
    value: 'TF',
    displayValue: 'CountriesDropdown.french_southern_territories',
  },
  {
    value: 'GF',
    displayValue: 'CountriesDropdown.french_guiana',
  },
  {
    value: 'PF',
    displayValue: 'CountriesDropdown.french_polynesia',
  },
  {
    value: 'GA',
    displayValue: 'CountriesDropdown.gabon',
  },
  {
    value: 'GM',
    displayValue: 'CountriesDropdown.gambia',
  },
  {
    value: 'GE',
    displayValue: 'CountriesDropdown.georgia',
  },
  {
    value: 'GH',
    displayValue: 'CountriesDropdown.ghana',
  },
  {
    value: 'GI',
    displayValue: 'CountriesDropdown.gibraltar',
  },
  {
    value: 'GD',
    displayValue: 'CountriesDropdown.grenada',
  },
  {
    value: 'GR',
    displayValue: 'CountriesDropdown.greece',
  },
  {
    value: 'GL',
    displayValue: 'CountriesDropdown.greenland',
  },
  {
    value: 'GP',
    displayValue: 'CountriesDropdown.guadeloupe',
  },
  {
    value: 'GU',
    displayValue: 'CountriesDropdown.guam',
  },
  {
    value: 'GT',
    displayValue: 'CountriesDropdown.guatemala',
  },
  {
    value: 'GG',
    displayValue: 'CountriesDropdown.guernsey',
  },
  {
    value: 'GN',
    displayValue: 'CountriesDropdown.guinea',
  },
  {
    value: 'GW',
    displayValue: 'CountriesDropdown.guinea-bissau',
  },
  {
    value: 'GY',
    displayValue: 'CountriesDropdown.guyana',
  },
  {
    value: 'HT',
    displayValue: 'CountriesDropdown.haiti',
  },
  {
    value: 'HM',
    displayValue: 'CountriesDropdown.heard_island_and_mcdonald_islands',
  },
  {
    value: 'HN',
    displayValue: 'CountriesDropdown.honduras',
  },
  {
    value: 'HU',
    displayValue: 'CountriesDropdown.hungary',
  },
  {
    value: 'HK',
    displayValue: 'CountriesDropdown.hong_kong',
  },
  {
    value: 'IE',
    displayValue: 'CountriesDropdown.ireland',
  },
  {
    value: 'IS',
    displayValue: 'CountriesDropdown.iceland',
  },
  {
    value: 'IN',
    displayValue: 'CountriesDropdown.inda',
  },
  {
    value: 'ID',
    displayValue: 'CountriesDropdown.indonesia',
  },
  {
    value: 'IQ',
    displayValue: 'CountriesDropdown.iraq',
  },
  {
    value: 'IR',
    displayValue: 'CountriesDropdown.iran',
  },
  {
    value: 'IM',
    displayValue: 'CountriesDropdown.isle_of_man',
  },
  {
    value: 'IL',
    displayValue: 'CountriesDropdown.israel',
  },
  {
    value: 'IT',
    displayValue: 'CountriesDropdown.italy',
  },
  {
    value: 'CI',
    displayValue: 'CountriesDropdown.ivory_coast',
  },
  {
    value: 'JM',
    displayValue: 'CountriesDropdown.jamaica',
  },
  {
    value: 'JP',
    displayValue: 'CountriesDropdown.japan',
  },
  {
    value: 'YE',
    displayValue: 'CountriesDropdown.yemen',
  },
  {
    value: 'JE',
    displayValue: 'CountriesDropdown.jersey',
  },
  {
    value: 'JO',
    displayValue: 'CountriesDropdown.jordan',
  },
  {
    value: 'KY',
    displayValue: 'CountriesDropdown.cayman_islands',
  },
  {
    value: 'CV',
    displayValue: 'CountriesDropdown.cape_verde',
  },
  {
    value: 'KZ',
    displayValue: 'CountriesDropdown.kazakhstan',
  },
  {
    value: 'KE',
    displayValue: 'CountriesDropdown.kenya',
  },
  {
    value: 'KG',
    displayValue: 'CountriesDropdown.kyrgyzstan',
  },
  {
    value: 'KI',
    displayValue: 'CountriesDropdown.kiribati',
  },
  {
    value: 'UM',
    displayValue: 'CountriesDropdown.united_states_minor_outlying_islands',
  },
  {
    value: 'KW',
    displayValue: 'CountriesDropdown.kuwait',
  },
  {
    value: 'HR',
    displayValue: 'CountriesDropdown.croatia',
  },
  {
    value: 'LA',
    displayValue: 'CountriesDropdown.laos',
  },
  {
    value: 'LS',
    displayValue: 'CountriesDropdown.lesotho',
  },
  {
    value: 'LV',
    displayValue: 'CountriesDropdown.latvia',
  },
  {
    value: 'LB',
    displayValue: 'CountriesDropdown.lebanon',
  },
  {
    value: 'LR',
    displayValue: 'CountriesDropdown.liberia',
  },
  {
    value: 'LY',
    displayValue: 'CountriesDropdown.libya',
  },
  {
    value: 'LI',
    displayValue: 'CountriesDropdown.liechtenstein',
  },
  {
    value: 'LT',
    displayValue: 'CountriesDropdown.lithuania',
  },
  {
    value: 'LU',
    displayValue: 'CountriesDropdown.luxembourg',
  },
  {
    value: 'MO',
    displayValue: 'CountriesDropdown.macao',
  },
  {
    value: 'MG',
    displayValue: 'CountriesDropdown.madagascar',
  },
  {
    value: 'MW',
    displayValue: 'CountriesDropdown.malawi',
  },
  {
    value: 'MV',
    displayValue: 'CountriesDropdown.maldives',
  },
  {
    value: 'MY',
    displayValue: 'CountriesDropdown.malaysia',
  },
  {
    value: 'ML',
    displayValue: 'CountriesDropdown.mali',
  },
  {
    value: 'MT',
    displayValue: 'CountriesDropdown.malta',
  },
  {
    value: 'MA',
    displayValue: 'CountriesDropdown.morocco',
  },
  {
    value: 'MH',
    displayValue: 'CountriesDropdown.marshall_islands',
  },
  {
    value: 'MQ',
    displayValue: 'CountriesDropdown.martinique',
  },
  {
    value: 'MR',
    displayValue: 'CountriesDropdown.maritania',
  },
  {
    value: 'MU',
    displayValue: 'CountriesDropdown.mauritius',
  },
  {
    value: 'YT',
    displayValue: 'CountriesDropdown.mayotte',
  },
  {
    value: 'MX',
    displayValue: 'CountriesDropdown.mexico',
  },
  {
    value: 'FM',
    displayValue: 'CountriesDropdown.micronesia',
  },
  {
    value: 'MD',
    displayValue: 'CountriesDropdown.moldova',
  },
  {
    value: 'MC',
    displayValue: 'CountriesDropdown.monaco',
  },
  {
    value: 'MN',
    displayValue: 'CountriesDropdown.mongolia',
  },
  {
    value: 'ME',
    displayValue: 'CountriesDropdown.montenegro',
  },
  {
    value: 'MS',
    displayValue: 'CountriesDropdown.montserrat',
  },
  {
    value: 'MZ',
    displayValue: 'CountriesDropdown.mozambique',
  },
  {
    value: 'MM',
    displayValue: 'CountriesDropdown.myanmar',
  },
  {
    value: 'NA',
    displayValue: 'CountriesDropdown.namibia',
  },
  {
    value: 'NR',
    displayValue: 'CountriesDropdown.naura',
  },
  {
    value: 'NL',
    displayValue: 'CountriesDropdown.netherlands',
  },
  {
    value: 'NP',
    displayValue: 'CountriesDropdown.nepal',
  },
  {
    value: 'NI',
    displayValue: 'CountriesDropdown.nicaragua',
  },
  {
    value: 'NC',
    displayValue: 'CountriesDropdown.new_caledonia',
  },
  {
    value: 'NZ',
    displayValue: 'CountriesDropdown.new_zealand',
  },
  {
    value: 'NE',
    displayValue: 'CountriesDropdown.niger',
  },
  {
    value: 'NG',
    displayValue: 'CountriesDropdown.nigeria',
  },
  {
    value: 'NU',
    displayValue: 'CountriesDropdown.niue',
  },
  {
    value: 'MP',
    displayValue: 'CountriesDropdown.northern_mariana_islands',
  },
  {
    value: 'KP',
    displayValue: 'CountriesDropdown.north_korea',
  },
  {
    value: 'MK',
    displayValue: 'CountriesDropdown.north_macedonia',
  },
  {
    value: 'NO',
    displayValue: 'CountriesDropdown.norway',
  },
  {
    value: 'NF',
    displayValue: 'CountriesDropdown.norfolk_island',
  },
  {
    value: 'UG',
    displayValue: 'CountriesDropdown.uganda',
  },
  {
    value: 'UA',
    displayValue: 'CountriesDropdown.ukraine',
  },
  {
    value: 'UZ',
    displayValue: 'CountriesDropdown.uzbekistan',
  },
  {
    value: 'OM',
    displayValue: 'CountriesDropdown.oman',
  },
  {
    value: 'AT',
    displayValue: 'CountriesDropdown.austria',
  },
  {
    value: 'TL',
    displayValue: 'CountriesDropdown.timor-leste',
  },
  {
    value: 'PK',
    displayValue: 'CountriesDropdown.pakistan',
  },
  {
    value: 'PW',
    displayValue: 'CountriesDropdown.palau',
  },
  {
    value: 'PS',
    displayValue: 'CountriesDropdown.state_of_palestine',
  },
  {
    value: 'PA',
    displayValue: 'CountriesDropdown.panama',
  },
  {
    value: 'PG',
    displayValue: 'CountriesDropdown.papua_new_guinea',
  },
  {
    value: 'PY',
    displayValue: 'CountriesDropdown.paraguay',
  },
  {
    value: 'PE',
    displayValue: 'CountriesDropdown.peru',
  },
  {
    value: 'PN',
    displayValue: 'CountriesDropdown.pitcairn',
  },
  {
    value: 'PL',
    displayValue: 'CountriesDropdown.poland',
  },
  {
    value: 'PT',
    displayValue: 'CountriesDropdown.portugal',
  },
  {
    value: 'PR',
    displayValue: 'CountriesDropdown.puerto_rico',
  },
  {
    value: 'QA',
    displayValue: 'CountriesDropdown.qatar',
  },
  {
    value: 'RE',
    displayValue: 'CountriesDropdown.reunion',
  },
  {
    value: 'RO',
    displayValue: 'CountriesDropdown.romania',
  },
  {
    value: 'RU',
    displayValue: 'CountriesDropdown.russia',
  },
  {
    value: 'RW',
    displayValue: 'CountriesDropdown.rwanda',
  },
  {
    value: 'KN',
    displayValue: 'CountriesDropdown.saint_kitts_and_nevis',
  },
  {
    value: 'LC',
    displayValue: 'CountriesDropdown.saint_lucia',
  },
  {
    value: 'VC',
    displayValue: 'CountriesDropdown.saint_vincent_and_the_grenadines',
  },
  {
    value: 'BL',
    displayValue: 'CountriesDropdown.saint_barthelemy',
  },
  {
    value: 'PM',
    displayValue: 'CountriesDropdown.saint_pierre_and_miquelon',
  },
  {
    value: 'SB',
    displayValue: 'CountriesDropdown.solomon_islands',
  },
  {
    value: 'WS',
    displayValue: 'CountriesDropdown.samao',
  },
  {
    value: 'SM',
    displayValue: 'CountriesDropdown.san_marino',
  },
  {
    value: 'ST',
    displayValue: 'CountriesDropdown.sao_tome_and_principe',
  },
  {
    value: 'SA',
    displayValue: 'CountriesDropdown.saudi_arabia',
  },
  {
    value: 'SN',
    displayValue: 'CountriesDropdown.senegal',
  },
  {
    value: 'RS',
    displayValue: 'CountriesDropdown.serbia',
  },
  {
    value: 'SC',
    displayValue: 'CountriesDropdown.seychelles',
  },
  {
    value: 'SL',
    displayValue: 'CountriesDropdown.sierra_leone',
  },
  {
    value: 'SG',
    displayValue: 'CountriesDropdown.singapore',
  },
  {
    value: 'SX',
    displayValue: 'CountriesDropdown.sint_maarten_dutch_part',
  },
  {
    value: 'SH',
    displayValue: 'CountriesDropdown.saint_helena_ascension_tristan_da_cunha',
  },
  {
    value: 'MF',
    displayValue: 'CountriesDropdown.saint_martin_french_part',
  },
  {
    value: 'SI',
    displayValue: 'CountriesDropdown.slovenia',
  },
  {
    value: 'SK',
    displayValue: 'CountriesDropdown.slovakia',
  },
  {
    value: 'SD',
    displayValue: 'CountriesDropdown.sudan',
  },
  {
    value: 'SO',
    displayValue: 'CountriesDropdown.somalia',
  },
  {
    value: 'ES',
    displayValue: 'CountriesDropdown.spain',
  },
  {
    value: 'SJ',
    displayValue: 'CountriesDropdown.svalbard_and_jan_mayen',
  },
  {
    value: 'LK',
    displayValue: 'CountriesDropdown.sri_lanka',
  },
  {
    value: 'SR',
    displayValue: 'CountriesDropdown.suriname',
  },
  {
    value: 'SZ',
    displayValue: 'CountriesDropdown.eswatini',
  },
  {
    value: 'SY',
    displayValue: 'CountriesDropdown.syria',
  },
  {
    value: 'TJ',
    displayValue: 'CountriesDropdown.tajikistan',
  },
  {
    value: 'TW',
    displayValue: 'CountriesDropdown.taiwan',
  },
  {
    value: 'TZ',
    displayValue: 'CountriesDropdown.tanzania',
  },
  {
    value: 'TH',
    displayValue: 'CountriesDropdown.thailand',
  },
  {
    value: 'TG',
    displayValue: 'CountriesDropdown.togo',
  },
  {
    value: 'TK',
    displayValue: 'CountriesDropdown.tokelau',
  },
  {
    value: 'TO',
    displayValue: 'CountriesDropdown.tonga',
  },
  {
    value: 'TT',
    displayValue: 'CountriesDropdown.trinidad_and_tobago',
  },
  {
    value: 'TD',
    displayValue: 'CountriesDropdown.chad',
  },
  {
    value: 'CZ',
    displayValue: 'CountriesDropdown.czechia',
  },
  {
    value: 'TN',
    displayValue: 'CountriesDropdown.tunisia',
  },
  {
    value: 'TR',
    displayValue: 'CountriesDropdown.turkiye',
  },
  {
    value: 'TM',
    displayValue: 'CountriesDropdown.turkmenistan',
  },
  {
    value: 'TC',
    displayValue: 'CountriesDropdown.turks_and_caicos_islands',
  },
  {
    value: 'TV',
    displayValue: 'CountriesDropdown.tuvalu',
  },
  {
    value: 'UY',
    displayValue: 'CountriesDropdown.uruguay',
  },
  {
    value: 'VU',
    displayValue: 'CountriesDropdown.vanuata',
  },
  {
    value: 'VA',
    displayValue: 'CountriesDropdown.vatican_city',
  },
  {
    value: 'VE',
    displayValue: 'CountriesDropdown.venezuela',
  },
  {
    value: 'GB',
    displayValue: 'CountriesDropdown.united_kingdom',
  },
  {
    value: 'AE',
    displayValue: 'CountriesDropdown.united_arab_emirates',
  },
  {
    value: 'US',
    displayValue: 'CountriesDropdown.united_states_of_america',
  },
  {
    value: 'VN',
    displayValue: 'CountriesDropdown.viet_nam',
  },
  {
    value: 'WF',
    displayValue: 'CountriesDropdown.wallis_and_futuna',
  },
  {
    value: 'EH',
    displayValue: 'CountriesDropdown.western_sahara',
  },
  {
    value: 'BY',
    displayValue: 'CountriesDropdown.belarus',
  },
  {
    value: 'ZM',
    displayValue: 'CountriesDropdown.zambia',
  },
  {
    value: 'ZW',
    displayValue: 'CountriesDropdown.zimbabwe',
  },
  {
    value: 'ZA',
    displayValue: 'CountriesDropdown.south_africa',
  },
  {
    value: 'GS',
    displayValue: 'CountriesDropdown.south_georgia_and_the_south_sandwich_islands',
  },
  {
    value: 'KR',
    displayValue: 'CountriesDropdown.south_korea',
  },
  {
    value: 'SS',
    displayValue: 'CountriesDropdown.south_sudan',
  },
  {
    value: 'SE',
    displayValue: 'CountriesDropdown.sweden',
  },
  {
    value: 'CH',
    displayValue: 'CountriesDropdown.switzerland',
  },
];
