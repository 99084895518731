import { BlubeemModelsApiLocation, BlubeemModelsApiLocationDay } from '@generated/brinks.schemas';
import { ProductType } from '@utilities/interfaces/user';

declare global {
  interface Window {
    EnvironmentSettings: any;
  }
}

export const EMPTY_CASH_DAY_INFORMATION: BlubeemModelsApiLocationDay[] = [
  {
    dayOfWeek: 0,
    open: false,
    openingHours: [
      {    openingTime: '9:00', closingTime: '17:00'}
    ],
    turnoverSpeed: 0,
  },
  {
    dayOfWeek: 1,
    open: false,
    openingHours: [
      {    openingTime: '9:00', closingTime: '17:00'}
    ],
    turnoverSpeed: 0,
  },
  {
    dayOfWeek: 2,
    open: false,
    openingHours: [
      {    openingTime: '9:00', closingTime: '17:00'}
    ],
    turnoverSpeed: 0,
  },
  {
    dayOfWeek: 3,
    open: false,
    openingHours: [
      {    openingTime: '9:00', closingTime: '17:00'}
    ],
    turnoverSpeed: 0,
  },
  {
    dayOfWeek: 4,
    open: false,
    openingHours: [
      {    openingTime: '9:00', closingTime: '17:00'}
    ],
    turnoverSpeed: 0,
  },
  {
    dayOfWeek: 5,
    open: false,
    openingHours: [
      {    openingTime: '9:00', closingTime: '17:00'}
    ],
    turnoverSpeed: 0,
  },
  {
    dayOfWeek: 6,
    open: false,
    openingHours: [
      {    openingTime: '9:00', closingTime: '17:00'}
    ],
    turnoverSpeed: 0,
  },
];

export const EMPTY_LOCATION: BlubeemModelsApiLocation = {
  id: '',
  name: '',
  street: '',
  houseNumber: '',
  zipCode: '',
  city: '',
  country: '',
  contactId: '',
  cash: {
    alarm: true,
    cashEnabled: false,
    dayInformation: EMPTY_CASH_DAY_INFORMATION,
  },
  cards: {
    cardsEnabled: false,
    goodsDeliveredAfterTransactionMoment: false,
    possibilityToReturnPins: true,
    tipTransactions: true,
    averageRevenue: 0,
    deliveryAfterwards: '50',
    directDelivery: '50',
    b2B: 0,
    b2C: 0,
    domesticTargetMarket: 0,
    domesticTargetCountryName: '',
    intraRegionalTargetMarketInsideEU: 0,
    intraRegionalTargetMarketInsideEUCountries: '',
    intraRegionalTargetMarketOutsideEU: 0,
    intraRegionalTargetMarketOutsideEUCountries: '',
    expectedTotalVolumeAnnually: 0,
    averageTransactionValue: 0,
    highestTransactionValue: 0,
    expectedSeasonalPattern: 0,
    averageRevenuePerMonthCredit: 0,
    averageRevenuePerMonthDebit: 0,
    nameFormerPsp: '',
    reasonForSwitching: '',
    fraudVolumePercentage: 0,
    numberOfChargebacksFromTotalSales: 0,
    numberOfRefundsFromTotalSales: 0,
    processedVolumeLastThreeMonths: 0,
  },
  clicks: {
    clicksEnabled: false,
    recurringCreditCardPayments: true,
    averageOnlineRevenuePerMonth: 0,
    countryFocus: '',
    descriptionOnBankStatementClient: '',
    productTypesOnline: '',
    webshopUrl: '',
    b2B: 0,
    b2C: 0,
    domesticTargetMarket: 0,
    domesticTargetCountryName: '',
    intraRegionalTargetMarketInsideEU: 0,
    intraRegionalTargetMarketInsideEUCountries: '',
    intraRegionalTargetMarketOutsideEU: 0,
    intraRegionalTargetMarketOutsideEUCountries: '',
    expectedTotalVolumeAnnually: 0,
    averageTransactionValue: 0,
    highestTransactionValue: 0,
    periodicSeasonalRevenue: 0,
    averageRevenuePerMonthCredit: 0,
    averageRevenuePerMonthDebit: 0,
    nameFormerPsp: '',
    reasonForSwitching: '',
    fraudVolumePercentage: 0,
    numberOfChargebacksFromTotalSales: 0,
    numberOfRefundsFromTotalSales: 0,
    processedVolumeLastThreeMonths: 0,
  },
};

export const EMPTY_BILLING_ADDRESS = {
  street: '',
  houseNumber: '',
  houseNumberExtension: '',
  zipCode: '',
  city: '',
  country: '',
  locationNumber: '',
};

export const EMPTY_SHIPPING_ADDRESS = {
  street: '',
  houseNumber: '',
  houseNumberExtension: '',
  zipCode: '',
  city: '',
  country: '',
  locationNumber: '',
};

export const EMPTY_COMPANY = {
  activity: '',
  address: {
    street: '',
    houseNumber: '',
    houseNumberExtension: '',
    zipCode: '',
    city: '',
    country: ''
  },
  invoiceAddress: {
    street: '',
    houseNumber: '',
    houseNumberExtension: '',
    zipCode: '',
    city: '',
    country: '',
  },
  name: '',
  phone: '',
  registrationDate: '',
  registrationNumber: '',
  locationNumber: '',
  registrationNumberTitle: '',
  vatNumber: '',
  tradeName: '',
  website: '',
  industry: '',
  descriptionOfBusinessActivities: '',
  socialMediaFields: null
}

export const emptyUser = {
  productType: ProductType.BLUEBEEM,
  applicant: {
    id: '',
    mobilePhone: '',
    email: '',
    acceptTerms: false,
    salutation: '',
    firstname: '',
    inFix: '',
    lastname: '',
    phone: '',
    gender: '',
    accountId: '',
  },
  packageInformation: {
    hasCards: false,
    hasClicks: false,
    hasCash: true,
    name: '',
    price: 0,
    mostWanted: false,
    packageLines: [],
  },
  contacts: [],
  account: {
    id: '',
    activity: '',
    branche: '',
    iban: '',
    name: '',
    phone: '',
    purchaseOrderNumber: '',
    registrationNumber: '',
    registrationDate: '',
    vatNumber: '',
    website: '',
    billingAddress: EMPTY_BILLING_ADDRESS,
    billingContact: {
      id: '',
      mobilePhone: '',
      department: 'Billing',
      email: '',
      acceptTerms: false,
      salutation: '',
      firstname: '',
      inFix: '',
      lastname: '',
      phone: '',
      gender: '',
      accountId: '',
    },
    shippingAddress: EMPTY_SHIPPING_ADDRESS,
  },
  packageId: '',
  step: 1,
  companySearchQuery: '',
  socialMediaFields: [],
  company: {
    activity: null,
    address: {
      street: '',
      houseNumber: null,
      houseNumberExtension: null,
      zipCode: null,
      city: '',
      country: '',
    },
    invoiceAddress: {
      street: '',
      houseNumber: null,
      houseNumberExtension: null,
      zipCode: null,
      city: '',
      country: '',
    },
    name: '',
    phone: null,
    registrationDate: '',
    registrationNumber: '',
    registrationNumberTitle: '',
    locationNumber: '',
    vatNumber: null,
    tradeName: '',
    website: '',
    industry: '',
    descriptionOfBusinessActivities: '',
    socialMedia: '',
  },
  locations: [EMPTY_LOCATION],
  onboardingCustomerDomain: window.location.host,
};

export const addressModalInitialValues = {
  name: '',
  address: {
    street: '',
    houseNumber: '',
    zipCode: '',
    city: '',
    country: '',
  },
  contact: {
    firstname: '',
    lastname: '',
    email: '',
    mobilePhone: '',
    gender: '',
  },
};
