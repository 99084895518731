export const testErrorMessage = 'FormValidationMessages.invalid_email_warning';
export const selectAValueMessage = 'FormValidationMessages.choose_option_warning';
export const requiredMessage = 'FormValidationMessages.field_mandatory_warning';
export const invalidEmail = 'FormValidationMessages.invalid_email_warning';
export const invalidPhone = 'FormValidationMessages.invalid_phonenumber_warning';
export const invalidPostalCode = 'FormValidationMessages.invalid_postal_code_warning';
export const minCharacters = `FormValidationMessages.minimum_characters_warning`;
export const maxCharacters = `FormValidationMessages.maximum_characters_warning`;
export const maxTwoDecimals = 'FormValidationMessages.two_decimals_maximum_warning';
export const invalidPurchaseOrderNumber = 'FormValidationMessages.character_restriction_warning';
export const moneyMessage = 'FormValidationMessages.fill_in_amount_warning';
export const percentageMessage = 'FormValidationMessages.invalid_percentage_warning';
export const validWebAddressMessage = 'FormValidationMessages.invalid_url_warning';
export const minRequiredMessage = 'FormValidationMessages.two_characters_minimum_warning';
export const domainTooLongMessage = 'FormValidationMessages.domainname_too_long_warning';
export const minNumberMessage = `FormValidationMessages.minimum_value_warning`;
export const maxNumberMessage = `FormValidationMessages.maximum_value_warning`;
export const intraRegionalCountriesMessage = 'FormValidationMessages.select_atleast_one_country_warning';
export const valueTooBigMessage = 'FormValidationMessages.numerical_value_exceeds_maximum_warning';
export const valueStringTooBigMessage = `FormValidationMessages.value_too_long_warning`;
export const terminalMinRequiredMessage = 'FormValidationMessages.select_atleast_one_terminal_warning';
export const invalidVatNumber = 'FormValidationMessages.invalid_vat_number_warning';
export const invalidVatNumberBelgium = 'FormValidationMessages.invalid_vat_number_be_warning';
export const invalidRegistratioNumber = 'FormValidationMessages.invalid_registration_number_warning';
export const termsMessage = 'FormValidationMessages.accept_terms_warning';
export const atleastOneEstablishmentMessage = 'FormValidationMessages.atleast_one_establishment_is_required_warning';
export const missingDetailsEstablishmentsMessage = 'FormValidationMessages.missing_person_for_establishment_warning';
export const closingTimeMessage = 'FormValidationMessages.closing_time_warning';
export const selectDayMessage = 'FormValidationMessages.select_atleast_one_day_warning';
export const invalidAdditionalTimeMessage = 'FormValidationMessages.invalid_additional_time';
export const selectPackageMessage = 'FormValidationMessages.select_atleast_one_package';
