import * as yup from 'yup';
import { CheckoutStep4Service } from '../models/checkout-step-4-form.model';
import { CARDS_SCHEMA } from './cards-schema';
import { CASH_SCHEMA } from './cash-schema';
import { CLICKS_SCHEMA } from './clicks-schema';
import { selectPackageMessage } from '@utilities/validations/messages';

export const CHECKOUT_FOUR_SCHEMA = (t) =>
  yup.object().shape({
    services: yup.array().of(
      yup.object().shape({
        active: yup.boolean().test('is-one-true', t(selectPackageMessage), function (this: any) {
          const formServices = this.from[1].value.services as CheckoutStep4Service[];

          return formServices.filter((service) => service.active).length > 0;
        }),
        ...CASH_SCHEMA(t),
        ...CARDS_SCHEMA(t),
        ...CLICKS_SCHEMA(t),
      }),
    ),
  });
