import StyledButton from '@components/Shared/StyledButton';
import { putAccountDetails } from '@generated/account';
import { BlubeemRequestsApiAccountDetails } from '@generated/brinks.schemas';
import { putOpportunityStep } from '@generated/opportunity';
import { useUser } from '@state/user';
import { useLoader } from '@utilities/context/LoaderContext';
import { UserProps } from '@utilities/interfaces/user';
import { Form, Formik } from 'formik';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FormikInput } from '../../../Shared/form-elements/FormikInput';
import FormHeader from '../../../Shared/FormHeader';
import FormLayout from '../../../Shared/FormLayout';
import FormLayoutColOne from '../../../Shared/FormLayoutColOne';
import FormLayoutColTwo from '../../../Shared/FormLayoutColTwo';
import InfoBox from '../../../Shared/InfoBox';
import { Trans, useTranslation } from 'react-i18next';
import { CHECKOUT_ONE_SCHEMA } from './validation/checkout-one-schema';

const CheckoutOne = () => {
  const history = useHistory();
  const { toggleLoader } = useLoader();
  const { user, setUser } = useUser();
  const { t, i18n } = useTranslation();

  const hasCardsOrClicks = user.packageInformation.hasCards || user.packageInformation.hasClicks;

  const onSubmit = async (values: UserProps) => {
    toggleLoader(true);

    const payload: BlubeemRequestsApiAccountDetails = {
      companyName: values.account.name,
      phone: values.account.phone,
    };

    await putAccountDetails(payload);

    setUser({ ...values, step: 2 });
    toggleLoader(false);
    history.push('/checkout/step-2');
  };

  useEffect(() => {
    putOpportunityStep({ step: '1' });
  }, []);

  return (
    <FormLayout>
      <FormLayoutColOne>
        <FormHeader title="CheckoutOne.header_title" indicator={t('step') + ' 1/6'} />
        <InfoBox theme="light">
          <p className="u-margin-bottom--sm">{t('CheckoutOne.task_description')}</p>
          <ul className="c-list u-margin-bottom--sm">
            <li className="list__item">{t('CheckoutOne.bank_account_number')}</li>
            <li className="list__item">{t('CheckoutOne.identification')}</li>
            <li className="list__item">{t('CheckoutOne.establishment_information')}</li>
            {hasCardsOrClicks && (
              <>
                <li className="list__item">{t('CheckoutOne.company_info_and_legal_representatives')}</li>
                <li className="list__item">{t('CheckoutOne.transaction_information')}</li>
              </>
            )}
          </ul>
          <p>
            <Trans i18nKey="CheckoutOne.time_estimate">
              Het invullen van de vragen is in <b>(ongeveer) 10 minuten</b> geregeld.
            </Trans>
          </p>
        </InfoBox>
      </FormLayoutColOne>
      <FormLayoutColTwo>
        <Formik enableReinitialize initialValues={user} validationSchema={CHECKOUT_ONE_SCHEMA(t)} onSubmit={onSubmit}>
          {({ setErrors }) => {
            i18n.on('languageChanged', () => {
              setErrors({});
            });
            return (
              <Form>
                <FormikInput
                  label="CheckoutOne.company_name"
                  name="account.name"
                  type="text"
                  formType="tertiary"
                  readonly
                  disabled
                />
                <FormikInput label="CheckoutOne.mobile_number" name="account.phone" type="tel" formType="tertiary" />
                <StyledButton tag="button" type="submit" text="Buttons.confirm" formType="tertiary" />
              </Form>
            );
          }}
        </Formik>
      </FormLayoutColTwo>
    </FormLayout>
  );
};

export default CheckoutOne;
