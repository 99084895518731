import { UboFormData } from '@components/ReactCheckout/components/CheckoutFive/models/ubo-form-data.model';
import StyledButton from '@components/Shared/StyledButton';
import { SignicatUboProcess } from '@utilities/interfaces/user';
import React, { useMemo } from 'react';
import { CheckRound, Chevron } from '../../../Shared/svg/icons';
import { SignicatStatus } from './models/signicat-status.enum';
import { useTranslation } from 'react-i18next';

interface Props {
  ubo: UboFormData;
  signicatProcessData?: SignicatUboProcess;
  handleEditClick: (ubo: UboFormData) => void;
  handleRemoveClick: (ubo: UboFormData) => void;
  verifyUbo: (ubo: UboFormData) => void;
}

const Ubo: React.FC<Props> = ({ ubo, signicatProcessData, handleEditClick, handleRemoveClick, verifyUbo }) => {
  const { status } = useMemo(() => ({ status: signicatProcessData?.status }), [signicatProcessData]);
  const { t } = useTranslation();
  const statusClass = useMemo(() => {
    switch (status) {
      case SignicatStatus.SUCCESS:
        return 'c-ubo is--verified';
      case SignicatStatus.FAILED:
        return 'c-ubo is--failed';
      default:
        'c-ubo';
    }
  }, [status]);

  return (
    <div className={statusClass || 'c-ubo'}>
      <div className="ubo__content-container">
        <div className="ubo__content">
          <span className="ubo__title">
            {ubo?.firstname} {ubo?.middlename} {ubo?.lastname}
          </span>
        </div>
        <div className="ubo__buttons">
          <div>
            <button className="ubo__button ubo__button--edit" onClick={() => handleEditClick(ubo)}>
              {t('Ubo.edit')}
            </button>
            <button className="ubo__button ubo__button--delete" onClick={() => handleRemoveClick(ubo)}>
              {t('Ubo.delete')}
            </button>
          </div>
          {!!status && status !== SignicatStatus.SUCCESS && (
            <StyledButton
              tag="button"
              text="Ubo.verify_again"
              classes="ubo__button--verify"
              onClick={() => verifyUbo(ubo)}
            >
              <Chevron classes="ubo__icon" />
            </StyledButton>
          )}
          {!status && (
            <StyledButton tag="button" text="Ubo.verify" classes="ubo__button--verify" onClick={() => verifyUbo(ubo)}>
              <Chevron classes="ubo__icon" />
            </StyledButton>
          )}
        </div>
      </div>
      <div className="ubo__icon-wrapper">
        <CheckRound classes="ubo__icon" />
      </div>
    </div>
  );
};

export default Ubo;
