import {
  maxTwoDecimals,
  moneyMessage,
  percentageMessage,
  requiredMessage,
  valueTooBigMessage,
} from '@utilities/validations/messages';
import { ServiceType } from '../models/service-type.enum';
import * as yup from 'yup';
import { maxTwoDecimalRegExp } from '@utilities/validations/regex';

export const validateMoneyInput = (t, attributeName: string, serviceType: ServiceType) =>
  yup
    .number()
    .when(['active', 'name'], {
      is: (active, name) => name === serviceType && active,
      then: yup
        .number()
        .required(t(moneyMessage))
        .test('exists', t(moneyMessage), function (this: yup.TestContext) {
          if (attributeName in this.parent) {
            const value = this.parent[attributeName as keyof typeof this.parent];
            return value > 0;
          }
          return true;
        })
        .test('decimals', t(maxTwoDecimals), (value) => maxTwoDecimalRegExp.test(String(value)))
        .test('length', t(valueTooBigMessage, { amount: 15 }), (value) => value < 999999999999999)
        .nullable(),
      otherwise: undefined,
    })
    .nullable();

export const validateNumberInput = (serviceType: ServiceType) =>
  yup
    .number()
    .when(['active', 'name'], {
      is: (active, name) => name === serviceType && active,
      then: yup.number().nullable(),
      otherwise: undefined,
    })
    .nullable();

export const validateRangeSlider = (t, attributeName: string, serviceType: ServiceType) =>
  yup
    .number()
    .when(['active', 'name'], {
      is: (active, name) => name === serviceType && active,
      then: yup
        .number()
        .test('exists', t(percentageMessage), function (this: yup.TestContext) {
          if (attributeName in this.parent) {
            const value = this.parent[attributeName as keyof typeof this.parent];

            if (value) {
              return value >= 0 && value <= 100;
            }
            return true;
          }
          return true;
        })
        .nullable(),
      otherwise: undefined,
    })
    .nullable();

export const validateSelect = (t, serviceType: ServiceType) =>
  yup
    .string()
    .when(['active', 'name'], {
      is: (active, name) => name === serviceType && active,
      then: yup.string().required(t(requiredMessage)).nullable(),
      otherwise: undefined,
    })
    .nullable();
