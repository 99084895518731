import { BlubeemModelsApiLocation, BlubeemModelsApiLocationDay } from '@generated/brinks.schemas';

export function serializeDayInformation(
  dayInformation?: BlubeemModelsApiLocationDay[],
  location?: BlubeemModelsApiLocation,
): BlubeemModelsApiLocationDay[] {
  if (!dayInformation || !location) {
    return [];
  }

  return dayInformation.map((day, index) => ({
    ...day,
    openingHours: day.openingHours.map((openingHour) => ({
      ...openingHour,
      id: openingHour.id || '',
      openingTime: serializeTime(openingHour.openingTime),
      closingTime: serializeTime(openingHour.closingTime),
    })),
  }));
}

export function deserializeDayInformation(
  dayInformation?: BlubeemModelsApiLocationDay[],
): BlubeemModelsApiLocationDay[] {
  if (!dayInformation) {
    return [];
  }

  return dayInformation.map((day) => ({
    ...day,
    openingHours: day?.openingHours?.map((openingHour) => ({
      ...openingHour,
      id: openingHour.id || '',
      openingTime: deserializeTime(openingHour.openingTime),
      closingTime: deserializeTime(openingHour.closingTime),
    })),
  }));
}

function serializeTime(time: string): string {
  return `${time}:00.000Z`;
}

function deserializeTime(time: string): string {
  const noTimezone = time.split(':00.')[0];

  const hours = noTimezone.split(':')[0];
  const minutes = noTimezone.split(':')[1];

  return `${Number(hours)}:${minutes}`;
}
