import { countries } from '@utilities/countries';
import {
  invalidEmail,
  invalidPhone,
  maxCharacters,
  requiredMessage,
  selectAValueMessage,
} from '@utilities/validations/messages';
import { emailRegExp, phoneRegExp } from '@utilities/validations/regex';
import * as yup from 'yup';

export const UBO_FORM_SCHEMA = (hasCardsOrClicks, t) => {
  const countryValue = countries.map((country) => country.value);

  return yup.object().shape({
    firstname: yup
      .string()
      .nullable()
      .required(t(requiredMessage))
      .max(40, t(maxCharacters, { amount: 40 })),
    middlename: yup
      .string()
      .nullable()
      .max(40, t(maxCharacters, { amount: 40 })),
    lastname: yup
      .string()
      .nullable()
      .required(t(requiredMessage))
      .max(40, t(maxCharacters, { amount: 40 })),
    gender: yup.string().oneOf(['M', 'F']).required(t(selectAValueMessage)),
    homeAddress: yup
      .string()
      .when({
        is: () => !!hasCardsOrClicks,
        then: yup
          .string()
          .nullable()
          .required(t(requiredMessage))
          .max(50, t(maxCharacters, { amount: 50 })),
      })
      .nullable(),
    postalCode: yup
      .string()
      .when({
        is: () => !!hasCardsOrClicks,
        then: yup
          .string()
          .nullable()
          .required(t(requiredMessage))
          .max(10, t(maxCharacters, { amount: 10 })),
      })
      .nullable(),
    city: yup
      .string()
      .when({
        is: () => !!hasCardsOrClicks,
        then: yup
          .string()
          .nullable()
          .required(t(requiredMessage))
          .max(40, t(maxCharacters, { amount: 40 })),
      })
      .nullable(),
    country: yup
      .string()
      .when({
        is: () => !!hasCardsOrClicks,
        then: yup.string().oneOf(countryValue).required(t(selectAValueMessage)),
      })
      .nullable(),
    email: yup
      .string()
      .when({
        is: () => !!hasCardsOrClicks,
        then: yup.string().nullable().matches(emailRegExp, t(invalidEmail)).required(t(requiredMessage)),
      })
      .nullable(),
    title: yup
      .string()
      .nullable()
      .max(40, t(maxCharacters, { amount: 40 })),
    mobilePhone: yup.string().nullable().matches(phoneRegExp, t(invalidPhone)).required(t(requiredMessage)),
  });
};
