import StyledButton from '@components/Shared/StyledButton';
import { BlubeemModelsApiPicklistValue, BlubeemModelsApiProduct } from '@generated/brinks.schemas';
import { useUser } from '@state/user';
import { FieldArray, Form } from 'formik';
import { FC, useEffect, useMemo, useState } from 'react';
import { Cards } from './Cards/Cards';
import { Cash } from './Cash/Cash';
import { Clicks } from './Clicks/Clicks';
import { FormikScrollToErrors } from './shared/FormikScrollToErrors';
import { getPicklistNameFormerPSP } from '@generated/picklist';
import { setToken as setAxiosToken } from '@utilities/services/setToken';
import { formerPSP } from '@utilities/formerPSP';
import { useTranslation } from 'react-i18next';

interface CashCardsClicksProps {
  callback?: (uuid: string) => void;
  currentSlideIndex: number;
  nrOflocations: number;
  setSubmitType: (type: 'nextSlide' | 'saveAllNextStep' | 'copyToAll') => void;
  products: BlubeemModelsApiProduct[];
  nextSlide: () => void;
}

const CashCardsClicks: FC<CashCardsClicksProps> = ({
  currentSlideIndex,
  nrOflocations,
  setSubmitType,
  products,
  nextSlide,
}) => {
  const { user } = useUser();
  const [formerPspPicklist, setFormerPspPicklist] = useState<BlubeemModelsApiPicklistValue[]>();
  const [resetValue, setResetValue] = useState(false);
  const [resetAccessoires, setResetAccessoires] = useState(0);
  const { t } = useTranslation();

  const token =
    (sessionStorage.getItem('USER_ACCOUNT_TOKEN') as string) ?? (sessionStorage.getItem('USER_TOKEN') as string);

  useEffect(() => {
    const getFormerPspPicklist = async () => {
      try {
        setAxiosToken(token);
        const { data } = await getPicklistNameFormerPSP();
        setFormerPspPicklist(data.values);
      } catch {
        setFormerPspPicklist(formerPSP);
      }
    };

    getFormerPspPicklist();
  }, []);

  const hasCards = useMemo(() => user.packageInformation.hasCards, [user]);
  const hasClicks = useMemo(() => user.packageInformation.hasClicks, [user]);
  const hasCash = useMemo(() => user.packageInformation.hasCash, [user]);

  return (
    <Form>
      <FormikScrollToErrors />
      <FieldArray
        name="services"
        render={() => {
          return (
            <div className="c-cash-cards-clicks">
              {hasCash && (
                <Cash
                  products={products}
                  locationIndex={currentSlideIndex}
                  resetValue={resetValue}
                  resetAccessoires={resetAccessoires}
                />
              )}
              {formerPspPicklist && hasCards && (
                <Cards
                  products={products}
                  formerPSPPicklistValues={formerPspPicklist}
                  resetValue={resetValue}
                  resetAccessoires={resetAccessoires}
                />
              )}
              {formerPspPicklist && hasClicks && (
                <Clicks
                  products={products}
                  formerPSPPicklistValues={formerPspPicklist}
                  resetValue={resetValue}
                  resetAccessoires={resetAccessoires}
                />
              )}
            </div>
          );
        }}
      />
      {currentSlideIndex !== nrOflocations - 1 && (
        <StyledButton
          tag="button"
          type="button"
          text="CheckoutFour.next_establishment"
          classes="u-margin-bottom--xs"
          fullWidth
          onClick={() => {
            nextSlide();
            setResetValue(true);
            setResetAccessoires(currentSlideIndex);
          }}
        />
      )}
      {currentSlideIndex === 0 && nrOflocations > 1 && (
        <StyledButton
          classes="cash-cards-clicks__submit-button"
          tag="button"
          type="submit"
          text="CheckoutFour.save_for_all_and_continue"
          fullWidth
          onClick={() => setSubmitType('copyToAll')}
        />
      )}
      {currentSlideIndex === nrOflocations - 1 && (
        <StyledButton
          classes="cash-cards-clicks__submit-button"
          tag="button"
          type="submit"
          text="CheckoutFour.save_and_continue"
          fullWidth
          onClick={() => setSubmitType('saveAllNextStep')}
        />
      )}
    </Form>
  );
};

export default CashCardsClicks;
