import { CountryPrefix } from '@utilities/countryPrefix';
import {
  invalidEmail,
  invalidPhone,
  invalidPostalCode,
  maxCharacters,
  minCharacters,
  requiredMessage,
} from '@utilities/validations/messages';
import { belgiumPostcode, dutchPostcode, emailRegExp, phoneRegExp } from '@utilities/validations/regex';
import * as yup from 'yup';

export const CHECKOUT_THREE_SCHEMA = (t, hostCountry: CountryPrefix) =>
  yup.object().shape({
    name: yup.string().max(80, t(maxCharacters, { amount: 80 })),
    address: yup.object().shape({
      street: yup
        .string()
        .nullable()
        .max(255, t(maxCharacters, { amount: 255 }))
        .required(t(requiredMessage)),
      houseNumber: yup
        .string()
        .nullable()
        .max(255, t(maxCharacters, { amount: 255 }))
        .required(t(requiredMessage)),
        zipCode: yup
        .string()
        .required(t(requiredMessage))
        .test('checkPostcode', t(invalidPostalCode), function(this: yup.TestContext) {
          if(!this.parent.zipCode) return false;
          if (hostCountry === CountryPrefix.Netherlands && this.parent.zipCode.match(dutchPostcode)) return true;
          if (hostCountry === CountryPrefix.Belgium && this.parent.zipCode.match(belgiumPostcode)) return true;
          return false;
        }),
      city: yup
        .string()
        .nullable()
        .max(80, t(maxCharacters, { amount: 80 }))
        .required(t(requiredMessage)),
      // This will probably be added back to the project once we added BE handling for foreign addresses
      // country: yup.string().test('exists', selectAValueMessage, function (this: yup.TestContext) {
      //   const { country } = this.parent;
      //   return countries.includes(country);
      // }),
    }),
    contact: yup.object().shape({
      firstname: yup
        .string()
        .min(2, t(minCharacters, { amount: 2 }))
        .max(40, t(maxCharacters, { amount: 40 }))
        .required(t(requiredMessage)),
      lastname: yup
        .string()
        .min(2, t(minCharacters, { amount: 2 }))
        .max(80, t(maxCharacters, { amount: 80 }))
        .required(t(requiredMessage)),
      email: yup
        .string()
        .matches(emailRegExp, t(invalidEmail))
        .max(255, t(maxCharacters, { amount: 255 }))
        .required(t(requiredMessage)),
      mobilePhone: yup.string().matches(phoneRegExp, t(invalidPhone)).required(t(requiredMessage)),
    }),
  });
