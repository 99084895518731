import classNames from 'classnames';
import { ErrorMessage, Field, useFormikContext } from 'formik';
import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';

interface Props {
  classes?: string;
  name: string;
  label: string;
  labelSubtitle?: string;
  placeholder?: string;
  formType?: string;
  style?: CSSProperties;
  onFocus?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const FormikDatePicker: React.FC<Props> = ({
  name,
  classes: propsClasses,
  label,
  labelSubtitle,
  placeholder,
  style,
  formType = 'tertiary',
  onFocus,
  onBlur,
}) => {
  const [active, setActive] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const inputRef = useRef<DatePicker>(null);
  const { setFieldValue, initialValues } = useFormikContext();
  const { t } = useTranslation();

  const classes = classNames({
    'c-input form__item': true,
    [`${propsClasses}`]: Boolean(propsClasses),
    'form__item--active': active,
    'form__item--error': false,
    [`form__item--${formType}`]: formType,
  });

  const labelClasses = classNames({
    input__label: true,
  });

  const localOnFocus = (e) => {
    if (onFocus) {
      onFocus(e);
    }
    if (!inputRef.current) return;
    if (active === false) setActive(true);
  };

  const localOnBlur = (e) => {
    if (onBlur) {
      onBlur(e);
    }
    if (!inputRef.current) return;
    const ACTION = startDate ? true : false;
    setActive(ACTION);
  };

  const localOnChange = (date: Date) => {
    setStartDate(date);
    setFieldValue(name, date.toISOString());
  };

  useEffect(() => {
    const value = initialValues[name];

    if (typeof value === 'string') {
      setStartDate(value ? new Date(value) : new Date());
    }
    setActive(!!startDate);
  }, [initialValues]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Field name={name}>
      {({ meta }) => (
        <div className={classes} style={style || {}}>
          <div className="input__wrapper">
            <label htmlFor={name} className={labelClasses} style={{ zIndex: 1 }}>
              {t(label)}
              {labelSubtitle && <span className="product-input__label-subtitle"> {t(labelSubtitle)}</span>}
            </label>
            <DatePicker
              name={name}
              ref={inputRef}
              onFocus={localOnFocus}
              onBlur={localOnBlur}
              selected={startDate}
              onChange={(date) => localOnChange(date)}
              dateFormat="dd/MM/yyyy"
              className="input__input"
              placeholderText={t(placeholder)}
              maxDate={new Date()}
            />
          </div>
          {Boolean(meta.touched && meta.error) && (
            <p className="input__error">
              <ErrorMessage name={name} />
            </p>
          )}
        </div>
      )}
    </Field>
  );
};
