import { FormikNumberInput } from '@components/Shared/form-elements/FormikNumberInput';
import { FormikRange } from '@components/Shared/form-elements/FormikRange';
import { BlubeemModelsApiPicklistValue, BlubeemModelsApiProduct } from '@generated/brinks.schemas';
import Collapse from '@shared/collapse/Collapse';
import { FormikRadio } from '@shared/form-elements/FormikRadio';
import { FormikSelect } from '@shared/form-elements/FormikSelect';
import { FormikToggle } from '@shared/form-elements/FormikToggle';
import { countries } from '@utilities/countries';
import { useFormikContext } from 'formik';
import React, { useCallback, useMemo, useState } from 'react';
import { CheckoutStep4FormData } from '../../models/checkout-step-4-form.model';
import { ServiceType } from '../../models/service-type.enum';
import Accessories from '../shared/Accessories';
import FormTitle from '../shared/FormTitle';
import GroupButton from '../shared/GroupButton';
import { useTranslation } from 'react-i18next';
import { FormikMultiSelect } from '@components/Shared/form-elements/FormikMultiSelect';
import { reasonForSwitching } from '@utilities/reasonForSwitching';

const PAGE_SERVICE_TYPE = ServiceType.Cards;

interface Props {
  products: BlubeemModelsApiProduct[];
  formerPSPPicklistValues: BlubeemModelsApiPicklistValue[];
  resetValue: boolean;
  resetAccessoires: number;
}

export const Cards: React.FC<Props> = ({ products, formerPSPPicklistValues, resetValue, resetAccessoires }) => {
  const { setFieldValue, values, setFieldTouched } = useFormikContext<CheckoutStep4FormData>();
  const [cardsActive, setCardsActive] = useState<boolean>(true);
  const { t } = useTranslation();

  const serviceIndex = useMemo(
    () => values.services.findIndex((service) => service.name === PAGE_SERVICE_TYPE),
    [values],
  );
  const active = useMemo(() => values.services.find((service) => service.name === PAGE_SERVICE_TYPE).active, [values]);

  const cardsProducts = useMemo(
    () => products.filter((product) => product.serviceType?.toLowerCase() === PAGE_SERVICE_TYPE),
    [products],
  );

  const setAllTogglesTouchedForValidation = useCallback(() => {
    values.services.forEach((_, index) => setFieldTouched(`services[${index}].active`, true, true));
  }, [values.services, setFieldTouched]);

  // useEffect(() => {
  //   const languageChangedHandler = () => {
  //     // setCountriesPicklist(countries(t));
  //   };

  //   i18next.on('languageChanged', languageChangedHandler);

  //   return () => {
  //     i18next.off('languageChanged', languageChangedHandler);
  //   };
  // }, [t]);

  return (
    <div className={`${active ? 'collapse__wrapper-active' : null}`}>
      <div className="cash-cards-clicks__button-toggle">
        <GroupButton title={'Cards'} defaultActive={true} onToggle={setCardsActive}>
          <div className="cash-cards-clicks__item">
            <FormikToggle
              formType="secondary"
              label={active ? 'CheckoutFourCards.cards_off' : 'CheckoutFourCards.cards_on'}
              uncheckedLabel="CheckoutFourCards.cards_on"
              checkedLabel="CheckoutFourCards.cards_off"
              name={`services[${serviceIndex}].active`}
              hideLabel={true}
              optimized={false}
              onChange={() => {
                setFieldValue(`services[${serviceIndex}].active`, !active);
                setAllTogglesTouchedForValidation();
              }}
            />
          </div>
        </GroupButton>
      </div>
      <Collapse active={active}>
        <>
          <div className="cash-cards-clicks__item">
            <FormikRadio
              name={`services[${serviceIndex}].cardsGoodsDeliveredAfterTransactionMoment`}
              label="CheckoutFourCards.delivery_of_goods_and_services"
              labelSubtitle="CheckoutFourCards.delivery_question"
              options={[
                { value: 'true', displayValue: 'CheckoutFourCards.yes' },
                { value: 'false', displayValue: 'CheckoutFourCards.no' },
              ]}
            />
          </div>
          <Collapse
            active={(values.services[serviceIndex].cardsGoodsDeliveredAfterTransactionMoment as any) === 'true'}
          >
            <div className="cash-cards-clicks__item">
              <FormikNumberInput
                title="CheckoutFourCards.lead_time"
                label="CheckoutFourCards.lead_time"
                subtitle="CheckoutFourCardsClicksShared.average_lead_time_in_days"
                name={`services[${serviceIndex}].cardsLeadTimeInDays`}
                formType="tertiary"
              />
            </div>
          </Collapse>
          <div className="cash-cards-clicks__item">
            <FormikRange
              name={`services[${serviceIndex}].cardsDeliveryAfterTransactionDifference`}
              label="CheckoutFourCards.distribution_delivery_immediately_versus_after"
              labelSubtitle="CheckoutFourCards.percentage_delivery_after_transaction"
              suffix="%"
              minLabel="CheckoutFourCards.immediately"
              maxLabel="CheckoutFourCards.after"
              tooltip="CheckoutFourCards.percentage_of_products_delivered_immediately_versus_after"
              split
              initialValue={50}
              resetValue={resetValue}
            />
          </div>
          <div className="cash-cards-clicks__item">
            <FormikNumberInput
              title="CheckoutFourCards.average_revenue"
              label="CheckoutFourCards.average_revenue"
              subtitle="CheckoutFourCards.average_cards_revenue_per_month"
              name={`services[${serviceIndex}].averageRevenue`}
              formType="tertiary"
              prefix="€"
            />
          </div>
          <div className="cash-cards-clicks__item">
            <FormikRange
              label="CheckoutFourCardsClicksShared.seasonal_revenue"
              name={`services[${serviceIndex}].cardsSeasonalRevenuePercentage`}
              labelSubtitle="CheckoutFourCards.percentage_of_revenue_seasonal_or_periodic"
              suffix="%"
              tooltip="CheckoutFourCards.seasonal_revenue_specifics_question"
              resetValue={resetValue}
            />
          </div>
          <div className="cash-cards-clicks__item">
            <FormikRadio
              name={`services[${serviceIndex}].possibilityToReturnPins`}
              label="CheckoutFourCards.extra_services"
              labelSubtitle="CheckoutFourCards.opportunity_to_return_pin"
              options={[
                { value: 'true', displayValue: 'CheckoutFourCards.yes' },
                { value: 'false', displayValue: 'CheckoutFourCards.no' },
              ]}
            />
          </div>
          <div className="cash-cards-clicks__item">
            <FormikRadio
              name={`services[${serviceIndex}].tipTransactions`}
              label="CheckoutFourCards.extra_services"
              labelSubtitle="CheckoutFourCards.opportunity_to_tip"
              options={[
                { value: 'true', displayValue: 'CheckoutFourCards.yes' },
                { value: 'false', displayValue: 'CheckoutFourCards.no' },
              ]}
            />
          </div>
          <div className="cash-cards-clicks__item">
            <FormikRange
              name={`services[${serviceIndex}].cardsConsumptionDistributionDifference`}
              label="CheckoutFourCardsClicksShared.distribution_consumer_versus_corporate"
              labelSubtitle="CheckoutFourCardsClicksShared.percentage_consumer_versus_corporate"
              suffix="%"
              minLabel="CheckoutFourCardsClicksShared.consumer"
              maxLabel="CheckoutFourCardsClicksShared.corporate"
              split
              initialValue={100}
              resetValue={resetValue}
            />
          </div>
          <div className="cash-cards-clicks__item">
            <FormikRange
              name={`services[${serviceIndex}].cardsAverageRevenuePerPaymentMethodDifference`}
              label="CheckoutFourCards.average_revenue_per_payment_method"
              labelSubtitle="CheckoutFourCards.percentage_credit_versus_debit"
              suffix="%"
              minLabel="CheckoutFourCards.credit"
              maxLabel="CheckoutFourCards.debit"
              split
              initialValue={100}
              resetValue={resetValue}
            />
          </div>
          <div className="cash-cards-clicks__item">
            <FormikRange
              name={`services[${serviceIndex}].cardsDomesticTargetMarketPercentage`}
              label="CheckoutFourCardsClicksShared.percentage_domestic_target_market"
              labelSubtitle="CheckoutFourCards.percentage_of_revenue_in_target_market"
              suffix="%"
              resetValue={resetValue}
            />
          </div>
          <div className="cash-cards-clicks__item">
            <FormikSelect
              optimized={false}
              shouldTranslateValues
              label="CheckoutFourCardsClicksShared.domestic_country"
              labelSubtitle="CheckoutFourCardsClicksShared.target_market_country"
              name={`services[${serviceIndex}].cardsDomesticTargetCountryName`}
              placeholder="CheckoutFourCardsClicksShared.choose_country"
              options={countries}
            />
          </div>
          <div className="cash-cards-clicks__item">
            <FormikRange
              name={`services[${serviceIndex}].cardsIntraRegionalTargetMarketDifference`}
              label="CheckoutFourCards.distribution_transactions"
              labelSubtitle="CheckoutFourCardsClicksShared.percentage_inside_and_outside_eu"
              suffix="%"
              minLabel="CheckoutFourCardsClicksShared.inside_eu"
              maxLabel="CheckoutFourCardsClicksShared.outside_eu"
              split
              resetValue={resetValue}
            />
          </div>
          <div className="cash-cards-clicks__item">
            <FormikMultiSelect
              label="CheckoutFourCards.target_market_inside_eu"
              labelSubtitle="CheckoutFourCardsClicksShared.select_countries"
              name={`services[${serviceIndex}].cardsIntraRegionalTargetMarketInEuCountries`}
              placeholder="CheckoutFourCardsClicksShared.choose_country"
              options={countries.map((current) => ({
                value: current.value,
                label: t(current.displayValue),
              }))}
            />
          </div>
          <div className="cash-cards-clicks__item">
            <FormikMultiSelect
              label="CheckoutFourCards.target_market_outside_eu"
              labelSubtitle="CheckoutFourCardsClicksShared.select_countries"
              placeholder="CheckoutFourCardsClicksShared.choose_country"
              name={`services[${serviceIndex}].cardsIntraRegionalTargetMarketOutsideEuCountries`}
              options={countries.map((current) => ({
                value: current.value,
                label: t(current.displayValue),
              }))}
            />
          </div>
          <div className="cash-cards-clicks__item">
            <FormikNumberInput
              label="CheckoutFourCardsClicksShared.expected_total_volume_per_year_in_euros"
              title="CheckoutFourCardsClicksShared.expected_total_volume_per_year_in_euros"
              name={`services[${serviceIndex}].cardsExpectedTotalVolumeAnnually`}
              formType="tertiary"
              prefix="€"
            />
          </div>
          <div className="cash-cards-clicks__item">
            <FormikNumberInput
              label="CheckoutFourCardsClicksShared.average_transaction_value"
              title="CheckoutFourCardsClicksShared.average_transaction_value"
              subtitle="CheckoutFourCards.average_transaction_value_per_pin"
              name={`services[${serviceIndex}].cardsAverageTransactionValue`}
              formType="tertiary"
              prefix="€"
            />
          </div>
          <div className="cash-cards-clicks__item">
            <FormikNumberInput
              label="CheckoutFourCardsClicksShared.highest_transaction_value"
              title="CheckoutFourCardsClicksShared.highest_transaction_value"
              subtitle="CheckoutFourCards.highest_transaction_value_per_pin"
              name={`services[${serviceIndex}].cardsHighestTransactionValue`}
              formType="tertiary"
              prefix="€"
            />
          </div>
          <FormTitle variant="primary" title="CheckoutFourCardsClicksShared.transaction_history" />
          <div className="cash-cards-clicks__item">
            <FormikRadio
              name={`services[${serviceIndex}].madePaymentsWithAnotherPsp`}
              label="CheckoutFourCardsClicksShared.transaction_history"
              labelSubtitle="CheckoutFourCardsClicksShared.previous_payments_other_psp"
              options={[
                { value: 'true', displayValue: 'CheckoutFourCards.yes' },
                { value: 'false', displayValue: 'CheckoutFourCards.no' },
              ]}
            />
          </div>

          <Collapse active={(values.services[serviceIndex].madePaymentsWithAnotherPsp as any) === 'true'}>
            <div className="cash-cards-clicks__item">
              <FormikSelect
                optimized={false}
                label="CheckoutFourCardsClicksShared.previous_psp"
                name={`services[${serviceIndex}].cardsNameFormerPsp`}
                placeholder="CheckoutFourCardsClicksShared.choose_psp"
                options={formerPSPPicklistValues.map((value) => ({
                  value: value.value,
                  displayValue: value.displayValue,
                }))}
              />
            </div>
            <div className="cash-cards-clicks__item">
              <FormikSelect
                optimized={false}
                label="CheckoutFourCardsClicksShared.reason_for_switch"
                name={`services[${serviceIndex}].cardsReasonForSwitching`}
                shouldTranslateValues
                placeholder="CheckoutFourCardsClicksShared.choose_reason"
                options={reasonForSwitching}
              />
            </div>

            <div className="cash-cards-clicks__item">
              <FormikNumberInput
                title="CheckoutFourCardsClicksShared.total_transactions_last_three_months_current_psp"
                label="CheckoutFourCardsClicksShared.payment_history_current_psp"
                subtitle=""
                name={`services[${serviceIndex}].cardsProcessedVolumeLastThreeMonths`}
                formType="tertiary"
              />
            </div>
            <div className="cash-cards-clicks__item">
              <FormikRange
                name={`services[${serviceIndex}].cardsReturnPaymentPercentage`}
                label="CheckoutFourCardsClicksShared.return_payment_volume"
                formType="tertiary"
                suffix="%"
                resetValue={resetValue}
              />
            </div>
            <div className="cash-cards-clicks__item">
              <FormikRange
                name={`services[${serviceIndex}].cardsChargebackPercentage`}
                label="CheckoutFourCardsClicksShared.charge_back_volume"
                formType="tertiary"
                suffix="%"
                resetValue={resetValue}
              />
            </div>
            <div className="cash-cards-clicks__item">
              <FormikRange
                name={`services[${serviceIndex}].cardsFraudVolumePercentage`}
                label="CheckoutFourCardsClicksShared.fraud_volume"
                formType="tertiary"
                suffix="%"
                resetValue={resetValue}
              />
            </div>
          </Collapse>
        </>

        {cardsProducts.length > 0 && (
          <Accessories
            products={cardsProducts}
            service={values?.services[serviceIndex]}
            resetValue={resetValue}
            resetAccessoires={resetAccessoires}
          />
        )}
      </Collapse>
    </div>
  );
};
