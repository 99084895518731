import classNames from 'classnames';
import { ErrorMessage } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import Tooltip from '../Tooltip';
import { useTranslation } from 'react-i18next';

export interface InputProps {
  classes?: string;
  disabled?: boolean;
  readonly?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  sronly?: boolean;
  label: string;
  labelSubtitle?: string;
  value?: string | number;
  type?: string;
  name: string;
  placeholder?: string;
  formType?: string;
  hasError?: boolean;
  tooltip?: string;
}

const Input: React.FC<InputProps> = ({ formType = 'primary', ...props }) => {
  const [active, setActive] = useState(false);
  const { t } = useTranslation();

  const classes = classNames({
    'c-input form__item': true,
    [`${props.classes}`]: Boolean(props.classes),
    'form__item--active': active,
    'form__item--error': props.hasError,
    [`form__item--${formType}`]: formType,
  });

  const labelClasses = classNames({
    input__label: true,
    'u-sr-only': Boolean(props.sronly),
  });

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setActive(Boolean(props.value));
  }, [props.value]);

  useEffect(() => {
    if (!inputRef.current) return;
    if (inputRef.current.value) setActive(true);
  }, []);

  const onFocus = (e) => {
    if (props.onFocus) {
      props.onFocus(e);
    }
    if (!inputRef.current) return;
    if (active === false) setActive(true);
  };

  const onBlur = (e) => {
    if (props.onBlur) {
      props.onBlur(e);
    }
    if (!inputRef.current) return;
    const ACTION = inputRef.current.value ? true : false;
    setActive(ACTION);
  };

  return (
    <div className={classes}>
      <div className="input__wrapper">
        <label htmlFor={props.name} className={labelClasses}>
          {t(props.label)}
          {props.labelSubtitle && <span className="product-input__label-subtitle"> {t(props.labelSubtitle)}</span>}
        </label>
        <input
          onChange={props.onChange}
          type={props.type || 'text'}
          className="input__input"
          name={props.name}
          value={props.value || ''}
          onFocus={onFocus}
          onBlur={onBlur}
          id={props.name}
          ref={inputRef}
          disabled={props.disabled}
          readOnly={props.readonly}
          placeholder={props.placeholder}
        />
        {props.tooltip && <Tooltip text={props.tooltip || ''} classes="input__tooltip" />}
      </div>
      {props.hasError && (
        <p className="input__error">
          <ErrorMessage name={props.name} />
        </p>
      )}
    </div>
  );
};

export default Input;
