import StyledButton from '@components/Shared/StyledButton';
import { Modal } from '@shared/modal/Modal';
import { useModalActions } from '@utilities/context/ModalContext';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface RemoveLocationModalProps {
  removeLocation: (locationIndex: number) => void;
  locationIndex: number;
  numberOfLocations: number;
}

const RemoveLocationModal: React.FC<RemoveLocationModalProps> = ({
  numberOfLocations,
  locationIndex,
  removeLocation,
}) => {
  const { hide } = useModalActions();
  const { t } = useTranslation();
  const [error, setError] = useState(false);

  const onClose = () => {
    setError(false);
  };

  const removeLocationCheck = () => {
    if (numberOfLocations > 1) {
      removeLocation(locationIndex);
      hide('remove-location-modal');
    } else {
      setError(true);
    }
  };

  return (
    <Modal
      id="remove-location-modal"
      title={t('RemoveLocationModal.delete_establishment', { locationIndex: locationIndex + 1 })}
      isClosable={true}
      onClose={onClose}
    >
      <p className="u-margin-top--xs u-text--secondary">{t('RemoveLocationModal.delete_confirmation_message')}</p>
      <StyledButton
        tag="button"
        text="Buttons.no_do_not_delete"
        type="button"
        formType="tertiary"
        classes="u-margin-right--xs u-margin-bottom--xs"
        onClick={() => hide('remove-location-modal')}
      />
      <StyledButton
        tag="button"
        text="Buttons.yes_delete"
        type="button"
        formType="tertiary"
        onClick={removeLocationCheck}
      />

      {error && (
        <div>
          <span className="u-text--red">{t('RemoveLocationModal.atleast_one_establishment_warning')}</span>
        </div>
      )}
    </Modal>
  );
};

export default RemoveLocationModal;
