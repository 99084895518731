/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/label-has-associated-control */
import classNames from 'classnames';
import { ErrorMessage } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface CheckboxProps {
  classes?: string;
  formType?: string;
  disabled?: boolean;
  readonly?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: () => void;
  sronly?: boolean;
  label: string;
  value?: boolean;
  name: string;
  hasError?: boolean;
  align?: string;
  checked?: boolean;
}

const Checkbox: React.FC<CheckboxProps> = ({ formType = 'primary', align = 'top', ...props }) => {
  const { t } = useTranslation();

  const classes = classNames({
    'c-checkbox form__item': true,
    [`${props.classes}`]: Boolean(props.classes),
    'form__item--error': props.hasError,
    [`form__item--${formType}`]: formType,
  });

  const labelClasses = classNames({
    checkbox__label: true,
    [`checkbox__label--${align}`]: Boolean(align),
  });

  const inputClasses = classNames({
    checkbox__input: true,
    'u-sr-only': Boolean(props.sronly),
  });

  return (
    <div className={classes}>
      <div className="checkbox__wrapper">
        <input
          className={inputClasses}
          type="checkbox"
          name={props.name}
          id={props.name}
          onChange={props.onChange}
          disabled={props.disabled}
          readOnly={props.readonly}
          checked={props.checked}
        />
        <label
          htmlFor={props.name}
          className={labelClasses}
          dangerouslySetInnerHTML={{ __html: t(props.label) }}
          role="button"
          onClick={props.onClick}
        />
      </div>
      {props.hasError && (
        <p className="checkbox__error">
          <ErrorMessage name={props.name} />
        </p>
      )}
    </div>
  );
};

export default Checkbox;
