import {
  invalidPhone,
  maxCharacters,
  requiredMessage,
  termsMessage,
  testErrorMessage,
} from '@utilities/validations/messages';
import { emailRegExp, phoneRegExp } from '@utilities/validations/regex';
import * as yup from 'yup';

export const REGISTRATION_ONE_SCHEMA = (t) => {
  return yup.object().shape({
    applicant: yup.object().shape({
      email: yup
        .string()
        .matches(emailRegExp, t(testErrorMessage))
        .max(40, t(maxCharacters, { amount: 40 }))
        .required(t(requiredMessage)),
      mobilePhone: yup.string().matches(phoneRegExp, t(invalidPhone)).required(t(requiredMessage)),
      acceptTerms: yup.bool().oneOf([true], t(termsMessage)),
    }),
  });
};
