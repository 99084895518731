/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/no-onchange */
// @ts-nocheck
import React, { useRef, useState, useEffect } from 'react';
import { ErrorMessage } from 'formik';
import classNames from 'classnames';
import { Chevron } from '../svg/icons';
import Tooltip from '../Tooltip';
import { useTranslation } from 'react-i18next';

export interface SelectProps {
  classes?: string;
  formType?: string;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onFocus?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onBlur?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  sronly?: boolean;
  label?: string;
  labelSubtitle?: string;
  value?: string;
  name: string;
  hasError?: boolean;
  tabIndex?: number;
  tooltip?: string;
  options: { value: string; displayValue: string }[];
  hideLabel?: boolean;
  placeholder?: string;
  selectRef?: any;
  white?: boolean;
  shouldTranslateValues?: boolean;
}

const Select: React.FC<SelectProps> = ({ formType = 'primary', ...props }) => {
  const checkboxRef = useRef<HTMLDivElement>(null);
  const { t, i18n } = useTranslation();
  const [forceRerender, setForceRerender] = useState<boolean>(false); // State variable to force re-render

  const classes = classNames({
    'c-select form__item': true,
    [`${props.classes}`]: Boolean(props.classes),
    'form__item--error': props.hasError,
    [`form__item--${formType}`]: formType,
  });

  const inputClasses = classNames({
    select__input: true,
    'select__input--white': props.white,
    'u-sr-only': Boolean(props.sronly),
  });

  useEffect(() => {
    const languageChangeHandler = () => {
      setForceRerender((prevState) => !prevState); // Toggle state to force re-render
    };

    i18n.on('languageChanged', languageChangeHandler);

    return () => {
      i18n.off('languageChanged', languageChangeHandler);
    };
  }, [i18n.language]);

  return (
    <div className={classes} ref={checkboxRef}>
      {!props.hideLabel && props.label && (
        <div className="select__label-wrapper">
          <label htmlFor={props.name} role="button">
            <span dangerouslySetInnerHTML={{ __html: t(props.label) }} className="select__label-title" />
            {props.labelSubtitle && <span className="select__label-subtitle">{t(props.labelSubtitle)}</span>}
          </label>
          {props.tooltip && <Tooltip text={props.tooltip} />}
        </div>
      )}
      <div className="select__wrapper">
        <select
          ref={props.selectRef || undefined}
          className={inputClasses}
          name={props.name}
          id={props.name}
          value={props.value ? props.value : props.placeholder ? '' : undefined}
          onChange={props.onChange}
          disabled={props.disabled}
          tabIndex={props.tabIndex}
          aria-label={props.hideLabel ? props.label : undefined}
        >
          {props.placeholder && (
            <option value="" disabled>
              {t(props.placeholder)}
            </option>
          )}
          {props.options.map(({ value, displayValue }) => (
            <option key={value} value={value}>
              {props.shouldTranslateValues ? t(displayValue) : displayValue}
            </option>
          ))}
        </select>
        <Chevron fill={props.white ? '#ffffff' : '#3C2E72'} direction="bottom" />
      </div>
      {props.hasError && (
        <p className="select__error">
          <ErrorMessage name={props.name} />
        </p>
      )}
    </div>
  );
};

export default Select;
