/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Brinks Onboarding API v2023.9.6.948
 * Onboarding API (Development)
 * OpenAPI spec version: 1.0
 */
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import type { BlubeemRequestsApiAccountRequest, BlubeemRequestsApiAccountDetails } from './brinks.schemas';

/**
 * @summary Updates the account
 */
export const putAccount = <TData = AxiosResponse<void>>(
  blubeemRequestsApiAccountRequest: BlubeemRequestsApiAccountRequest,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.put(`/Account`, blubeemRequestsApiAccountRequest, options);
};
/**
 * @summary Updates the account
 */
export const putAccountDetails = <TData = AxiosResponse<void>>(
  blubeemRequestsApiAccountDetails: BlubeemRequestsApiAccountDetails,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.put(`/Account/Details`, blubeemRequestsApiAccountDetails, options);
};
