import { CountryPrefix } from '@utilities/countryPrefix';
import {
  invalidPostalCode,
  invalidRegistratioNumber,
  invalidVatNumber,
  maxCharacters,
  requiredMessage,
} from '@utilities/validations/messages';
import {
  belgianVat,
  dutchVat,
  dutchRegistrationNumberRegExp,
  belgianRegistrationNumberRegExp,
  dutchPostcode,
  belgiumPostcode,
} from '@utilities/validations/regex';
import * as yup from 'yup';

export const REGISTER_COMPANY_FORM_SCHEMA = (t, hostCountry: CountryPrefix) => {
  return yup.object().shape({
    name: yup.string().nullable().required(t(requiredMessage)).max(40, t(maxCharacters)),
    registrationNumber: yup
      .string()
      .required(t(requiredMessage))
      .test('checkRegistrationNumber', t(invalidRegistratioNumber), function (this: yup.TestContext) {
        if (!this.parent.registrationNumber) return false;
        if (
          hostCountry === CountryPrefix.Netherlands &&
          this.parent.registrationNumber.match(dutchRegistrationNumberRegExp)
        ) {
          return true;
        }

        if (
          hostCountry === CountryPrefix.Belgium &&
          this.parent.registrationNumber.match(belgianRegistrationNumberRegExp)
        ) {
          return true;
        }

        return false;
      }),
    vatNumber: yup
      .string()
      .required(t(requiredMessage))
      .test('checkVatNumber', t(invalidVatNumber), function (this: yup.TestContext) {
        if (!this.parent.vatNumber) return false;
        if (hostCountry === CountryPrefix.Netherlands && this.parent.vatNumber.match(dutchVat)) return true;
        if (hostCountry === CountryPrefix.Belgium && this.parent.vatNumber.match(belgianVat)) return true;
        return false;
      }),
    descriptionOfBusinessActivities: yup
      .string()
      .nullable()
      .required(t(requiredMessage))
      .max(50, t(maxCharacters, { amount: 50 })),
    address: yup.object().shape({
      street: yup
        .string()
        .nullable()
        .required(t(requiredMessage))
        .max(40, t(maxCharacters, { amount: 40 })),
      houseNumber: yup
        .string()
        .nullable()
        .required(t(requiredMessage))
        .max(10, t(maxCharacters, { amount: 10 })),
      zipCode: yup
        .string()
        .required(t(requiredMessage))
        .test('checkPostcode', t(invalidPostalCode), function (this: yup.TestContext) {
          if (!this.parent.zipCode) return false;
          if (hostCountry === CountryPrefix.Netherlands && this.parent.zipCode.match(dutchPostcode)) return true;
          if (hostCountry === CountryPrefix.Belgium && this.parent.zipCode.match(belgiumPostcode)) return true;
          return false;
        }),
      city: yup
        .string()
        .nullable()
        .required(t(requiredMessage))
        .max(40, t(maxCharacters, { amount: 40 })),
    }),
  });
};
