import { useHistory } from 'react-router-dom';
import Confirmation from '../../Shared/Confirmation';
import { useTranslation } from 'react-i18next';

const CheckoutConfirmation = () => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Confirmation
      title="CheckoutConfirm.success"
      text="CheckoutConfirm.success_message"
      button={{ label: 'CheckoutConfirm.home_button_label', onClick: () => history.push('/') }}
    />
  );
};

export default CheckoutConfirmation;
