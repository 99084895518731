export const formerPSP = [
  {
    value: 'Worldline',
    displayValue: 'Worldline',
  },
  {
    value: 'Ingenico',
    displayValue: 'Ingenico',
  },
  {
    value: 'EMS',
    displayValue: 'EMS',
  },
  {
    value: 'CCV',
    displayValue: 'CCV',
  },
  {
    value: 'MyPOS Europe',
    displayValue: 'MyPOS Europe',
  },
  {
    value: 'Sumup',
    displayValue: 'Sumup',
  },
  {
    value: 'Zettle',
    displayValue: 'Zettle',
  },
  {
    value: 'Rabobank',
    displayValue: 'Rabobank',
  },
  {
    value: 'ING',
    displayValue: 'ING',
  },
  {
    value: 'ABN AMRO',
    displayValue: 'ABN AMRO',
  },
  {
    value: 'Mollie',
    displayValue: 'Mollie',
  },
  {
    value: 'Buckaroo',
    displayValue: 'Buckaroo',
  },
  {
    value: 'CM',
    displayValue: 'CM',
  },
  {
    value: 'Stripe',
    displayValue: 'Stripe',
  },
  {
    value: 'Adyen',
    displayValue: 'Adyen',
  },
  {
    value: 'PINDirect',
    displayValue: 'PINDirect',
  },
  {
    value: 'SEPAY',
    displayValue: 'SEPAY',
  },
  {
    value: 'Other',
    displayValue: 'FormerPaymentServiceProvidor.other',
  },
];
