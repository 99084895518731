/**
 * Generated by orval v6.6.4 🍺
 * Do not edit manually.
 * Brinks Onboarding API v2023.9.6.948
 * Onboarding API (Development)
 * OpenAPI spec version: 1.0
 */
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import type { BlubeemModelsApiPackage } from './brinks.schemas';

/**
 * @summary Gets all packages (Later on CPQ) this is a combination of products
 */
export const getPackageSource = <TData = AxiosResponse<BlubeemModelsApiPackage[]>>(
  source: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/Package/${source}`, options);
};
/**
 * @summary Gets package (Later on CPQ)
 */
export const getPackageSourceCode = <TData = AxiosResponse<BlubeemModelsApiPackage>>(
  source: string,
  code: string,
  options?: AxiosRequestConfig,
): Promise<TData> => {
  return axios.get(`/Package/${source}/${code}`, options);
};
