import {
  invalidEmail,
  invalidPostalCode,
  invalidPurchaseOrderNumber,
  maxCharacters,
  minCharacters,
  requiredMessage,
} from '@utilities/validations/messages';
import { belgiumPostcode, dutchPostcode, emailRegExp, purchaseOrderNumberRegExp } from '@utilities/validations/regex';
import * as yup from 'yup';

export const CHECKOUT_TWO_SCHEMA = (t) =>
  yup.object().shape({
    contact: yup.object().shape({
      email: yup.string().matches(emailRegExp, t(invalidEmail)).required(t(requiredMessage)),
      firstname: yup
        .string()
        .min(2, t(minCharacters, { amount: 2 }))
        .max(40, t(maxCharacters, { amount: 40 }))
        .required(t(requiredMessage)),
      inFix: yup.string().max(40, t(maxCharacters, { amount: 40 })),
      lastname: yup
        .string()
        .min(2, t(minCharacters, { amount: 2 }))
        .max(80, t(maxCharacters, { amount: 80 }))
        .required(t(requiredMessage)),
    }),
    purchaseOrderNumber: yup
      .string()
      .matches(purchaseOrderNumberRegExp, t(invalidPurchaseOrderNumber))
      .max(50, t(maxCharacters, { amount: 50 })),
    billingAddress: yup.object().when('useSameInvoiceAddress', {
      is: false,
      then: yup.object({
        street: yup
          .string()
          .max(255, t(maxCharacters, { amount: 255 }))
          .required(t(requiredMessage))
          .nullable(),
        houseNumber: yup.string().nullable().required(t(requiredMessage)),
        zipCode: yup
          .string()
          .test('postcode', t(invalidPostalCode), (value) => dutchPostcode.test(value) || belgiumPostcode.test(value))
          .required(t(requiredMessage))
          .nullable(),
        city: yup
          .string()
          .max(20, t(maxCharacters, { amount: 20 }))
          .required(t(requiredMessage))
          .nullable(),
      }),
    }),
  });
