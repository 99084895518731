import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { CountryPrefix, getHostCountryPrefix } from '@utilities/countryPrefix';

interface LanguageSelectorProps {
  languagePrefix: string;
}

const LanguageSelector: React.FC<LanguageSelectorProps> = ({ languagePrefix }) => {
  const { t, i18n } = useTranslation();
  const urlParams = new URLSearchParams(location.search);
  const hostCountry = getHostCountryPrefix();

  const options = [
    { value: 'nl', label: t('dutch'), emoji: '🌐' },
    { value: 'fr', label: t('french'), emoji: '🌐' },
  ];

  const [selectedLanguage, setSelectedLanguage] = useState(
    urlParams.get('language') 
    || localStorage.getItem('selectedLanguage') 
    || (hostCountry === CountryPrefix.Netherlands ? 'nl' : options[0].value),
  );

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const language = urlParams.get('language');
    if (language && ['nl', 'fr'].includes(language)) {
      changeLanguage(language);
    }
  }, []);

  useEffect(() => {
    changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  const changeLanguage = (language) => {
    setSelectedLanguage(language);
    i18n.changeLanguage(language);
    localStorage.setItem('selectedLanguage', language);
  };

  const showLanguageSelector = languagePrefix === CountryPrefix.Belgium;
  if (!showLanguageSelector) {
    return null;
  }

  const customStyles = {
    control: (provided) => ({
      ...provided,
      background: 'transparent',
      border: '1px solid #ccc',
      borderRadius: '10px',
      padding: '2.5px',
      transition: 'all 0.8s ease-in-out',
      width: 'auto',
      height: 'auto',
    }),
    option: (provided) => ({
      ...provided,
      color: 'black',
      display: 'flex',
      alignItems: 'center',
      marginBottom: '2.5px',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'white',
      display: 'flex',
      alignItems: 'center',
    }),
    valueContainer: (provided) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    }),
  };

  const CustomOption = ({ innerProps, label, data }) => {
    const optionStyle = customStyles.option({ label, data, isFocused: innerProps.isFocused });

    return (
      <div {...innerProps} style={{ ...optionStyle, cursor: 'pointer' }}>
        <span style={{ marginLeft: '5px' }}>{label}</span>
      </div>
    );
  };

  const CustomSingleValue = ({ children, ...props }) => {
    const selectedOption = options.find((option) => option.value === selectedLanguage);

    const singleValueStyle = customStyles.singleValue(props);
    return (
      <div {...props} style={{ ...singleValueStyle }}>
        <span> {selectedOption.emoji} </span>
        <span style={{ marginLeft: '5px' }}>{children}</span>
      </div>
    );
  };

  return (
    <Select
      options={options}
      value={options.find((option) => option.value === selectedLanguage)}
      onChange={(selectedOption) => changeLanguage(selectedOption.value)}
      styles={customStyles}
      isSearchable={false}
      components={{ Option: CustomOption, SingleValue: CustomSingleValue }}
    />
  );
};

export default LanguageSelector;
