import React from 'react';
import classNames from 'classnames';
import { TooltipIcon } from './svg/icons';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';

interface TooltipProps {
  variant?: string;
  text: string;
  classes?: string;
}

const Tooltip: React.FC<TooltipProps> = ({ variant = 'primary', classes, text }) => {
  const { t } = useTranslation();

  const tooltipClasses = classNames({
    'c-tooltip': true,
    [`${classes}`]: Boolean(classes),
    [`c-tooltip--${variant}`]: variant,
  });

  return (
    <div className={tooltipClasses}>
      <button type="button" className="tooltip__icon" data-tip={t(text)} data-class="tooltip__text">
        <TooltipIcon />
      </button>
      <ReactTooltip place="left" effect="solid" />
    </div>
  );
};

export default Tooltip;
