import Select from '@components/Shared/form-elements/Select';
import Collapse from '@shared/collapse/Collapse';
import Checkbox from '@shared/form-elements/Checkbox';
import { FormikNumberInput } from '@shared/form-elements/FormikNumberInput';
import { FormikSelect } from '@shared/form-elements/FormikSelect';
import { FormikToggle } from '@shared/form-elements/FormikToggle';
import { timeInputValue } from '@utilities/input-values';
import classNames from 'classnames';
import { FieldArray, useFormikContext } from 'formik';
import produce from 'immer';
import React, { useEffect, useMemo, useState } from 'react';
import { CheckoutStep4FormData, CheckoutStep4Service } from '../../models/checkout-step-4-form.model';
import { ServiceType } from '../../models/service-type.enum';
import { isTimeAfter } from '../../validation/cash-schema';
import FormTitle from '../shared/FormTitle';
import { useTranslation } from 'react-i18next';
import { CountryPrefix, getHostCountryPrefix } from '@utilities/countryPrefix';

interface HourProps {
  locationIndex: number;
}

export const Hours: React.FC<HourProps> = ({ locationIndex }) => {
  const { values, setValues, touched, setTouched } = useFormikContext<CheckoutStep4FormData>();
  const [applyToAllWorkDays, setApplyToAllWorkDays] = useState(false);
  const [applyToAllDays, setApplyToAllDays] = useState(false);
  const [allOpeningTimes, setAllOpeningTimes] = useState('');
  const [allClosingTimes, setAllClosingTimes] = useState('');
  const { t } = useTranslation();
  const weekDays = [
    t('Hours.monday'),
    t('Hours.tuesday'),
    t('Hours.wednesday'),
    t('Hours.thursday'),
    t('Hours.friday'),
    t('Hours.saturday'),
    t('Hours.sunday'),
  ];
  const [showAdditionalTimes, setShowAdditionalTimes] = useState(Array(weekDays.length).fill(false));
  const countryPrefix = getHostCountryPrefix();

  const timeClasses = classNames({
    'cash-cards-clicks__row cash-cards-clicks__row--padding-top': true,
    'cash-cards-clicks__row--is-hidden': !applyToAllWorkDays && !applyToAllDays,
  });

  const serviceIndex = useMemo(
    () => values.services.findIndex((service) => service.name === ServiceType.Cash),
    [values],
  );

  useEffect(() => {
    const correctAdditionalTimes = values?.services[serviceIndex]?.dayInformation?.map(
      (day) => day.openingHours !== undefined && day.openingHours?.length > 1,
    );

    setShowAdditionalTimes(correctAdditionalTimes);
  }, []);

  useEffect(() => {
    setApplyToAllDays(false);
    setApplyToAllWorkDays(false);
    setAllOpeningTimes('');
    setAllClosingTimes('');
  }, [locationIndex]);

  const service = useMemo(() => values.services[serviceIndex], [serviceIndex, values]) as CheckoutStep4Service;

  const clearAllFields = (isTouched = false) => {
    const payloadState = produce(values, (draftState) => {
      draftState.services[serviceIndex].dayInformation?.forEach((item) => {
        item.open = false;
        item.openingHours.forEach((openingHour) => {
          openingHour.openingTime = '';
          openingHour.closingTime = '';
        });
      });
    });

    setAllOpeningTimes('');
    setAllClosingTimes('');

    if (isTouched) {
      const touchedPayload: any = {};

      touchedPayload.dayInformation = [];

      service.dayInformation?.forEach((_) => {
        touchedPayload.dayInformation.push({
          open: false,
        });
      });

      setTouched({ ...touched, ...touchedPayload });
    }

    return payloadState;
  };

  const getWorkDaysValues = (setDays = false, isTouched = false) => {
    const payloadState = produce(values, (draftState) => {
      const dayInformation = draftState.services[serviceIndex]?.dayInformation;

      if (setDays) {
        dayInformation.forEach((item, index) => {
          if (index <= 4) item.open = true;
          if (index > 4 || !index) item.open = false;
        });
      }

      dayInformation.forEach((item, index) => {
        if (index <= 4) {
          if (item.openingHours.length === 0) {
            item.openingHours.push({ openingTime: allOpeningTimes, closingTime: allClosingTimes });
          } else {
            item.openingHours[0].openingTime = allOpeningTimes;
            item.openingHours[0].closingTime = allClosingTimes;
          }
        }

        if (index > 4 || !index) {
          if (item.openingHours.length === 0) {
            item.openingHours.push({ openingTime: '', closingTime: '' });
          } else {
            item.openingHours[0].openingTime = '';
            item.openingHours[0].closingTime = '';
          }
        }
      });
    });

    if (isTouched) {
      const touchedPayload: any = {};

      touchedPayload.dayInformation = [];

      service?.dayInformation?.forEach((_, index: number) => {
        if (index <= 4) {
          touchedPayload.dayInformation.push({
            open: true,
          });
        }
      });

      setTouched({ ...touched, ...touchedPayload });
    }

    return payloadState;
  };

  const getAllDaysValues = (setDays = false, isTouched = false) => {
    const payloadState = produce(values, (draftState) => {
      if (setDays) {
        draftState.services[serviceIndex]?.dayInformation?.forEach((item) => {
          item.open = true;
        });
      }

      draftState.services[serviceIndex]?.dayInformation?.forEach((item) => {
        if (item.openingHours.length === 0) {
          item.openingHours.push({ openingTime: allOpeningTimes, closingTime: allClosingTimes });
        } else {
          item.openingHours[0].openingTime = allOpeningTimes;
          item.openingHours[0].closingTime = allClosingTimes;
        }
      });
    });

    if (isTouched) {
      const touchedPayload: any = {};

      touchedPayload.dayInformation = [];

      service?.dayInformation?.forEach((_) => {
        touchedPayload.dayInformation.push({
          open: true,
        });
      });

      setTouched({ ...touched, ...touchedPayload });
    }

    return payloadState;
  };

  useEffect(() => {
    if (allOpeningTimes === '' && allClosingTimes === '') {
      return;
    }

    let payload = {} as CheckoutStep4FormData;
    const TOUCHED = !!(allClosingTimes || (allOpeningTimes && allClosingTimes));

    if (applyToAllWorkDays) {
      payload = getWorkDaysValues(false, TOUCHED);
    } else {
      payload = getAllDaysValues(false, TOUCHED);
    }

    setValues(payload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allOpeningTimes, allClosingTimes]);

  const setWorkDays = (checked: boolean) => {
    setApplyToAllWorkDays(checked);

    let payload = {} as CheckoutStep4FormData;

    if (checked) {
      setApplyToAllDays(false);
      payload = getWorkDaysValues(true);
    } else {
      payload = clearAllFields(true);
    }

    setValues(payload);
  };

  const setAllDays = (checked: boolean) => {
    setApplyToAllDays(checked);
    let payload = {} as CheckoutStep4FormData;

    if (checked) {
      setApplyToAllWorkDays(false);
      payload = getAllDaysValues(true);
    } else {
      payload = clearAllFields(true);
    }

    setValues(payload);
  };

  const getTotalRevenue = () => {
    const activeItems = service.dayInformation?.filter((item) => item.open);

    const priceFormatter = new Intl.NumberFormat('nl-NL', {
      style: 'currency',
      currency: 'EUR',
    });

    let totalRevenue = 0;
    activeItems?.forEach((item) => {
      if (item) totalRevenue += Number(item.turnoverSpeed);
    });
    return priceFormatter.format(totalRevenue);
  };

  return (
    <>
      <FormTitle variant="secondary" title="Hours.opening_times_title" subtitle="Hours.opening_times_subtitle" />
      <div className="cash-cards-clicks__checkbox-wrapper">
        <Checkbox
          label="Hours.select_time_for_workdays"
          classes="u-margin-bottom--sm"
          name="workdays"
          formType="secondary"
          onChange={(e) => setWorkDays(e.target.checked)}
          value={applyToAllWorkDays}
          checked={applyToAllWorkDays}
        />
        <Checkbox
          label="Hours.select_time_for_entire_week"
          name="alldays"
          formType="secondary"
          onChange={(e) => setAllDays(e.target.checked)}
          value={applyToAllDays}
          checked={applyToAllDays}
        />

        {(applyToAllWorkDays || applyToAllDays) && (
          <section className={timeClasses}>
            <Select
              label="Hours.opening"
              hideLabel
              name={`services[${serviceIndex}].allOpeningTimes`}
              value={allOpeningTimes}
              onChange={(event) => setAllOpeningTimes(event.target.value)}
              placeholder="Hours.opening_placeholder"
              options={timeInputValue.map((value) => ({
                value,
                displayValue: value,
              }))}
            />
            <Select
              label="Hours.closing"
              hideLabel
              name={`services[${serviceIndex}].closingTimes`}
              value={allClosingTimes}
              onChange={(event) => setAllClosingTimes(event.target.value)}
              placeholder="Hours.closing_placeholder"
              options={timeInputValue.map((value) => ({
                value,
                displayValue: value,
              }))}
            />
            {!isTimeAfter(allOpeningTimes, allClosingTimes) && (
              <p className="input__error">{t('Hours.closing_time_error')}</p>
            )}
          </section>
        )}
      </div>

      <FieldArray
        name="dayInformation"
        render={() => {
          return (
            <>
              {service?.dayInformation &&
                service?.dayInformation.map((day, i) => {
                  return (
                    <div className="cash-cards-clicks__item" key={i}>
                      <FormikToggle
                        name={`services[${serviceIndex}].dayInformation[${i}].open`}
                        uncheckedLabel="Hours.closed"
                        optimized={false}
                        label={weekDays[i]}
                        key={`closedSlider-${serviceIndex}-${i}`}
                      />
                      <Collapse active={service?.dayInformation?.[i]?.open}>
                        {day?.openingHours?.map((openingHour, openingIndex) => {
                          return (
                            <div
                              className="cash-cards-clicks__row cash-cards-clicks__row--padding-top"
                              key={`openingHours-${serviceIndex}-${i}-${openingIndex}`}
                            >
                              <FormikSelect
                                optimized={false}
                                label={`Hours.opening - ${i}`} // Adjust label if needed
                                hideLabel
                                name={`services[${serviceIndex}].dayInformation[${i}].openingHours[${openingIndex}].openingTime`}
                                placeholder="Hours.opening_placeholder"
                                tabIndex={!!day.open ? 0 : -1}
                                options={timeInputValue.map((value) => ({
                                  value,
                                  displayValue: value,
                                }))}
                                key={`openingTime-${serviceIndex}-${i}-${openingIndex}`}
                              />
                              <FormikSelect
                                optimized={false}
                                label={`Hours.closing - ${i}`} // Adjust label if needed
                                hideLabel
                                name={`services[${serviceIndex}].dayInformation[${i}].openingHours[${openingIndex}].closingTime`}
                                placeholder="Hours.closing_placeholder"
                                tabIndex={!!day.open ? 0 : -1}
                                options={timeInputValue.map((value) => ({
                                  value,
                                  displayValue: value,
                                }))}
                                key={`closingTime-${serviceIndex}-${i}-${openingIndex}`}
                              />
                            </div>
                          );
                        })}

                        {countryPrefix === CountryPrefix.Belgium && (
                          <Checkbox
                            label="Hours.show_additional_time"
                            classes="u-margin-top--sm"
                            name={`additionalTime - ${serviceIndex} - ${i}`}
                            formType="secondary"
                            onChange={(event) => {
                              const isChecked = event.target.checked;
                              const updatedShowAdditionalTimes = [...showAdditionalTimes];
                              updatedShowAdditionalTimes[i] = isChecked;
                              setShowAdditionalTimes(updatedShowAdditionalTimes);

                              if (isChecked) {
                                const updatedOpeningHours = [...day.openingHours, { openingTime: '', closingTime: '' }];
                                day.openingHours = updatedOpeningHours;
                              } else {
                                const updatedOpeningHours = day.openingHours.slice(0, -1);
                                day.openingHours = updatedOpeningHours;
                              }

                              event.target.checked = day.openingHours.length > 1;
                            }}
                            checked={showAdditionalTimes[i]}
                            key={`additionalTime-${serviceIndex}-${i}`}
                          />
                        )}
                        <div className="cash-cards-clicks__revenue">
                          <FormikNumberInput
                            label="Hours.average_cash_revenue"
                            name={`services[${serviceIndex}].dayInformation[${i}].turnoverSpeed`}
                            formType="tertiary"
                            prefix="€"
                            key={`turnoverSpeed-${serviceIndex}-${i}`}
                          />
                        </div>
                      </Collapse>
                    </div>
                  );
                })}

              {getTotalRevenue() !== '0' && (
                <div className="cash-cards-clicks__revenue-total">
                  <span className="cash-cards-clicks__revenue-total-text">{t('Hours.total_cash_revenue')}</span>
                  <span className="cash-cards-clicks__revenue-total-amount">&nbsp;{getTotalRevenue()}</span>
                </div>
              )}
            </>
          );
        }}
      ></FieldArray>
    </>
  );
};
