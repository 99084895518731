// @ts-nocheck
import classNames from 'classnames';
import { ErrorMessage } from 'formik';
import React, { useEffect, useState } from 'react';
import Slider from 'react-rangeslider';
import { useDebounce } from 'react-use';
import Tooltip from '../Tooltip';
import { useTranslation } from 'react-i18next';

export interface RangeBaseProps {
  classes?: string;
  formType?: string;
  disabled?: boolean;
  label: string;
  labelSubtitle?: string;
  hasError?: boolean;
  suffix?: string;
  name: string;
  minLabel?: string;
  maxLabel?: string;
  tooltip?: string;
  split?: boolean;
  resetValue?: boolean;
  initialValue?: number;
}

interface RangeProps extends RangeBaseProps {
  onChange: (e: number) => void;
  value: number;
}

const Range: React.FC<RangeProps> = ({ onChange, resetValue, ...props }) => {
  const [rangeValue, setRangeValue] = useState<number>(props.value);
  const [prevResetValue, setPrevResetValue] = useState(false);
  const [] = useDebounce(() => rangeValue >= 0 && onChange(rangeValue), 500, [rangeValue]);
  const { t } = useTranslation();

  const classes = classNames({
    'c-range form__item': true,
    [`${props.classes}`]: Boolean(props.classes),
    'form__item--error': props.hasError,
  });

  useEffect(() => {
    if (resetValue && !prevResetValue) {
      props.initialValue !== undefined ? setRangeValue(props.initialValue) : setRangeValue(0);
      setPrevResetValue(resetValue);
    } else {
      setRangeValue(props.value);
    }
  }, [resetValue, prevResetValue, props.value, props.initialValue]);

  return (
    <div className={classes}>
      <div className="range__label-wrapper">
        <label className="range__label-title">
          <span dangerouslySetInnerHTML={{ __html: t(props.label) }} />
          {props.labelSubtitle && <span className="range__label-subtitle">{t(props.labelSubtitle)}</span>}
        </label>
        {props.tooltip && <Tooltip text={props.tooltip} />}
      </div>
      <div className="range__wrapper">
        <div
          className={`range__value-label range__value-label--left ${
            props.split ? '' : 'range__value-label--no-margin'
          }`}
        >
          <span>{`${rangeValue}${props.suffix ? props.suffix : ''}`}</span>
          <span>{props.minLabel && t(props.minLabel)}</span>
        </div>
        <Slider
          tooltip={props.split ? false : true}
          format={(val) => `${val}${props.suffix ? props.suffix : ''}`}
          value={rangeValue}
          onChange={(e) => setRangeValue(e)}
          disabled={props.disabled}
        />
        <div
          className={`range__value-label range__value-label--right ${
            props.split ? '' : 'range__value-label--no-margin'
          }`}
        >
          <span>{`${100 - rangeValue}${props.suffix ? props.suffix : ''}`}</span>
          <span>{props.maxLabel && t(props.maxLabel)}</span>
        </div>
      </div>
      {props.hasError && (
        <p className="range__error">
          <ErrorMessage name={props.name} />
        </p>
      )}
    </div>
  );
};

export default Range;
