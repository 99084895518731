import StyledButton from '@components/Shared/StyledButton';
import { Cross } from '@components/Shared/svg/icons';
import { BlubeemRequestsApiUboRequest, BlubeemRequestsIdentificationRequest } from '@generated/brinks.schemas';
import { putOpportunityStep } from '@generated/opportunity';
import { postSignicat } from '@generated/signicat';
import { deleteUboId, getUbo, postUbo, putUbo } from '@generated/ubo';
import { useUser } from '@state/user';
import { useLoader } from '@utilities/context/LoaderContext';
import { useModalActions } from '@utilities/context/ModalContext';
import { useToast } from '@utilities/context/ToastContext';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import FormHeader from '../../../Shared/FormHeader';
import FormLayout from '../../../Shared/FormLayout';
import FormLayoutColOne from '../../../Shared/FormLayoutColOne';
import FormLayoutColTwo from '../../../Shared/FormLayoutColTwo';
import InfoBox from '../../../Shared/InfoBox';
import { convertFormDataToUbo, convertUboToFormData } from './converters/create-ubo-form.converter';
import { EMPTY_UBO } from './models/test-ubos';
import { UboFormData } from './models/ubo-form-data.model';
import RemoveUboModal from './RemoveUboModal';
import Ubo from './Ubo';
import UboModal from './UboModal';
import { useTranslation } from 'react-i18next';

const CheckoutFive: React.FC = () => {
  const { toggleLoader } = useLoader();
  const { dispatch, hide } = useModalActions();
  const { user, setUser } = useUser();
  const { t } = useTranslation();

  const { makeToast } = useToast();
  const history = useHistory();
  const [selectedUbo, setSelectedUbo] = useState<UboFormData>(EMPTY_UBO);

  useEffect(() => {
    putOpportunityStep({ step: '5' });
    getUbos();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getUbos = async () => {
    toggleLoader(true);

    const { data } = await getUbo();
    toggleLoader(false);
    setUser({ ...user, ubos: data });
  };

  const verifyUbo = async (ubo: UboFormData) => {
    toggleLoader(true);

    const payload: BlubeemRequestsIdentificationRequest = {
      uboId: ubo.id,
      redirectUrl: `${location.origin}/checkout/signicat-redirect?uboId=${ubo.id}`,
    };
    const { data } = await postSignicat(payload);

    const signicatUrl = data.url;
    window.location.href = signicatUrl;

    toggleLoader(false);
  };

  const createUbo = async (payload: BlubeemRequestsApiUboRequest) => {
    toggleLoader(true);

    await postUbo(payload);
    toggleLoader(false);

    await getUbos();
  };

  const editUbo = async (newValues: UboFormData) => {
    const selectedUbo = user.ubos.find((ubo) => ubo.id === newValues.id);

    const newUbo: BlubeemRequestsApiUboRequest = convertFormDataToUbo(selectedUbo, newValues);

    toggleLoader(true);
    await putUbo(newUbo);
    toggleLoader(false);

    await getUbos();
  };

  const handleEditClick = (ubo: UboFormData) => {
    setSelectedUbo(ubo);
    dispatch({ type: 'show', key: 'edit-ubo-modal' });
  };

  const handleRemoveClick = (ubo: UboFormData) => {
    setSelectedUbo(ubo);
    dispatch({ type: 'show', key: 'remove-ubo-modal' });
  };

  const removeUbo = async (ubo: UboFormData) => {
    toggleLoader(true);

    await deleteUboId(ubo.id);
    toggleLoader(false);
    hide('remove-ubo-modal');
    setSelectedUbo(EMPTY_UBO);
    await getUbos();
  };

  const getSignicatProcessData = useCallback(
    (uboId: string) => {
      const processData = user.signicatProcess;
      if (!processData) {
        return null;
      }

      return processData.find((process) => process.uboId === uboId);
    },
    [user.signicatProcess],
  );

  const hasCardsOrClicks = user.packageInformation.hasCards || user.packageInformation.hasClicks;

  const validateUbosCash = (allVerifiedUbos: string[]) => {
    if (allVerifiedUbos.length == 0) {
      makeToast({ message: t('CheckoutFive.atleast_one_ubo_warning'), variant: 'warning' });
      return;
    }

    history.push('step-6');
  };

  const validateUbosClicksCards = (allVerifiedUbos: string[]) => {
    const allUbosInformationFilled = user.ubos?.every((ubo) => {
      return (
        ubo.firstname &&
        ubo.lastname &&
        ubo.gender &&
        ubo.homeAddress &&
        ubo.postalCode &&
        ubo.city &&
        ubo.country &&
        ubo.email &&
        ubo.mobilePhone
      );
    });

    const areAllUsersVerified = user.ubos.every((ubo) => allVerifiedUbos.includes(ubo.id));
    if (!areAllUsersVerified) {
      makeToast({ message: t('CheckoutFive.all_ubo_must_be_verified_warning'), variant: 'warning' });
      return;
    }

    if (!allUbosInformationFilled) {
      makeToast({ message: t('CheckoutFive.fill_in_details'), variant: 'warning' });
      return;
    }

    history.push('step-6');
  };

  const moveToNextStep = useCallback(() => {
    const allVerifiedUbos = user.signicatProcess
      ?.filter((process) => process.status === 'accepted')
      .map((process) => process.uboId);

    if (user.ubos && allVerifiedUbos) {
      if (hasCardsOrClicks) {
        validateUbosClicksCards(allVerifiedUbos);
      } else {
        validateUbosCash(allVerifiedUbos);
      }
    }
  }, [user, makeToast, history, hasCardsOrClicks, validateUbosCash, validateUbosClicksCards]);

  return (
    <FormLayout>
      <UboModal
        id="edit-ubo-modal"
        title={t('CheckoutFive.edit_ubo')}
        initialValues={convertUboToFormData(selectedUbo)}
        onSubmit={editUbo}
      />
      <UboModal
        id="add-ubo-modal"
        title={t('CheckoutFive.add_ubo')}
        initialValues={convertUboToFormData(EMPTY_UBO)}
        onSubmit={createUbo}
      />
      <RemoveUboModal removeUbo={removeUbo} ubo={convertUboToFormData(selectedUbo)} />
      <FormLayoutColOne>
        <FormHeader
          title="CheckoutFive.form_header_verification"
          indicator={t('step') + ' 5/6'}
          hasPrev
          prevUrl="step-4"
        />
        <InfoBox>
          {hasCardsOrClicks ? (
            <p>{t('CheckoutFive.cards_clicks_verification')}</p>
          ) : (
            <p>{t('CheckoutFive.cash_verification')}</p>
          )}
        </InfoBox>
      </FormLayoutColOne>
      <FormLayoutColTwo>
        {user.ubos ? (
          user.ubos.map((ubo, index) => (
            <Ubo
              key={index}
              ubo={convertUboToFormData(ubo)}
              signicatProcessData={getSignicatProcessData(ubo.id)}
              handleEditClick={handleEditClick}
              handleRemoveClick={handleRemoveClick}
              verifyUbo={verifyUbo}
            />
          ))
        ) : (
          <p>{t('CheckoutFive.create_ubo')}</p>
        )}

        <button
          type="button"
          className="c-verify u-margin-bottom--sm u-margin-top--md"
          onClick={() => dispatch({ type: 'show', key: 'add-ubo-modal' })}
        >
          <div className="verify__icon-wrapper">
            <Cross classes="verify__icon" />
          </div>
          <p className="verify__content">{t('CheckoutFive.add_ubo_button')}</p>
        </button>

        <StyledButton
          tag="button"
          text="CheckoutFive.next_button_text"
          type="submit"
          formType="tertiary"
          onClick={() => moveToNextStep()}
        />
      </FormLayoutColTwo>
    </FormLayout>
  );
};

export default CheckoutFive;
